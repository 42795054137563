const commonTypes = require('./commonTypes');

const mediaList = {
  type: "array",
  default: [],
  items: {
    type: "object",
    required: ["_id", "duration", 'mute'],
    properties: {
      _id: commonTypes.string,
      duration: commonTypes.positiveNumber,
      mute: commonTypes.booleanFalse
    },
  }
};

const playlistProperties = {
  _id: commonTypes.string,
  name: commonTypes.string,
  listType: commonTypes.playlistType,
  random: commonTypes.booleanFalse,
  mute: commonTypes.booleanFalse,
  timed: commonTypes.booleanFalse,
  timedTime: commonTypes.positiveNumber,
  mediaList
};

//////////////// SCHEMAS ////////////////////

const playlistSchema = {
  properties: playlistProperties
};

const playlistCreateSchema = {
  required: ["name", "listType"],
  properties: playlistProperties
};

const playlistPatchSchema = {
  properties: playlistProperties
};

export {
  playlistSchema,
  playlistCreateSchema,
  playlistPatchSchema
};