//FIXME: Quan afegim una llista a les llistes de reproducció la planificació (schedule) agafa la de l'últim que hem posat
//TODO: Que no es pugui posar una data anterior al ValidityFrom en el validityUntil i viceversa

import React, { Component } from "react";
import { Prompt } from "react-router";
import uuid from "uuid/v4";
import Datetime from "react-datetime";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import ReactTable from "react-table";

import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import NavPills from "components/NavPills/NavPills2.jsx";
import Switch from "@material-ui/core/Switch";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Extension from "@material-ui/icons/ExtensionOutlined";
import Sync from "@material-ui/icons/SyncOutlined";
import Save from "@material-ui/icons/SaveOutlined";
import Restore from "@material-ui/icons/RestoreOutlined";
import Check from "@material-ui/icons/CheckOutlined";
import VpnKey from "@material-ui/icons/VpnKeyOutlined";
import Replay from "@material-ui/icons/ReplayOutlined";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Snackbars from "components/Snackbar/Snackbar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// Feathers
import { feathersClient } from "../../helpers/feathers";
import { validateClient } from "../../schemas/validators";
import ga from "../../helpers/google-analytics";

// Styles
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const CALENDAR_MONTH = 6;
const CALENDAR_YEAR = 2018;

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const tabs = [
  {
    item: 'players',
    translation: 'players'
  },
  {
    item: 'groups',
    translation: 'groups'
  },
  /*{
    item: 'videoPlaylists',
    translation: 'video_playlists'
  },
  {
    item: 'audioPlaylists',
    translation: 'audio_playlists'
  },*/
  {
    item: 'playlists',
    translation: 'playlists'
  },
  {
    item: 'categories',
    translation: 'categories'
  },
]

class Client extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      id: 0,
      client: {
        _id: "",
        name: "",
        clientID: "",
        clientSecret: "",
        enabled: false,
      },
      clientChanges: false,
      players: {
        name: "players",
        read: false,
        update: false,
        delete: false,
        create: false,
        items: []
      },
      groups: {
        name: "groups",
        read: false,
        update: false,
        delete: false,
        create: false,
        items: []
      },
      /*videoPlaylists: {
        read: false,
        update: false,
        delete: false,
        create: false,
        items: []
      },
      audioPlaylists: {
        read: false,
        update: false,
        delete: false,
        create: false,
        items: []
      },*/
      playlists: {
        name: "playlists",
        read: false,
        update: false,
        delete: false,
        create: false,
        items: []
      },
      categories: {
        name: "categories",
        read: false,
        update: false,
        delete: false,
        create: false,
        items: []
      },
      notification: {
        visible: false,
        text: "",
        color: "success"
      }
    };
  }

  loadClients() {
    const {
      match: { params }
    } = this.props;

    feathersClient
      .service("clients")
      .get(params.clientId)
      .then(async client => {
        validateClient(client);
        if (this._isMounted) {
          this.setState(
            {
              client: client,
            });
        }
        const playersPromise = feathersClient.service("players").find();
        const groupsPromise = feathersClient.service("groups").find();
        const playlistsPromise = feathersClient.service("playlists").find();
        const categoriesPromise = feathersClient.service("categories").find();
        Promise.all([playersPromise, groupsPromise, playlistsPromise, categoriesPromise]).then(values => {
          var players = values[0].data;
          var groups = values[1].data;
          //var videoPlaylists = values[2].data.filter(list => list.listType === "video");
          //var audioPlaylists = values[2].data.filter(list => list.listType === "audio");
          var playlists = values[2].data;
          var categories = values[3].data;
          var playersList = this.initServiceList('players', players, client);
          var groupsList = this.initServiceList('groups', groups, client);
          //var videoPlaylistsList = this.initServiceList('playlists', videoPlaylists, client);
          //var audioPlaylistsList = this.initServiceList('players', audioPlaylists, client);
          var playlistsList = this.initServiceList('playlists', playlists, client);
          var categoriesList = this.initServiceList('categories', categories, client);
          this.setState({
            players: playersList,
            groups: groupsList,
            //videoPlaylists: videoPlaylistsList,
            //audioPlaylists: audioPlaylistsList,
            playlists: playlistsList,
            categories: categoriesList
          });
        })
      })
      .catch(error => {
        this.showNotification(
          this.props.t('translation:error_fetching_client') + ": " + error.name,
          "warning"
        );
      })
    if (this._isMounted)
      this.setState({
        clientChanges: false
      });
  }

  componentDidMount() {
    this._isMounted = true;
    ga.pageview(window.location.pathname);

    this.loadClients();
  }

  initServiceList(serviceName, data, client) {
    const clientService = client.services.find(service => service.name === serviceName);
    var service = {
      name: serviceName,
      read: false,
      update: false,
      delete: false,
      create: false,
      items: []
    }

    if (clientService) {
      service.read = clientService.read;
      service.update = clientService.update;
      service.delete = clientService.delete;
      service.create = clientService.create;
    }

    data.forEach(item => {
      var newItem = {
        name: item.name,
        id: item._id,
        read: false,
        update: false,
        delete: false
      }
      if (clientService) {
        const clientItem = clientService.items.find(clientItem => clientItem.id === item._id);
        if (clientItem) {
          newItem.read = clientItem.read;
          newItem.update = clientItem.update;
          newItem.delete = clientItem.delete;
        }
      }
      //this.addItemSwitches(newItem, serviceName);
      service.items.push(newItem);
    });

    return service;
  }

  nameFilter(filter, row) {
    return row._original["name"]
      .toLowerCase()
      .includes(filter.value.toLowerCase());
  }

  addItemSwitches(item, serviceName) {
    item.readSwitch = (
      // we've added some custom button actions
      <div className="actions-right">
        <Switch
          checked={item.read}
          onChange={this.handleItemSwitchChange(item, serviceName)}
          value="read"
          classes={{
            switchBase: this.props.classes.switchBase,
            checked: this.props.classes.switchChecked,
            icon: this.props.classes.switchIcon,
            iconChecked: this.props.classes.switchIconChecked,
            bar: this.props.classes.switchBar
          }}
        />
      </div>
    );
    item.writeSwitch = (
      // we've added some custom button actions
      <div className="actions-right">
        <Switch
          checked={item.update}
          onChange={this.handleItemSwitchChange(item, serviceName)}
          value="update"
          classes={{
            switchBase: this.props.classes.switchBase,
            checked: this.props.classes.switchChecked,
            icon: this.props.classes.switchIcon,
            iconChecked: this.props.classes.switchIconChecked,
            bar: this.props.classes.switchBar
          }}
        />
      </div>
    );
    item.deleteSwitch = (
      // we've added some custom button actions
      <div className="actions-right">
        <Switch
          checked={item.delete}
          onChange={this.handleItemSwitchChange(item, serviceName)}
          value="delete"
          classes={{
            switchBase: this.props.classes.switchBase,
            checked: this.props.classes.switchChecked,
            icon: this.props.classes.switchIcon,
            iconChecked: this.props.classes.switchIconChecked,
            bar: this.props.classes.switchBar
          }}
        />
      </div>
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  hideAlert() {
    if (this._isMounted)
      this.setState({
        alert: null
      });
  }

  showNotification(message, color) {
    if (!this.state.notification["visible"]) {
      var x = this.state.notification;
      x["visible"] = true;
      x["text"] = message;
      x["color"] = color;
      if (this._isMounted) this.setState(x);
      // use this to make the notification autoclose
      setTimeout(
        function () {
          x["visible"] = false;
          if (this._isMounted) this.setState(x);
        }.bind(this),
        3000
      );
    }
  }

  handleEnabledChange = (valor) => event => {
    var temp = this.state.client;
    temp[event.target.value] = event.target.checked;
    if (this._isMounted)
      this.setState({
        client: temp,
        clientChanges: true
      });
  };

  handleGroupSwitchChange = (service) => event => {
    var temp = this.state[service];
    temp[event.target.value] = event.target.checked;
    if (this._isMounted)
      this.setState({
        service: temp,
        clientChanges: true
      });
  };

  handleItemSwitchChange = (row, serviceName) => event => {
    var temp = this.state[serviceName];
    //const index = temp.items.findIndex(i => i.id === item.id);
    const index = row.index;
    temp.items[index][event.target.value] = event.target.checked;
    if (this._isMounted)
      this.setState({
        serviceName: temp,
        clientChanges: true
      });
  };

  renewClientSecretWarning() {
    if (this._isMounted)
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px", textAlign: "center" }}
            title={this.props.t('translation:are_you_sure')}
            onConfirm={() => this.renewClientSecret()}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={this.props.t('translation:yes_renew_it')}
            cancelBtnText={this.props.t('translation:cancel')}
            showCancel
          >
            <p>
              {this.props.t('translation:this_will_renew_the_client_secret')}
            </p>
            <p>
              {this.props.t('translation:old_client_secret_will_not_work')}
            </p>
          </SweetAlert>
        )
      });
  }

  renewClientSecret() {
    this.hideAlert();
    feathersClient.service('clients').patch(this.state.client._id, {
      renewSecret: true
    }).then(newClient => {
      this.showNotification(
        this.props.t('translation:client_secret_renewed_correctly'),
        "success"
      );
      var tempClient = this.state.client;
      tempClient.clientSecret = newClient.clientSecret;
      this.setState({
        client: tempClient
      })
    })
  }

  updateClientChanges() {
    var client = this.state.client;
    client.services = [
      this.state.players,
      this.state.groups,
      this.state.playlists,
      this.state.categories
    ];
    feathersClient
      .service("clients")
      .patch(client._id, client)
      .then(client => {
        validateClient(client);
        if (this._isMounted)
          this.setState({
            client: client,
            clientChanges: false
          });
        this.showNotification(this.props.t('translation:client_changes_applied_successfully'), "success");
      })
      .catch(error => {
        this.showNotification(
          this.props.t('translation:error_applying_client_changes') + ": " + error.name,
          "warning"
        );
      });
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div>
        {this.state.alert}
        <Prompt
          when={this.state.clientChanges}
          message={t('translation:are_you_sure_you_want_to_loose_your_changes')}
        />
        <Snackbars
          place="tc"
          color={this.state.notification.color}
          //icon={AddAlert}
          message={this.state.notification.text}
          open={this.state.notification.visible}
          closeNotification={() => {
            if (this._isMounted)
              this.setState({ "notification.visible": false });
          }}
        />
        <Card>
          <CardHeader color="success" icon>
            <GridContainer>
              <GridItem xs={4}>
                <GridContainer>
                  <GridItem>
                    <CardIcon color="success">
                      <VpnKey />
                    </CardIcon>
                  </GridItem>
                  <GridItem
                    xs={3}
                    style={{ maxWidth: "100%", flexBasis: "auto" }}
                  >
                    <h4 className={classes.cardIconTitle}>
                      {this.state.client ? this.state.client.name : ""}
                    </h4>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs>
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Button
                      color="success"
                      simple
                      className={classes.marginRight}
                      disabled={!this.state.clientChanges}
                      onClick={this.loadClients.bind(this)}
                      style={{ maxWidth: "140px" }}
                    >
                      <Restore className={classes.icons} /> {t('translation:undo_changes')}
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      color="success"
                      simple
                      className={classes.marginRight}
                      disabled={!this.state.clientChanges}
                      onClick={this.updateClientChanges.bind(this)}
                      style={{ maxWidth: "140px" }}
                    >
                      <Save className={classes.icons} /> {t('translation:save_changes')}
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} md={5}>
                <CustomInput
                  labelText={t('translation:clientID')}
                  id="clientID"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.client.clientID,
                    type: "text",
                    readOnly: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} md={7}>
                <CustomInput
                  labelText={t('translation:client_secret')}
                  id="clientSecret"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.client.clientSecret,
                    type: "text",
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                        color="warning"
                      >
                        <Tooltip
                          disableFocusListener={true}
                          id="tooltip-top"
                          title={this.props.t('translation:new_client_secret')}
                          placement="top"
                          classes={{ tooltip: this.props.classes.tooltip }}
                        >
                          <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                              this.renewClientSecretWarning();
                            }}
                            color="warning"
                            className="edit"
                          >
                            <Replay />
                          </Button>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="space-between">
              <GridItem>
                <h4 className={classes.cardIconTitle}>{t('translation:options')}</h4>
              </GridItem>
              <GridItem>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.client.enabled}
                      onChange={this.handleEnabledChange("enabled")}
                      value="enabled"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        icon: classes.switchIcon,
                        iconChecked: classes.switchIconChecked,
                        bar: classes.switchBar
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label={t('translation:enabled')}
                />
              </GridItem>
            </GridContainer>
            <NavPills
              color="success"
              tabs={tabs.map(tab => {
                return {
                  tabButton: t('translation:' + tab.translation),
                  tabContent: (
                    <GridContainer style={{ paddingRight: '10px', paddingLeft: '10px' }}>
                      <Card style={{ margin: '5px' }}>
                        <CardHeader>
                          <h5 className={classes.cardIconTitle}>{t('translation:group_permissions')}</h5>
                        </CardHeader>
                        <CardBody>
                          <GridContainer>
                            <GridItem>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={this.state[tab.item].create}
                                    onChange={this.handleGroupSwitchChange(tab.item)}
                                    value="create"
                                    classes={{
                                      switchBase: classes.switchBase,
                                      checked: classes.switchChecked,
                                      icon: classes.switchIcon,
                                      iconChecked: classes.switchIconChecked,
                                      bar: classes.switchBar
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label={t('translation:create')}
                              />
                            </GridItem>
                            <GridItem>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={this.state[tab.item].read}
                                    onChange={this.handleGroupSwitchChange(tab.item)}
                                    value="read"
                                    classes={{
                                      switchBase: classes.switchBase,
                                      checked: classes.switchChecked,
                                      icon: classes.switchIcon,
                                      iconChecked: classes.switchIconChecked,
                                      bar: classes.switchBar
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label={t('translation:read')}
                              />
                            </GridItem>
                            <GridItem>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={this.state[tab.item].update}
                                    onChange={this.handleGroupSwitchChange(tab.item)}
                                    value="update"
                                    classes={{
                                      switchBase: classes.switchBase,
                                      checked: classes.switchChecked,
                                      icon: classes.switchIcon,
                                      iconChecked: classes.switchIconChecked,
                                      bar: classes.switchBar
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label={t('translation:update')}
                              />
                            </GridItem>
                            <GridItem>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={this.state[tab.item].delete}
                                    onChange={this.handleGroupSwitchChange(tab.item)}
                                    value="delete"
                                    classes={{
                                      switchBase: classes.switchBase,
                                      checked: classes.switchChecked,
                                      icon: classes.switchIcon,
                                      iconChecked: classes.switchIconChecked,
                                      bar: classes.switchBar
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label
                                }}
                                label={t('translation:delete')}
                              />
                            </GridItem>
                          </GridContainer>
                        </CardBody>
                      </Card>
                      <Card style={{ margin: '5px' }}>
                        <CardHeader>
                          <h5 className={classes.cardIconTitle}>{t('translation:items_permissions')}</h5>
                        </CardHeader>
                        <CardBody>
                          <ReactTable
                            data={this.state[tab.item].items}
                            filterable
                            previousText={this.props.t('translation:previous')}
                            nextText={this.props.t('translation:next')}
                            loadingText={this.props.t('translation:loading')}
                            noDataText={this.props.t('translation:no_rows_found')}
                            pageText={this.props.t('translation:page')}
                            ofText={this.props.t('translation:of')}
                            rowsText={this.props.t('translation:rows')}
                            pageJumpText={this.props.t('translation:jump_to_page')}
                            rowsSelectorText={this.props.t('translation:rows_per_page')}
                            columns={[
                              {
                                Header: t('translation:name'),
                                accessor: "name",
                                filterMethod: this.nameFilter,
                                filterable: true,
                                sortable: true,
                              },
                              {
                                Header: t('translation:read'),
                                headerStyle: { textAlign: 'center' },
                                accessor: "read",
                                sortable: false,
                                filterable: false,
                                maxWidth: 100,
                                Cell: row => (
                                  <div className="actions-center">
                                    <Switch
                                      disabled={this.state[tab.item].read}
                                      checked={row.value}
                                      onChange={this.handleItemSwitchChange(row, tab.item)}
                                      value="read"
                                      classes={{
                                        switchBase: this.props.classes.switchBase,
                                        checked: this.props.classes.switchChecked,
                                        icon: this.props.classes.switchIcon,
                                        iconChecked: this.props.classes.switchIconChecked,
                                        bar: this.props.classes.switchBar
                                      }}
                                    />
                                  </div>
                                )
                              },
                              {
                                Header: t('translation:update'),
                                headerStyle: { textAlign: 'center' },
                                accessor: "update",
                                sortable: false,
                                filterable: false,
                                maxWidth: 100,
                                Cell: row => (
                                  <div className="actions-center">
                                    <Switch
                                      disabled={this.state[tab.item].update}
                                      checked={row.value}
                                      onChange={this.handleItemSwitchChange(row, tab.item)}
                                      value="update"
                                      classes={{
                                        switchBase: this.props.classes.switchBase,
                                        checked: this.props.classes.switchChecked,
                                        icon: this.props.classes.switchIcon,
                                        iconChecked: this.props.classes.switchIconChecked,
                                        bar: this.props.classes.switchBar
                                      }}
                                    />
                                  </div>
                                )
                              },
                              {
                                Header: t('translation:delete'),
                                headerStyle: { textAlign: 'center' },
                                accessor: "delete",
                                sortable: false,
                                filterable: false,
                                maxWidth: 100,
                                Cell: row => (
                                  <div className="actions-center">
                                    <Switch
                                      disabled={this.state[tab.item].delete}
                                      checked={row.value}
                                      onChange={this.handleItemSwitchChange(row, tab.item)}
                                      value="delete"
                                      classes={{
                                        switchBase: this.props.classes.switchBase,
                                        checked: this.props.classes.switchChecked,
                                        icon: this.props.classes.switchIcon,
                                        iconChecked: this.props.classes.switchIconChecked,
                                        bar: this.props.classes.switchBar
                                      }}
                                    />
                                  </div>
                                )
                              }
                            ]}
                            defaultPageSize={10}
                            showPaginationTop
                            showPaginationBottom={false}
                            className="-striped -highlight"
                          />
                        </CardBody>
                      </Card>
                    </GridContainer>
                  )
                }
              })}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withStyles(dashboardStyle)(withTranslation()(Client));
