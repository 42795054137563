import React from "react";
import styled from "styled-components";

import { withTranslation } from 'react-i18next';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import CalendarToday from "@material-ui/icons/CalendarTodayOutlined";
import Delete from "@material-ui/icons/DeleteOutline";
import Timer from "@material-ui/icons/Timer";
import Add from "@material-ui/icons/Add";

import Tooltip from "@material-ui/core/Tooltip";

import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const Notice = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem;
  border: 1px solid transparent;
  line-height: 1.5;
  color: #aaa;
`;

class SchedulesPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <GridContainer style={{ margin: "0", width: "100%" }}>
        <GridItem xs={12}>
          <Card style={{ marginTop: "15px", marginBottom: "15px" }}>
            <CardHeader>
              <GridContainer>
                <GridItem xs={4}>
                  <h4 className={classes.cardIconTitle}>
                    {this.props.listsTitle}
                  </h4>
                </GridItem>
                <GridItem xs>
                  <GridContainer justify="flex-end">
                    <GridItem>
                      <Button
                        color="success"
                        simple
                        className={classes.marginRight}
                        //disabled={!this.state.syncGroup}
                        onClick={this.props.addGroupSchedule.bind(this)}
                        style={{ maxWidth: "140px" }}
                      >
                        <Add className={classes.icons} /> {t('translation:add_schedule')}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              {this.props.list.length
                ? this.props.list.map((groupSchedule, index) => (
                  <Card
                    key={groupSchedule.id}
                    style={{
                      marginTop: "5px",
                      marginBottom: "5px"
                    }}
                  >
                    <GridContainer alignItems="center">
                      <GridItem xs={9}>
                        <GridContainer
                          alignItems="center"
                          justify="flex-start"
                        >
                          <GridItem xs={3}>
                            <CardIcon
                              color="success"
                              style={{
                                marginTop: "0px"
                              }}
                            >
                              <Timer style={{fill: "white"}}/>
                            </CardIcon>
                          </GridItem>
                          <GridItem xs={9}>
                            {groupSchedule.descriptions.map(
                              (description, index) => {
                                return (
                                  <p
                                    key={index}
                                    className={
                                      classes.cardProductDesciprion
                                    }
                                  >
                                    {description}
                                  </p>
                                );
                              }
                            )}
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={3}>
                        <GridContainer
                          alignItems="center"
                          justify="flex-end"
                        >
                          <GridItem>
                            <Tooltip
                              disableFocusListener={true}
                              id="tooltip-top"
                              title={this.props.t('translation:schedule')}
                              placement="bottom"
                              classes={{
                                tooltip: classes.tooltip
                              }}
                            >
                              <Button
                                color="success"
                                simple
                                justIcon
                                onClick={() =>
                                  this.props.changeGroupScheduleOptions(
                                    groupSchedule
                                  )
                                }
                              >
                                <CalendarToday
                                  className={
                                    classes.underChartIcons
                                  }
                                />
                              </Button>
                            </Tooltip>
                          </GridItem>
                          <GridItem>
                            <Tooltip
                              disableFocusListener={true}
                              id="tooltip-top"
                              title={this.props.t('translation:delete')}
                              placement="bottom"
                              classes={{
                                tooltip: classes.tooltip
                              }}
                            >
                              <Button
                                color="danger"
                                simple
                                justIcon
                                onClick={() =>
                                  this.props.removeGroupSchedule(
                                    groupSchedule
                                  )
                                }
                              >
                                <Delete
                                  className={
                                    classes.underChartIcons
                                  }
                                />
                              </Button>
                            </Tooltip>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </Card>
                ))
                : (
                  <Notice>{t('translation:create_new_schedules_for_automatic_synchronization')}</Notice>
                )}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(dashboardStyle)(withTranslation()(SchedulesPreview));
