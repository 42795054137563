import { colors } from "@material-ui/core";
import moment from "moment";

function getMediaBackgroundColor(media) {
    const now = moment();
    if (media.validUntil && now.isAfter(media.validUntil)) {
        return colors.red[50];
    } else if (media.validFrom && now.isBefore(media.validFrom)) {
        return colors.amber[50];
    }
}

function getPlaylistBackgroundColor(playlist) {
    const now = moment();
    if (playlist.validity.until && now.isAfter(playlist.validity.until)) {
        return colors.red[50];
    } else if (playlist.validity.from && now.isBefore(playlist.validity.from)) {
        return colors.amber[50];
    }
}

export default {
    getMediaBackgroundColor,
    getPlaylistBackgroundColor
}