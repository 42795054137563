import React from "react";
import { withCookies } from 'react-cookie';
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "helpers/privateroute";

import indexRoutes from "routes/index.jsx";

class App extends React.Component {
render() {
      return (
         <div>
           <Switch>
            {indexRoutes.map((prop, key) => {
               if (prop.protected === true) {
                  return <PrivateRoute path={prop.path} component={prop.component} key={key} />;
               } else {
                  return <Route path={prop.path} component={prop.component} key={key} />;
               }
            })}
            </Switch>
         </div>
      ); 
   }
}
export default withCookies(App);