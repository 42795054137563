//import commonTypes from "./commonTypes_import";
const commonTypes = require('./commonTypes');

const _id = {
    type: "string",
    default: "",
    pattern: "^(.*)$"
};

const name = {
    type: "string",
    default: "",
    pattern: "^(.*)$"
};

const id = {
    type: "string",
    default: "",
    pattern: "^(.*)$"
};

const group = {
    type: "string",
    default: "",
    pattern: "^(.*)$"
};

const location = {
    type: "string",
    default: "",
    examples: [
        "Sant Feliu de Guíxols"
    ],
    pattern: "^(.*)$"
};

const controlTV = {
    "type": "boolean",
    "default": true,
};

const disableHDMI = {
    "type": "boolean",
    "default": true,
};

const controlType = {
    "type": "string",
    "enum": ["cec", "retual", "lg"],
    "default": "cec",
    "pattern": "^(.*)$"
};

const controlPort = {
    "type": "string",
    "default": "",
    "pattern": "^(.*)$"
};

const rotation = {
    "type": "number",
    "enum": [
        0, //no rotation
        1, //90º clockwise
        2, //180º clockwise
        3 // 270º clockwise
    ],
    "default": 0,
};

const display = {
    type: "object",
    default: {},
    required: ["controlTV", "disableHDMI", "controlType", "controlPort", "rotation"],
    properties: {
        controlTV,
        disableHDMI,
        controlType,
        controlPort,
        rotation,
        panelMode: commonTypes.booleanFalse,
        panelWidth: commonTypes.positivePixels,
        panelHeight: commonTypes.positivePixels,
        hdmiGroup: commonTypes.hdmiGroup,
        hdmiMode: commonTypes.positiveNumber,
    }
};

const timezone = {
    type: "string",
    default: "",
    examples: ["Europe/Andorra"],
    pattern: "^(.*)$"
};

const keyboard = {
    "type": "string",
    "default": "",
    "pattern": "^(.*)$"
};

const localization = {
    type: "object",
    required: ["timezone", "keyboard"],
    default: {},
    properties: {
        timezone,
        keyboard
    }
};

const configuration = {
    type: "object",
    required: ["display", "localization"],
    default: {},
    properties: {
        display,
        localization
    }
};

const ip = {
    type: "string",
    //format: 'ipv4',
    default: "",
};

const machineType = {
    type: "string",
    //format: 'ipv4',
    default: "",
};

const playing = {
    type: "boolean",
    default: false,
};

const error = {
    type: "string",
    default: "",
    pattern: "^(.*)$"
};

const description = {
    type: "string",
    default: "",
    pattern: "^(.*)$"
};

const date = {
    type: "string",
    default: "",
    format: 'date'
};

const errors = {
    type: "array",
    default: [],
    items: {
        type: "object",
        default: {},
        required: ["error", "description", "date"],
        properties: {
            error,
            description,
            date
        },
    }
};

const serialPorts = {
    type: 'array',
    default: [],
    items: {
        type: 'object',
        required: ['port', 'description'],
        properties: {
            port: commonTypes.string,
            description: commonTypes.string
        },
    }
};

const state = {
    type: "object",
    required: ["machineType", "ip", "playing", "errors", "serialPorts", "updatedAt"],
    default: {},
    properties: {
        machineType,
        ip,
        playing,
        errors,
        serialPorts,
        updatedAt: commonTypes.string
    }
};

const screenshot = {
    type: "object",
    required: ["file", "createdAt", "updatedAt"],
    default: {
        file: '',
        createdAt: '',
        updatedAt: ''
    },
    properties: {
        file: commonTypes.string,
        createdAt: commonTypes.date_time,
        updatedAt: commonTypes.date_time
    }
};

const playerProperties = {
    _id,
    name,
    id,
    group,
    license: commonTypes.string,
    location,
    version: commonTypes.string,
    upgradeVersion: commonTypes.string,
    configuration,
    state,
    allowPreRelease: commonTypes.booleanFalse,
    screenshot
};

//////////////// SCHEMAS ////////////////////

const playerSchema = {
    properties: playerProperties
};

const playerCreateSchema = {
    required: ["name", "id", "group"],
    properties: playerProperties
};

const playerPatchSchema = {
    properties: playerProperties
};

const playerStatePatchSchema = {
    required: ['state'],
    properties: {
        state
    }
};

export {
    playerSchema,
    playerCreateSchema,
    playerPatchSchema,
    playerStatePatchSchema
};