import React from "react";
// react component plugin for creating a beautiful datetime dropdown picker
import Datetime from "react-datetime";
import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// @material-ui/icons
// import Add from "@material-ui/icons/Add";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class MediaOptionsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: this.props.mediaOptions._id,
      name: this.props.mediaOptions.name,
      defaultDuration: this.props.mediaOptions.defaultDuration,
      mute: this.props.mediaOptions.mute,
      validFrom: this.props.mediaOptions.validFrom,
      validUntil: this.props.mediaOptions.validUntil,
      url: this.props.mediaOptions.url
    };
    this.lastMediaOptions = props.mediaOptions;
  }

  handleChange(event, option) {
    this.setState({
      [option]: event.target.value
    });
  }

  handleMediaValidityChange = name => event => {
    //en principi l'event hauria de portar la data (dins un momentjs), però per si de cas, fem un try catch i si falla llavors posem la data a null
    try {
      var temp = event.toDate();
    } catch (e) {
      temp = "";
    }
    this.setState({
      [name]: temp
    });
  };

  handleMuteChange = name => event => {
    //en principi l'event hauria de portar la data (dins un momentjs), però per si de cas, fem un try catch i si falla llavors posem la data a null
    this.setState({
      [name]: event.target.checked
    });
  };

  handleMediaTimeChange(event, name) {
    var newDuration = parseInt(event.target.value);

    if (
      this.props.type === "videostream" ||
      this.props.type === "audiostream"
    ) {
      if (newDuration < 0) newDuration = 0;
    } else if (newDuration < 1) {
      newDuration = 1;
    }
    this.setState({
      [name]: newDuration
    });
  }

  handleApply(options) {
    if (this.props.onClose) this.props.onClose();
    if (this.props.onAccept) this.props.onAccept(options);
  }

  render() {
    const { classes, open } = this.props;
    const { t } = this.props;

    return (
      <Dialog
        classes={{
          root: this.props.classes.center + " " + this.props.classes.modalRoot,
          paper: this.props.classes.modal
        }}
        TransitionComponent={Transition}
        keepMounted
        open={open}
        onClose={() => {
          // Tornem a posar el valor inicial per si l'hem modificat i no ho canviem
          this.setState(this.lastMediaOptions);
          if (this.props.onClose) this.props.onClose();
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{ t('translation:media_options') }</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12}>
              <CustomInput
                //success={this.state.registerEmailState === "success"}
                //error={this.state.registerEmailState === "error"}
                labelText={ t('translation:name') }
                id="MediaName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  value: this.state.name,
                  onChange: event => this.handleChange(event, "name"),
                  type: "text"
                }}
              />
            </GridItem>
            {(this.props.type === "webpage" ||
              this.props.type === "videostream" ||
              this.props.type === "audiostream") && (
              <GridItem xs={12}>
                <CustomInput
                  //success={this.state.registerEmailState === "success"}
                  //error={this.state.registerEmailState === "error"}
                  labelText={ t('translation:url') }
                  id="url"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "url",
                    value: this.state.url,
                    onChange: event => this.handleChange(event, "url")
                  }}
                />
              </GridItem>
            )}
            <GridItem xs={6}>
              <CustomInput
                labelText={ t('translation:default_duration_s') }
                id="defaultDuration"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  type: "number",
                  value: this.state.defaultDuration,
                  disabled:
                    this.props.type === "video" || this.props.type === "audio",
                  onChange: event =>
                    this.handleMediaTimeChange(event, "defaultDuration")
                }}
              />
            </GridItem>
            <GridItem xs={6}>
              {(this.props.type === "video" ||
                this.props.type === "videostream") && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.mute}
                      onChange={this.handleMuteChange("mute")}
                      value="mute"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        icon: classes.switchIcon,
                        iconChecked: classes.switchIconChecked,
                        bar: classes.switchBar
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label={ t('translation:mute') }
                />
              )}
            </GridItem>
            <GridItem xs={12} sm={6}>
              <InputLabel className={classes.label}>{ t('translation:valid_from') }</InputLabel>
              {/*<FormControl fullWidth>*/}
              <Datetime
                timeFormat={false}
                closeOnSelect
                inputProps={{ placeholder: t('translation:valid_from') }}
                onChange={this.handleMediaValidityChange("validFrom")}
                value={this.state.validFrom}
              />
              {/*</FormControl>*/}
            </GridItem>
            <GridItem xs={12} sm={6}>
              <InputLabel className={classes.label}>{ t('translation:valid_until') }</InputLabel>
              {/*<FormControl fullWidth>*/}
              <Datetime
                timeFormat={false}
                closeOnSelect
                inputProps={{ placeholder: t('translation:valid_until') }}
                onChange={this.handleMediaValidityChange("validUntil")}
                value={this.state.validUntil}
              />
              {/*</FormControl>*/}
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            simple
            color="success"
            onClick={() => this.handleApply(this.state)}
            className={classes.registerButton}
            xs={3}
          >
            { t('translation:apply') }
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(dashboardStyle)(withTranslation()(MediaOptionsDialog));
