module.exports = {
    mediaDuration: 10,
    timedTime: 30,
    dashboardTimeout: 300, //seconds
    dashboardErrorTimeout: 10, //seconds
    momentFormat: 'lll',
    momentFormatDay: 'L',
    playersUpdateState: 60, //seconds
    panelSize: 500,
    /*configTimeout: 10000,
    playerUpdate: 60000,
    webPreferences: {
        nodeIntegration: true
    }*/
}