import React from "react";
import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui/icons
// import Add from "@material-ui/icons/Add";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class CategoryOptionsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      _id: this.props._id,
      name: this.props.name
    };
    this.lastProps = props;
  }

  handleChange(event, option) {
    this.setState({
      [option]: event.target.value
    });
  }

  handleApply(options) {
    options.name = options.name.trim();
    if (this.props.onClose) this.props.onClose();
    if (this.props.onAccept) this.props.onAccept(options);
  }

  render() {
    const { classes, onClose, open } = this.props;
    const { t } = this.props;

    return (
      <Dialog
        classes={{
          root: this.props.classes.center + " " + this.props.classes.modalRoot,
          paper: this.props.classes.modal
        }}
        TransitionComponent={Transition}
        keepMounted
        open={open}
        onClose={() => {
          // Tornem a posar el valor inicial per si l'hem modificat i no ho canviem
          this.setState({
            _id: this.lastProps._id,
            name: this.lastProps.name
          });
          onClose();
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.modalTitle}>{ t('translation:category_options') }</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <CustomInput
            //success={this.state.registerEmailState === "success"}
            //error={this.state.registerEmailState === "error"}
            labelText={ t('translation:name') }
            id="categoryName"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              value: this.state.name,
              onChange: event => {
                this.handleChange(event, "name");
              },
              type: "text"
            }}
          />
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            simple
            color="success"
            onClick={() => this.handleApply(this.state)}
            className={classes.registerButton}
            xs={3}
          >
            { t('translation:apply') }
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(dashboardStyle)(withTranslation()(CategoryOptionsDialog));
