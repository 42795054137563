import { ListItem } from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import { Rnd } from 'react-rnd';
import ReactResizeDetector, { useResizeDetector, withResizeDetector } from 'react-resize-detector';

import '../../assets/css/ScreenPreview.css';

const ScreenSplit = ({ sectionsList, selectedSection, screenWidth, screenHeight, onResize, onDrag, changeSelected }) => {
    //const [list, setList] = useState(sectionsList);
    //const [list, setList] = useState([]);

    const { width, height, ref } = useResizeDetector();
    const [scaleX, setScaleX] = useState(1);
    const [scaleY, setScaleY] = useState(1);
    //const [selectedSection, setSelectedIndex] = useState(0);

    /*
        useEffect(() => {
            if (width) {
                const wScale = width / screenWidth / 100;
                const hScale = height / screenHeight / 100;
                var newList = [];
                list.forEach(item => {
                    newList.push({
                        position: {
                            x: item.x * wScale,
                            y: item.y * hScale,
                            width: item.width * wScale,
                            height: item.height * hScale
                        }
                    })
                })
                setList(newList);
            }
        }, [width]);
    */

    /*
    useEffect(() => {
        setList([]);
        return () => {
            console.log("Hello");
        }
    }, [sectionsList]);
    */

    useEffect(() => {
        if (width) {
            setScaleX(width / screenWidth);
        }
        if (height) {
            setScaleY(height / screenHeight);
        }
    }, [width, height]);

    function getX(x) {
        return x * screenWidth;
    }

    function getY(y) {
        return y * screenHeight;
    }

    function getWidth(w) {
        return w * screenWidth;
    }

    function getHeight(h) {
        return h * screenHeight;
    }

    return (
        <div className="screen" style={{ paddingTop: (screenHeight * 100.0 / screenWidth + "%") }}>
            <div ref={ref} className="splitter-container">
                <div style={{ transform: 'scale(' + scaleX + ',' + scaleY + ')', width: screenWidth + 'px', height: screenHeight + 'px', transformOrigin: 'left top' }}>
                    {sectionsList.map((item, index) =>
                        <Rnd
                            default={{
                                x: getX(item.size.x),
                                y: getY(item.size.y),
                                width: getWidth(item.size.width),
                                height: getHeight(item.size.height),
                            }}
                            scale={scaleX}
                            resizeGrid={[screenWidth * .01 * scaleX, screenHeight * .01 * scaleX]}
                            dragGrid={[screenWidth * .01 * scaleX, screenHeight * .01 * scaleX]}
                            //resizeGrid={[10, 10]}
                            //dragGrid={[10, 10]}
                            minWidth={screenWidth * .1 + 'px'}
                            minHeight={screenHeight * .1 + 'px'}
                            maxWidth={screenWidth + 'px'}
                            maxHeight={screenHeight + 'px'}
                            bounds="parent"
                            key={index}
                            className='splitter-content'
                            onResizeStart={() => {
                                //setSelectedIndex(index);
                                changeSelected(index);
                            }}
                            onResize={(e, dir, refToElement, delta, position) => {
                                //console.log(dir, refToElement, delta, position);
                                const size = {
                                    x: position.x / screenWidth,
                                    y: position.y / screenHeight,
                                    width: refToElement.clientWidth / screenWidth,
                                    height: refToElement.clientHeight / screenHeight,
                                }
                                onResize(index, size);
                            }}
                            onDragStart={() => {
                                //setSelectedIndex(index);
                                changeSelected(index)
                            }}
                            onDrag={(e, data) => {
                                const position = {
                                    x: data.lastX / screenWidth,
                                    y: data.lastY / screenHeight,
                                }
                                onDrag(index, position);
                            }}
                        >
                            <div
                                className={'center ' + (selectedSection === index ? 'selected' : 'not-selected')}
                                onMouseDown={() => {
                                    //setSelectedIndex(index);
                                    changeSelected(index);
                                }}
                            >
                                {index}
                            </div>
                        </Rnd>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ScreenSplit;