import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";

// @material-ui/icons
import ExitToApp from "@material-ui/icons/ExitToAppOutlined";

// core components
import Button from "components/CustomButtons/Button.jsx";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.jsx';
//import Badge from 'components/Badge/Badge.jsx';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import headerLinksStyle from "assets/jss/material-dashboard-pro-react/components/headerLinksStyle";

import { userProfile, feathersAuth, feathersClient } from "helpers/feathers";
import config from "helpers/config";

/*import usFlag from "assets/img/flags/US.png";
import deFlag from "assets/img/flags/DE.png";
import gbFlag from "assets/img/flags/GB.png";
import frFlag from "assets/img/flags/FR.png";
import roFlag from "assets/img/flags/RO.png";
import itFlag from "assets/img/flags/IT.png";
import esFlag from "assets/img/flags/ES.png";
import brFlag from "assets/img/flags/BR.png";
import jpFlag from "assets/img/flags/JP.png";*/

class HeaderLinks extends React.Component {
  state = {
    open: false,
    //flag: gbFlag
  };

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleSignOut = async () => {
    await feathersAuth.signout();
    window.location.href = config.web + "/wp-login.php?action=logout";
    //history.push('/');
  }

  changeLanguage(language) {
    feathersClient
      .service("users")
      .patch(userProfile._id, {
        language: language
      }).then(() => {
        this.props.i18n.changeLanguage(language, () => {
          /*this.setState({
            flag: gbFlag
          })*/
        });
      }).catch(error => {
        //console.log(error);
      })
  }

  render() {
    const { classes, rtlActive } = this.props;
    const wrapper = classNames({
      [classes.wrapperRTL]: rtlActive
    });
    const { t, i18n } = this.props;

    return (
      <div className={wrapper}>
        <GridContainer>
          <GridItem>
            <CustomDropdown
              buttonText={<span>{/*<img src={this.state.flag} alt="..." />*/}{" " + t('change_language')}</span>}
              buttonProps={{
                className: classes.navLink,
                color: "transparent",
              }}
              dropdownList={[
                <span onClick={() => {
                  this.changeLanguage('en');
                }}>
                  {/*<img src={gbFlag} alt="..." />*/}
                  {" English"}
                </span>,
                <span onClick={() => {
                  this.changeLanguage('es');
                }}>
                  {/*<img src={esFlag} alt="..." />*/}
                  {" Español"}
                </span>,
                <span onClick={() => {
                  this.changeLanguage('ca');
                }}>
                  {/*<img src={frFlag} alt="..." />*/}
                  {" Català"}
                </span>,
              ]}
            />
          </GridItem>
          <GridItem>
            <Button
              color="transparent"
              aria-label="Exit"
              justIcon
              className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
              muiClasses={{
                label: rtlActive ? classes.labelRTL : ""
              }}
              onClick={this.handleSignOut}
            >
              <ExitToApp
                className={
                  classes.headerLinksSvg +
                  " " +
                  (rtlActive
                    ? classes.links + " " + classes.linksRTL
                    : classes.links)
                }
              />
              <Hidden mdUp implementation="css">
                <span className={classes.linkText}>
                  {rtlActive ? "الملف الشخصي" : t('translation:sign_out')}
                </span>
              </Hidden>
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(headerLinksStyle)(withTranslation()(HeaderLinks));
