//TODO: Que es seleccioni automàticament la primera llicència disponible

import React from "react";

import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import NavPills from "components/NavPills/NavPills2.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import MaskedInput from "components/CustomInput/MaskedInput.jsx";

// @material-ui/icons
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Delete from "@material-ui/icons/DeleteOutline";
import List from "@material-ui/icons/List";

import momentTimezone from "moment-timezone";
import moment from "moment";

import { layouts } from "../../helpers/keyboard-layouts";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import defaults from '../../helpers/defaults';
import hdmiModes from '../../helpers/hdmiModes.js'

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

const controlTypes = [
  {
    type: "cec",
    description: "HDMI CEC Control *"
  },
  {
    type: "retual",
    description: "Retual Totem"
  },
  {
    type: "lg",
    description: "LG Digital Signage"
  }
];

const macMask = /^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/;
const macRegExp = RegExp(macMask);

class PlayerOptionsDialog extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      playerOptions: this.props.playerOptions,
      idError: true,
      tab: 0,
      selectedHdmiMode: this.getHdmiModeIndex(this.props.playerOptions.configuration.display),
      selectedSerialPort: this.props.playerOptions.configuration.display.controlPort,
      serialPortsListDialog: false,
    };
    this.lastPlayerOptions = props.playerOptions;
    this.tabChild = React.createRef();
  }

  getLicenseValidity(validUntil) {
    if (
      moment()
        .add(50, "years")
        .isBefore(validUntil)
    ) {
      return this.props.t('translation:lifetime_license');
    } else {
      return this.props.t('translation:valid_until') + ": " + moment(validUntil).format(defaults.momentFormatDay);
    }
  }

  getHdmiModeIndex(display) {
    return hdmiModes.findIndex(hdmiMode => {
      return ((hdmiMode.group === display.hdmiGroup) && (hdmiMode.mode === display.hdmiMode || display.hdmiGroup === 'Auto'))
    });
  }

  getHdmiModeDescription(hdmiMode) {
    var description = 'Auto'
    if (hdmiMode.group !== 'Auto') {
      description = hdmiMode.resolution;
      if (hdmiMode.frequency !== "") {
        description = description + ' ' + hdmiMode.frequency;
      }
      if (hdmiMode.aspectRatio !== "") {
        description = description + ' ' + hdmiMode.aspectRatio + ' aspect ratio';
      }
      description = description + ' ' + hdmiMode.description + ' (' + hdmiMode.group + ' ' + hdmiMode.mode + ')';
    }
    return description;
  }

  handlePlayerOptionChange(event, option) {
    var temp = this.state.playerOptions;
    temp[option] = event.target.value;
    this.setState({
      playerOptions: temp
    });
  }

  handleControlPortChange(event, option) {
    var temp = this.state.playerOptions;
    temp.configuration.display.controlPort = event.target.value;
    this.setState({
      playerOptions: temp
    });
  }

  handleApply(options) {
    if (this.props.onClose) this.props.onClose();
    if (this.props.onAccept) this.props.onAccept(options);
  }

  applySerialPortsListDialogClick(serialPort) {
    var temp = this.state.playerOptions;
    temp.configuration.display.controlPort = serialPort;
    this.setState({
      playerOptions: temp
    });
  }

  handleGroupSelectChange = event => {
    // Si hem canviat el valor del select, hem de canviar el grup
    var temp = this.state.playerOptions;
    //temp['groupName'] = event.target.value;
    temp["group"] = event.target.value;
    this.setState({ playerOptions: temp });
  };

  handleControlTypeSelectChange = event => {
    // Si hem canviat el valor del select, hem de canviar el tipus de control
    var temp = this.state.playerOptions;
    temp.configuration.display.controlType = event.target.value;
    this.setState({ playerOptions: temp });
  };

  handleSerialPortSelectChange = event => {
    // Si hem canviat el valor del select, hem de canviar el port serie
    this.setState({ selectedSerialPort: event.target.value });
  };

  handleRotationChange = event => {
    // Si hem canviat el valor de la rotacio
    var temp = this.state.playerOptions;
    temp.configuration.display.rotation = Number(event.target.value);
    this.setState({ playerOptions: temp });
  };

  handleControlTVChange = () => event => {
    var temp = this.state.playerOptions;
    temp.configuration.display.controlTV = event.target.checked;
    this.setState({ playerOptions: temp });
  };

  handlePanelModeChange = () => event => {
    var temp = this.state.playerOptions;
    temp.configuration.display.panelMode = event.target.checked;
    this.setState({ playerOptions: temp });
  };

  handlePanelSizeChange(event, name) {
    var newSize = parseInt(event.target.value);

    if (newSize < 1) {
      newSize = 1;
    }

    var temp = this.state.playerOptions;
    temp.configuration.display[name] = newSize;
    this.setState({ playerOptions: temp });
  }

  handleDisableHDMIChange = () => event => {
    var temp = this.state.playerOptions;
    temp.configuration.display.disableHDMI = event.target.checked;
    this.setState({ playerOptions: temp });
  };

  handleLicenseChange = event => {
    // Si hem canviat el valor del select, hem de canviar el tipus de control
    var temp = this.state.playerOptions;
    temp.license = event.target.value;
    this.setState({ playerOptions: temp });
  };

  handleTimezoneChange = event => {
    // Si hem canviat el valor del select, hem de canviar el tipus de control
    var temp = this.state.playerOptions;
    temp.configuration.localization.timezone = event.target.value;
    this.setState({ playerOptions: temp });
  };

  handleKeyboardChange = event => {
    // Si hem canviat el valor del select, hem de canviar el tipus de control
    var temp = this.state.playerOptions;
    temp.configuration.localization.keyboard = event.target.value;
    this.setState({ playerOptions: temp });
  };

  handleHdmiModeSelectChange = event => {
    // Si hem canviat el valor del select, hem de canviar el tipus de control
    var temp = this.state.playerOptions;
    temp.configuration.display.hdmiGroup = hdmiModes[event.target.value].group;
    temp.configuration.display.hdmiMode = hdmiModes[event.target.value].mode;
    this.setState({ playerOptions: temp, selectedHdmiMode: event.target.value });
  }

  deleteLicense = event => {
    // Si hem canviat el valor del select, hem de canviar el tipus de control
    var temp = this.state.playerOptions;
    temp.license = "";
    this.setState({ playerOptions: temp });
  };

  renderLicense() {
    const { classes } = this.props;

    const license_id = this.state.playerOptions.license;
    const temp_licenses = this.props.licenses.filter(
      license => license._id === license_id
    );
    var license = {};
    const available_licenses = this.props.licenses.filter(
      license =>
        (moment(license.validUntil).isSameOrAfter(moment()) &&
          this.props.players.filter(player => player.license === license._id)
            .length === 0) ||
        license._id === license_id
    );
    /*const available_licenses = available_licenses.filter(license =>
      moment(license.validUntil).isSameOrAfter(moment())
    );*/
    if (temp_licenses.length > 0) {
      license = temp_licenses[0];
    }
    if (temp_licenses.length === 0) {
      //La llicència no és vàlida
      return (
        <GridItem xs={12}>
          <FormControl
            fullWidth
            className={classes.selectFormControl}
            style={{ textAlign: "left" }}
          >
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              {this.props.t('translation:choose_license')}
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={""}
              onChange={this.handleLicenseChange}
              inputProps={{
                name: "licenseSelect",
                id: "license-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
              >
                {this.props.t('translation:choose_license')}
              </MenuItem>
              {available_licenses.map(license => {
                return (
                  <MenuItem
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected
                    }}
                    value={license._id}
                    key={license._id} //Posem el valor key per que el React no es queixi. Aquesta clau ha de ser única
                  >
                    {license.id +
                      " - " +
                      this.getLicenseValidity(license.validUntil)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </GridItem>
      );
    } else {
      return (
        <GridContainer>
          <GridItem xs={10}>
            <h4 className={classes.cardIconTitle}>
              {license.id + " - " + this.getLicenseValidity(license.validUntil)}
            </h4>
          </GridItem>
          <GridItem xs={2}>
            <Button
              color="danger"
              simple
              justIcon
              onClick={() => this.deleteLicense()}
            >
              <Delete className={classes.underChartIcons} />
            </Button>
          </GridItem>
        </GridContainer>
      );
    }
  };

  showSerialPortsListDialog(show) {
    //if (this._isMounted)
      this.setState({
        serialPortsListDialog: show
      });
  };

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <Dialog
        classes={{
          root: this.props.classes.center + " " + this.props.classes.modalRoot,
          paper: this.props.classes.modal
        }}
        TransitionComponent={Transition}
        keepMounted
        open={this.props.open}
        onClose={() => {
          // Tornem a posar el valor inicial per si l'hem modificat i no ho canviem
          this.setState(this.lastPlayerOptions);
          if (this.props.onClose) this.props.onClose();
        }}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <h4 className={classes.cardIconTitle}>{t('translation:player_configuration')}</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <NavPills
            ref={this.tabChild}
            color="success"
            simple
            id="tabs"
            active={this.state.tab}
            tabs={[
              {
                tabButton: t('translation:general'),
                tabContent: (
                  <GridContainer>
                    <GridItem xs={12}>
                      <CustomInput
                        //success={this.state.registerEmailState === "success"}
                        //error={this.state.registerEmailState === "error"}
                        labelText={t('translation:name')}
                        id="name"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.playerOptions.name,
                          onChange: event =>
                            this.handlePlayerOptionChange(event, "name"),
                          type: "text"
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <MaskedInput
                        //success={this.state.registerEmailState === "success"}
                        //error={this.state.idError}
                        labelText={t('translation:player_id')}
                        id="id"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.playerOptions.id,
                          onChange: event =>
                            this.handlePlayerOptionChange(event, "id"),
                          type: "text"
                        }}
                        error={!macRegExp.test(this.state.playerOptions.id)}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ textAlign: "left" }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          {t('translation:group')}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.playerOptions.group}
                          onChange={this.handleGroupSelectChange}
                          inputProps={{
                            name: "groupSelect",
                            id: "groupName-select"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            {t('translation:choose_group')}
                          </MenuItem>
                          {this.props.groups.map(group => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={group._id}
                                key={group._id} //Posem el valor key per que el React no es queixi. Aquesta clau ha de ser única
                              >
                                {group.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12}>
                      <CustomInput
                        //success={this.state.registerEmailState === "success"}
                        //error={this.state.registerEmailState === "error"}
                        labelText={t('translation:location')}
                        id="location"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.playerOptions.location,
                          onChange: event =>
                            this.handlePlayerOptionChange(event, "location"),
                          type: "text"
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                )
              },
              {
                tabButton: t('translation:display'),
                tabContent: (
                  <GridContainer>
                    <GridItem xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={
                              this.state.playerOptions.configuration.display
                                .controlTV
                            }
                            onChange={this.handleControlTVChange("controlTV")}
                            value="controlTV"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={t('translation:control_tv_on_off')}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={
                              this.state.playerOptions.configuration.display
                                .disableHDMI
                            }
                            onChange={this.handleDisableHDMIChange(
                              "disableHDMI"
                            )}
                            value="disableHDMI"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={t('translation:disable_hdmi_when_tv_off')}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ textAlign: "left" }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ color: "#999999" }}
                        >
                          {t('translation:control_type')}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={
                            this.state.playerOptions.configuration.display
                              .controlType
                          }
                          onChange={this.handleControlTypeSelectChange}
                          inputProps={{
                            name: "controlTypeSelect",
                            id: "controlType-select"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            {t('translation:choose_control_type')}
                          </MenuItem>
                          {controlTypes.map(controlType => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={controlType.type}
                                key={controlType.type} //Posem el valor key per que el React no es queixi. Aquesta clau ha de ser única
                              >
                                {controlType.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        //success={this.state.registerEmailState === "success"}
                        //error={this.state.registerEmailState === "error"}
                        labelText={t('translation:port')}
                        //labelText={t('translation:serial_port')}
                        id="controlPort"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.playerOptions.configuration.display
                            .controlPort,
                          onChange: event =>
                            this.handleControlPortChange(event, "name"),
                          type: "text",
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              className={classes.inputAdornment}
                              color="warning"
                            >
                              <Tooltip
                                disableFocusListener={true}
                                id="tooltip-top"
                                title={this.props.t('translation:available_serial_ports')}
                                placement="top"
                                classes={{ tooltip: this.props.classes.tooltip }}
                              >
                                <Button
                                  justIcon
                                  round
                                  simple
                                  onClick={() => {
                                    this.showSerialPortsListDialog(true);
                                  }}
                                  color="warning"
                                  className="edit"
                                /*disabled={
                                  !this.getUpgradable(player, this.state.githubVersion, this.state.githubPreVersion)
                                }*/
                                >
                                  <List />
                                </Button>
                              </Tooltip>
                            </InputAdornment>
                          )
                        }}
                      />
                      <Dialog
                        classes={{
                          root:
                            this.props.classes.center + " " + this.props.classes.modalRoot,
                          paper: this.props.classes.modal
                        }}
                        fullWidth
                        TransitionComponent={Transition}
                        keepMounted
                        open={this.state.serialPortsListDialog}
                        onClose={() => this.showSerialPortsListDialog(false)}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                      >
                        <DialogTitle
                          id="classic-modal-slide-title"
                          disableTypography
                          className={classes.modalHeader}
                        >
                          <h4 className={classes.modalTitle}>{t('translation:available_serial_ports')}</h4>
                        </DialogTitle>
                        <DialogContent
                          id="classic-modal-slide-description"
                          className={classes.modalBody}
                        >
                          <GridContainer>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                              style={{ textAlign: "left" }}
                            >
                              <InputLabel
                                htmlFor="simple-select"
                                className={classes.selectLabel}
                                style={{ color: "#999999" }}
                              >
                                {t('translation:select_serial_port')}
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={
                                  this.state.selectedSerialPort
                                }
                                onChange={this.handleSerialPortSelectChange}
                                inputProps={{
                                  name: "serialPortSelect",
                                  id: "serialPort-select"
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem
                                  }}
                                >
                                  {t('translation:select_serial_port')}
                                </MenuItem>
                                {this.state.playerOptions.state.serialPorts.map(serialPort => {
                                  return (
                                    <MenuItem
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelected
                                      }}
                                      value={serialPort.port}
                                      key={serialPort.port} //Posem el valor key per que el React no es queixi. Aquesta clau ha de ser única
                                    >
                                      {serialPort.port + ((serialPort.description !== '') ? (' - ' + serialPort.description) : '')}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </GridContainer>
                        </DialogContent>
                        <DialogActions className={classes.modalFooter}>
                          <Button
                            simple
                            color="success"
                            onClick={() => {
                              this.applySerialPortsListDialogClick(this.state.selectedSerialPort);
                              this.showSerialPortsListDialog(false);
                            }
                            }
                            className={classes.registerButton}
                            xs={3}
                          >
                            {t('translation:apply')}
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </GridItem>
                    <GridItem xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={
                              this.state.playerOptions.configuration.display
                                .panelMode
                            }
                            onChange={this.handlePanelModeChange("panelMode")}
                            value="panelMode"
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={t('translation:panel_mode_on_off')}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={3}>
                      <CustomInput
                        labelText={t('translation:panel_width_px')}
                        id="panelWidth"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: "number",
                          value: this.state.playerOptions.configuration.display.panelWidth,
                          disabled: !this.state.playerOptions.configuration.display.panelMode,
                          onChange: event =>
                            this.handlePanelSizeChange(event, "panelWidth")
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6} sm={3}>
                      <CustomInput
                        labelText={t('translation:panel_heigth_px')}
                        id="panelHeight"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          type: "number",
                          value: this.state.playerOptions.configuration.display.panelHeight,
                          disabled: !this.state.playerOptions.configuration.display.panelMode,
                          onChange: event =>
                            this.handlePanelSizeChange(event, "panelHeight")
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                      <FormLabel
                        className={
                          classes.labelHorizontal +
                          " " +
                          classes.labelHorizontalRadioCheckbox
                        }
                      >
                        {t('translation:rotation')}
                      </FormLabel>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                      <div className={classes.inlineChecks}>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  this.state.playerOptions.configuration.display
                                    .rotation === 0
                                }
                                onChange={this.handleRotationChange}
                                value="0"
                                name="radio button demo"
                                aria-label="A"
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="0º"
                          />
                        </div>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                      <div className={classes.inlineChecks}>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  this.state.playerOptions.configuration.display
                                    .rotation === 90
                                }
                                onChange={this.handleRotationChange}
                                value="90"
                                name="radio button demo"
                                aria-label="B"
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="90º"
                          />
                        </div>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                      <div className={classes.inlineChecks}>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  this.state.playerOptions.configuration.display
                                    .rotation === 180
                                }
                                onChange={this.handleRotationChange}
                                value="180"
                                name="radio button demo"
                                aria-label="B"
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="180º"
                          />
                        </div>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={2}>
                      <div className={classes.inlineChecks}>
                        <div
                          className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                          }
                        >
                          <FormControlLabel
                            control={
                              <Radio
                                checked={
                                  this.state.playerOptions.configuration.display
                                    .rotation === 270
                                }
                                onChange={this.handleRotationChange}
                                value="270"
                                name="radio button demo"
                                aria-label="B"
                                icon={
                                  <FiberManualRecord
                                    className={classes.radioUnchecked}
                                  />
                                }
                                checkedIcon={
                                  <FiberManualRecord
                                    className={classes.radioChecked}
                                  />
                                }
                                classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot
                                }}
                              />
                            }
                            classes={{
                              label: classes.label
                            }}
                            label="270º"
                          />
                        </div>
                      </div>
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ textAlign: "left" }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ color: "#999999" }}
                        >
                          {t('translation:hdmi_mode')}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={this.state.selectedHdmiMode
                            /*this.state.playerOptions.configuration.display
                              .controlType*/
                          }
                          onChange={this.handleHdmiModeSelectChange}
                          inputProps={{
                            name: "hdmiModeSelect",
                            id: "hdmiMode-select"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            {t('translation:choose_hdmi_mode')}
                          </MenuItem>
                          {hdmiModes.map((hdmiMode, index) => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={index}
                                key={index} //Posem el valor key per que el React no es queixi. Aquesta clau ha de ser única
                              >
                                {this.getHdmiModeDescription(hdmiMode)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                )
              },
              {
                tabButton: t('translation:localization'),
                tabContent: (
                  <GridContainer>
                    <GridItem xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ textAlign: "left" }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          {t('translation:time_zone')}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={
                            this.state.playerOptions.configuration.localization
                              .timezone
                          }
                          onChange={this.handleTimezoneChange}
                          inputProps={{
                            name: "timeZoneSelect",
                            id: "timeZone-select"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            {t('translation:choose_time_zone')}
                          </MenuItem>
                          {momentTimezone.tz.names().map(tz => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={tz}
                                key={tz} //Posem el valor key per que el React no es queixi. Aquesta clau ha de ser única
                              >
                                {tz}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12}>
                      <FormControl
                        fullWidth
                        className={classes.selectFormControl}
                        style={{ textAlign: "left" }}
                      >
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                        >
                          {t('translation:keyboard')}
                        </InputLabel>
                        <Select
                          MenuProps={{
                            className: classes.selectMenu
                          }}
                          classes={{
                            select: classes.select
                          }}
                          value={
                            this.state.playerOptions.configuration.localization
                              .keyboard
                          }
                          onChange={this.handleKeyboardChange}
                          inputProps={{
                            name: "keyboardSelect",
                            id: "keyboard-select"
                          }}
                        >
                          <MenuItem
                            disabled
                            classes={{
                              root: classes.selectMenuItem
                            }}
                          >
                            {t('translation:choose_keyboard')}
                          </MenuItem>
                          {layouts.map(layout => {
                            return (
                              <MenuItem
                                classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelected
                                }}
                                value={layout.layout}
                                key={layout.layout} //Posem el valor key per que el React no es queixi. Aquesta clau ha de ser única
                              >
                                {layout.language}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                )
              },
              {
                tabButton: t('translation:license'),
                tabContent: this.renderLicense()
              }
            ]}
          />
          <GridContainer>
            <GridItem xs={12} style={{ textAlign: "left" }}>
              <div className={classes.stats}>
                <a
                  style={{ color: "#AAAAAA" }}
                  href="https://cityadpro.com/product/player_and_license/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('translation:only_available_with_cityad_pro_player_device')}
                </a>
              </div>
            </GridItem>
            <GridItem xs={12} style={{ textAlign: "left" }}>
              <div className={classes.stats}>
                <a
                  style={{ color: "#AAAAAA" }}
                  href="https://cityadpro.com/product/player_and_license/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('translation:only_compatible_with_rpi_4')}
                </a>
              </div>
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="success"
            simple
            onClick={() => {
              if (macRegExp.test(this.state.playerOptions.id)) {
                this.props.onAccept(this.state.playerOptions);
              } else {
                //this.focusMaskedInput();
                //document.getElementById('tabs').focus();
                document.getElementById("id").focus();
                //this.tabChild.current.changeTab(0);
              }
            }}
            //onClick={this.focusMaskedInput}
            className={classes.registerButton}
            xs={3}
          >
            {t('translation:apply')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(dashboardStyle)(withTranslation()(PlayerOptionsDialog));
