import React from "react";
import { Link } from "react-router-dom";

import { withTranslation, Trans } from 'react-i18next';

// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Assignment from "@material-ui/icons/AssignmentOutlined";
import VpnKey from "@material-ui/icons/VpnKeyOutlined";
import Add from "@material-ui/icons/AddOutlined";
import Delete from "@material-ui/icons/DeleteOutline";
import CheckCircle from "@material-ui/icons/CheckCircleOutlined";
import HighlightOff from "@material-ui/icons/HighlightOffOutlined";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Snackbars from "components/Snackbar/Snackbar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// Feathers
import { feathersClient } from "../../helpers/feathers";
import config from "../../helpers/config";
import ga from "../../helpers/google-analytics";

// Styles
import clientsStyle from "assets/jss/material-dashboard-pro-react/views/clientsStyle";
import moment from "moment";

import defaults from "../../helpers/defaults";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class Clients extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      isClientsAllowed: false,
      clients: [], //Ha de ser un array, si no el map no funciona
      clientOptionsDialog: false,
      clientOptions: {
        _id: "",
        name: ""
      },
      notification: {
        visible: false,
        text: "",
        color: "success"
      }
    };
  }

  componentDidMount() {
    this._isMounted = true;
    ga.pageview(window.location.pathname);

    feathersClient
      .service("subscriptions")
      .find()
      .then(subscriptions => {
        if (subscriptions.data.length > 0) {
          if (subscriptions.data[0].type !== "basic") {
            this.setState({
              isClientsAllowed: true
            })
            feathersClient
              .service("clients")
              .find({ query: { $sort: { name: 1 } } })
              .then(clients => {
                clients.data.forEach(client => {
                  this.addClientLink(client);
                  this.addClientStatus(client);
                  this.addClientActions(client);
                });
                if (this._isMounted)
                  this.setState({
                    clients: clients.data
                  });
                feathersClient
                  .service("clients")
                  .on("created", client => {
                    //console.log(client);
                    this.addClientEvent(client);
                  });
                feathersClient
                  .service("clients")
                  .on("patched", client => this.changeClientOptionsEvent(client));
                feathersClient
                  .service("clients")
                  .on("removed", client => this.removeClientEvent(client));
              })
              .catch(error => {
                this.showNotification(
                  this.props.t('translation:error_fetching_clients') + ": " + error.name,
                  "warning"
                );
                if (this._isMounted)
                  this.setState({
                    clients: []
                  });
              });
          } else {
            this.setState({
              isClientsAllowed: false
            })
          }
        }
      })
      .catch(error => {
        this.showNotification(
          this.props.t('translation:error_fetching_subscription') + ": " + error.name,
          "warning"
        );
      });
  }

  componentWillUnmount() {
    this._isMounted = false;

    feathersClient.service("clients").removeAllListeners("created");
    feathersClient.service("clients").removeAllListeners("patched");
    feathersClient.service("clients").removeAllListeners("removed");
  }

  hideAlert() {
    if (this._isMounted)
      this.setState({
        alert: null
      });
  }

  deleteClientWarning(client) {
    if (this._isMounted)
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title={this.props.t('translation:are_you_sure')}
            onConfirm={() => this.removeClient(client)}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={this.props.t('translation:yes_delete_it')}
            cancelBtnText={this.props.t('translation:cancel')}
            showCancel
          >
            {this.props.t('translation:this_will_remove_the_client')}
          </SweetAlert>
        )
      });
  }

  removeClientEvent(client) {
    var temp = [...this.state.clients];
    var index = temp.findIndex(x => x._id === client._id);
    if (index >= 0) {
      temp.splice(index, 1);
      if (this._isMounted)
        this.setState({
          clients: temp
        });
    }
  }

  removeClient(client) {
    this.hideAlert();
    feathersClient
      .service("clients")
      .remove(client._id)
      .then(message => {
        this.showNotification(this.props.t('translation:client_deleted_successfully'), "success");
      })
      .catch(error => {
        this.showNotification(error.name + ": " + error.message, "warning");
      });
  }

  addNewClientClick = event => {
    var temp = {
      _id: "", //Aquest és l'identificador del registre de la BBDD
      name: "" //Aquí hi va el nom del grup de la DDBB, no l'ID!!!
    };
    if (this._isMounted) this.setState({ clientOptions: temp });
    this.showClientOptions(true);
  };

  changeClientOptionsClick(client) {
    var temp = {
      _id: client._id, //Aquest és l'identificador del registre de la BBDD
      name: client.name //Aquí hi va el nom del grup de la DDBB, no l'ID!!!
    };
    if (this._isMounted) this.setState({ clientOptions: temp });
    this.showClientOptions(true);
  }

  showNotification(message, color) {
    if (!this.state.notification["visible"]) {
      var x = this.state.notification;
      x["visible"] = true;
      x["text"] = message;
      x["color"] = color;
      if (this._isMounted) this.setState(x);
      // use this to make the notification autoclose
      setTimeout(
        function () {
          x["visible"] = false;
          if (this._isMounted) this.setState(x);
        }.bind(this),
        3000
      );
    }
  }

  showClientOptions(show) {
    if (this._isMounted)
      this.setState({
        clientOptionsDialog: show
      });
  }

  handleClientOptionChange(event, option) {
    var temp = this.state.clientOptions;
    temp[option] = event.target.value;
    if (this._isMounted)
      this.setState({
        clientOptions: temp
      });
  }

  applyClientOptionsClick(options) {
    this.showClientOptions(false);
    if (options._id === "") {
      this.addClient(options);
    } else {
      this.changeClientOptions(options);
    }
  }

  addClientEvent(newClient) {
    this.addClientLink(newClient);
    //this.addClientSyncStatus(newClient);
    this.addClientActions(newClient);
    this.addClientStatus(newClient);
    if (this._isMounted)
      this.setState({
        clients: [...this.state.clients, newClient]
      });
  }

  addClient(options) {
    feathersClient
      .service("clients")
      .create({
        name: options.name
      })
      .then(newClient => {
        this.showNotification(this.props.t('translation:new_client_created_correctly'), "success");
        /*this.addClientActions(newClient);
      if (this._isMounted) this.setState({
        clients: [...this.state.clients, newClient]
      });*/
      })
      .catch(error => {
        this.showNotification(this.props.t('translation:error') + ": " + error.name, "warning");
      });
  }

  addClientLink(client) {
    const url = "clients/" + client._id;
    client.link = (
      <Link to={url} key={client.name}>
        {client.name}
      </Link>
    );
  }

  addClientActions(client) {
    client.actions = (
      // we've added some custom button actions
      <div className="actions-right">
        {/* use this button to add a like kind of action */}
        <Tooltip
          disableFocusListener={true}
          id="tooltip-top"
          title={this.props.t('translation:options')}
          placement="top"
          classes={{ tooltip: this.props.classes.tooltip }}
        >
          <Button
            justIcon
            round
            simple
            onClick={() => {
              this.changeClientOptionsClick(client);
            }}
            color="warning"
            className="edit"
          >
            <Assignment />
          </Button>
        </Tooltip>
        {/* use this button to remove the data row */}
        <Tooltip
          disableFocusListener={true}
          id="tooltip-top"
          title={this.props.t('translation:delete')}
          placement="top"
          classes={{ tooltip: this.props.classes.tooltip }}
        >
          <Button
            justIcon
            round
            simple
            onClick={() => this.deleteClientWarning(client)}
            color="danger"
            className="delete"
          >
            <Delete />
          </Button>
        </Tooltip>
      </div>
    );
  }

  addClientStatus(client) {
    client.status = (
      // we've added some custom button actions
      <div className="actions-left">
        <Tooltip
          disableFocusListener={true}
          id="tooltip-top"
          title={
            client.enabled ? this.props.t('translation:enabled') : this.props.t('translation:disabled')
          }
          placement="top"
          classes={{ tooltip: this.props.classes.tooltip }}
        >
          {client.enabled ? (
            <CheckCircle
              style={{
                color: "#4caf50",
                fontSize: 20,
                width: "41px",
                height: "41px",
                paddingLeft: "12px",
                paddingRight: "12px"
              }}
            />
          ) : (
            <HighlightOff
              style={{
                color: "#f44336",
                fontSize: 20,
                width: "41px",
                height: "41px",
                paddingLeft: "12px",
                paddingRight: "12px"
              }}
            />
          )}
        </Tooltip>
      </div>
    );
  }

  changeClientOptionsEvent(newClient) {
    // Ara substituim el fitxer de l'array d'estat per tal que el nou nom surti a la pantalla
    //console.log("Hem rebut l'event: " + newClient);
    this.addClientLink(newClient);
    //this.addClientSyncStatus(newClient);
    this.addClientActions(newClient);
    this.addClientStatus(newClient);
    var temp = [...this.state.clients];
    var index = temp.findIndex(x => x._id === newClient._id);
    temp.splice(index, 1, newClient);
    if (this._isMounted)
      this.setState({
        clients: temp
      });
  }

  changeClientOptions(options) {
    this.showClientOptions(false);
    feathersClient
      .service("clients")
      .patch(options._id, {
        name: options.name
      })
      .then(newClient => {
        this.showNotification(
          this.props.t('translation:new_client_options_submitted_correctly'),
          "success"
        );
      })
      .catch(error => {
        this.showNotification(this.props.t('translation:error') + ": " + error.name, "warning");
      });
  }

  keySort(a, b) {
    return a.key.localeCompare(b.key);
  }

  nameFilter(filter, row) {
    return row._original["name"]
      .toLowerCase()
      .includes(filter.value.toLowerCase());
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div>
        {this.state.alert}
        <Snackbars
          place="tc"
          color={this.state.notification.color}
          //icon={AddAlert}
          message={this.state.notification.text}
          open={this.state.notification.visible}
          closeNotification={() => {
            if (this._isMounted)
              this.setState({ "notification.visible": false });
          }}
        //close
        />
        <Dialog
          classes={{
            root:
              this.props.classes.center + " " + this.props.classes.modalRoot,
            paper: this.props.classes.modal
          }}
          TransitionComponent={Transition}
          keepMounted
          open={this.state.clientOptionsDialog}
          onClose={() => this.showClientOptions(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
          style={{ textAlign: "center" }}
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.cardIconTitle}>{t('translation:client_options')}</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  //success={this.state.registerEmailState === "success"}
                  //error={this.state.registerEmailState === "error"}
                  labelText={t('translation:name')}
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.clientOptions.name,
                    onChange: event =>
                      this.handleClientOptionChange(event, "name"),
                    type: "text"
                  }}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              simple
              color="success"
              onClick={() =>
                this.applyClientOptionsClick(this.state.clientOptions)
              }
              className={classes.registerButton}
              xs={3}
            >
              {t('translation:apply')}
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.isClientsAllowed === false &&
          <Card>
            <CardBody>
              <h4 style={{ textAlign: "center" }}>
                {t('translation:functionality_not_allowed')}
              </h4>
              <h4 style={{ textAlign: "center" }}>
                <Trans i18nKey="functionality_upgrade_your_plan">
                  Please
                  <a href={config.subscriptionURL} target="_blank" rel="noopener noreferrer">
                    upgrade
                  </a>
                  your plan to be able to use this functionality.
                </Trans>
              </h4>
            </CardBody>
          </Card>
          //<h1>This Media is not allowed</h1>
        }
        {this.state.isClientsAllowed === true &&
          <Card>
            <CardHeader color="success" icon>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer>
                    <GridItem>
                      <CardIcon color="success">
                        <VpnKey />
                      </CardIcon>
                    </GridItem>
                    <GridItem xs={6}>
                      <h4 className={classes.cardIconTitle}>{t('translation:client_keys')}</h4>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <GridContainer justify="flex-end">
                    <GridItem>
                      <Button
                        color="success"
                        simple
                        className={classes.marginRight}
                        onClick={this.addNewClientClick}
                        style={{ maxWidth: "140px" }}
                      >
                        <VpnKey
                          className={classes.icons}
                        />
                        {t('translation:new_client')}
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <ReactTable
                data={this.state.clients}
                filterable
                previousText={this.props.t('translation:previous')}
                nextText={this.props.t('translation:next')}
                loadingText={this.props.t('translation:loading')}
                noDataText={this.props.t('translation:no_rows_found')}
                pageText={this.props.t('translation:page')}
                ofText={this.props.t('translation:of')}
                rowsText={this.props.t('translation:rows')}
                pageJumpText={this.props.t('translation:jump_to_page')}
                rowsSelectorText={this.props.t('translation:rows_per_page')}
                columns={[
                  {
                    Header: t('translation:name'),
                    accessor: "link",
                    filterMethod: this.nameFilter,
                    sortMethod: this.keySort
                  },
                  {
                    Header: t('translation:clientID'),
                    accessor: "clientID",
                    filterable: true,
                    sortable: true,
                    //sortMethod: this.keySort
                  },
                  {
                    Header: t('translation:status'),
                    accessor: "status",
                    sortable: false,
                    filterable: false
                  },
                  {
                    Header: t('translation:actions'),
                    accessor: "actions",
                    sortable: false,
                    filterable: false,
                    //minWidth: 185
                  }
                ]}
                defaultPageSize={10}
                showPaginationTop
                showPaginationBottom={false}
                className="-striped -highlight"
              />
            </CardBody>
          </Card>
        }
      </div>
    );
  }
}

export default withStyles(clientsStyle)(withTranslation()(Clients));
