import React from "react";

import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Snackbars from "components/Snackbar/Snackbar.jsx";

// @material-ui/icons
import PhotoCamera from "@material-ui/icons/PhotoCameraOutlined";

import { ReactComponent as NotAvailable } from "../../assets/img/no_image_available.svg";

import moment from "moment";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import config from "../../helpers/config";
import defaults from "../../helpers/defaults";


// Feathers
import { feathersClient } from "../../helpers/feathers";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class PlayerControlDialog extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      player: this.props.player,
      file: this.props.player.screenshot.file,
      createdAt: this.props.player.screenshot.createdAt,
      notification: {
        visible: false,
        text: "",
        color: "success"
      }
    };
  }

  showNotification(message, color) {
    if (!this.state.notification["visible"]) {
      var x = this.state.notification;
      x["visible"] = true;
      x["text"] = message;
      x["color"] = color;
      if (this._isMounted) this.setState(x);
      // use this to make the notification autoclose
      setTimeout(
        function () {
          x["visible"] = false;
          //if (this._isMounted)
          this.setState(x);
        }.bind(this),
        3000
      );
    }
  }

  screenshot() {
    feathersClient
      .service("players")
      .patch(this.state.player._id, { getScreenshot: true })
      .then(player => {
        this.showNotification(
          this.props.t('translation:get_screenshot_sent_to_player'),
          "success"
        );
      })
      .catch(error => {
        this.showNotification(this.props.t('translation:error') + ": " + error.name, "warning");
      });
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.player.screenshot.file !== this.state.file) {
      this.setState({
        file: this.props.player.screenshot.file,
        createdAt: this.props.player.screenshot.createdAt
      })
    }
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div>
        <Snackbars
          place="tc"
          color={this.state.notification.color}
          message={this.state.notification.text}
          open={this.state.notification.visible}
          closeNotification={() => {
            if (this._isMounted)
              this.setState({ "notification.visible": false });
          }}
        />
        <Dialog
          classes={{
            root: this.props.classes.center + " " + this.props.classes.modalRoot,
            paper: this.props.classes.modal
          }}
          TransitionComponent={Transition}
          keepMounted
          open={this.props.open}
          onClose={() => {
            // Tornem a posar el valor inicial per si l'hem modificat i no ho canviem
            if (this.props.onClose) this.props.onClose();
          }}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.cardIconTitle}>{t('translation:screenshots')}</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              {(this.state.file !== "") ?
                (
                  <div>
                    <GridItem xs={12} style={{ textAlign: "center" }}>
                      <img
                        src={"//" + config.api + "/" + this.state.file}
                        loading="lazy"
                        alt="..."
                        //className={classes.imgRounded + " " + classes.imgFluid}
                        style={{ maxWidth: "100%", height: "auto", borderRadius: "6px" }}
                      />
                    </GridItem>
                    <GridItem xs={12} style={{ textAlign: "center" }}>
                      <div className={classes.stats}>
                        {t('translation:screenshot_taken') + moment(this.state.createdAt).format(defaults.momentFormat)}
                      </div>
                    </GridItem>
                  </div>
                )
                :
                <GridItem xs={12} style={{ textAlign: "center" }}>
                  <NotAvailable
                    style={{
                      maxWidth: "100%",
                      maxHeight: "80px",
                      objectFit: "contain",
                      display: "block",
                      margin: "auto"
                    }}
                  />
                </GridItem>
              }
            </GridContainer>
          </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            color="success"
            simple
            onClick={this.screenshot.bind(this)}
            className={classes.registerButton}
            xs={3}
          >
            <PhotoCamera className={classes.icons} /> {t('translation:screenshot')}
          </Button>
        </DialogActions>
        </Dialog >
      </div >
    );
  }
}

export default withStyles(dashboardStyle)(withTranslation()(PlayerControlDialog));
