import Dashboard from "views/Dashboard/Dashboard.jsx";
//import Users from "views/Users/Users.jsx";
import Players from "views/Players/Players.jsx";
import Groups from "views/Groups/Groups.jsx";
import Group from "views/Groups/Group.jsx";
import Playlists from "views/Playlists/Playlists.jsx";
import Playlist from "views/Playlists/Playlist.jsx";
import Media from "views/Media/Media.jsx";
import Clients from "views/Clients/Clients.jsx";
import Client from "views/Clients/Client.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/DashboardOutlined";
import Cast from "@material-ui/icons/CastOutlined";
import PlaylistPlay from "@material-ui/icons/PlaylistPlayOutlined";
import PhotoLibrary from "@material-ui/icons/PhotoLibraryOutlined";
import Extension from "@material-ui/icons/ExtensionOutlined";
//import People from "@material-ui/icons/PeopleOutlined"
import LibraryBooks from "@material-ui/icons/LibraryBooksOutlined";

import { mediaType } from "variables/media.jsx"

import i18n from "../i18n.js";

i18n.on('languageChanged', function(lng) {
  changeNames();
})

i18n.on('loaded', function(loaded) {
  changeNames();
})

function changeNames() {
  dashRoutes[0].name = i18n.t('translation:dashboard');
  dashRoutes[1].name = i18n.t('translation:players');
  dashRoutes[2].name = i18n.t('translation:groups');
  dashRoutes[3].name = i18n.t('translation:group');
  dashRoutes[4].name = i18n.t('translation:playlists');
  dashRoutes[4].views[0].name= i18n.t('translation:video_playlists');
  dashRoutes[4].views[0].mini= i18n.t('translation:vp');
  dashRoutes[4].views[1].name= i18n.t('translation:audio_playlists');
  dashRoutes[4].views[1].mini= i18n.t('translation:ap');
  dashRoutes[5].name = i18n.t('translation:video_playlist');
  dashRoutes[6].name = i18n.t('translation:audio_playlist');
  dashRoutes[7].name = i18n.t('translation:media');
  dashRoutes[7].views[0].name = i18n.t('translation:images');
  dashRoutes[7].views[0].mini = i18n.t('translation:i');
  dashRoutes[7].views[1].name = i18n.t('translation:videos');
  dashRoutes[7].views[1].mini = i18n.t('translation:v');
  dashRoutes[7].views[2].name = i18n.t('translation:video_streams');
  dashRoutes[7].views[2].mini = i18n.t('translation:vs');
  dashRoutes[7].views[3].name = i18n.t('translation:web_pages');
  dashRoutes[7].views[3].mini = i18n.t('translation:w');
  dashRoutes[7].views[4].name = i18n.t('translation:custom_html');
  dashRoutes[7].views[4].mini = i18n.t('translation:h');
  dashRoutes[7].views[5].name = i18n.t('translation:audios');
  dashRoutes[7].views[5].mini = i18n.t('translation:m');
  dashRoutes[7].views[6].name = i18n.t('translation:audio_streams');
  dashRoutes[7].views[6].mini = i18n.t('translation:ms');
  dashRoutes[8].name = i18n.t('translation:client_keys');
  //dashRoutes[9].name = i18n.t('translation:dashboard');
}

var dashRoutes = [
  {
    path: "/dashboard/dashboard",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard
  },
  //{ path: "/dashboard/users", name: "Users", icon: People, component: Users },
  //{ path: "/dashboard/licenses", name: "Licenses", icon: LibraryBooks, component: Licenses },
  { path: "/dashboard/players", name: "Players", icon: Cast, component: Players },
  { path: "/dashboard/groups", name: "Groups", icon: Extension, component: Groups },
  { path: "/dashboard/groups/:groupId", name: "Group", component: Group, single: true },
  {
    collapse: true,
    path: "/dashboard/playlists",
    name: "Playlists",
    state: "openPlaylists", //No sé què és això
    icon: PlaylistPlay,
    views: [
      {
        path: "/dashboard/playlists/videoplaylists",
        name: "Video Playlists",
        mini: "VP",
        component: Playlists,
        playlistType: 'video'
      },
      {
        path: "/dashboard/playlists/audioplaylists",
        name: "Audio Playlists",
        mini: "AP",
        component: Playlists,
        playlistType: 'audio'
      }
    ]
  },
  { path: "/dashboard/playlists/videoplaylists/:playlistId", name: "Video Playlist", component: Playlist, playlistType: 'video', single: true},
  { path: "/dashboard/playlists/audioplaylists/:playlistId", name: "Audio Playlist", component: Playlist, playlistType: 'audio', single: true},
  {
    collapse: true,
    path: "/dashboard/media",
    name: "Media",
    state: "openMedia",
    icon: PhotoLibrary,
    views: [
      {
        path: "/dashboard/media/images",
        name: "Images",
        mini: "I",
        component: Media,
        mediaType: mediaType.images
      },
      {
        path: "/dashboard/media/videos",
        name: "Videos",
        mini: "V",
        component: Media,
        mediaType: mediaType.videos
      },
      {
        path: "/dashboard/media/video-streams",
        name: "Video Streams",
        mini: "VS",
        component: Media,
        mediaType: mediaType.videostreams
      },
      {
        path: "/dashboard/media/webpages",
        name: "Webpages",
        mini: "W",
        component: Media,
        mediaType: mediaType.webpages
      },
      {
        path: "/dashboard/media/html",
        name: "Custom HTML",
        mini: "H",
        component: Media,
        mediaType: mediaType.html
      },
      {
        path: "/dashboard/media/music",
        name: "Music",
        mini: "M",
        component: Media,
        mediaType: mediaType.audios
      },
      {
        path: "/dashboard/media/audio-streams",
        name: "Music Streams",
        mini: "MS",
        component: Media,
        mediaType: mediaType.audiostreams
      },
      /*{
        path: "/dashboard/media/rss",
        name: "RSS Feed",
        mini: "R",
        component: Media
      }*/
    ]
  },
  { path: "/dashboard/clients", name: "Client Keys", icon: LibraryBooks, hidden: true, component: Clients},
  { path: "/dashboard/clients/:clientId", name: "Client", component: Client, single: true },
  {
    redirect: true,
    path: "/dashboard",
    pathTo: "/dashboard/dashboard",
    name: "Dashboard"
  }
];
export default dashRoutes;
