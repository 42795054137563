import Ajv from 'ajv';
import {
    playerSchema
} from "./player";
import {
    playlistSchema
} from "./playlist";
import {
    groupSchema
} from './group';
import {
    userSchema
} from './user';
import {
    clientSchema
} from './client';

const ajv = new Ajv({
    removeAdditional: 'all',
    useDefaults: true
});

const validatePlayer = ajv.compile(playerSchema);
const validatePlaylist = ajv.compile(playlistSchema);
const validateGroup = ajv.compile(groupSchema);
const validateUser = ajv.compile(userSchema);
const validateClient = ajv.compile(clientSchema);

export {
    validatePlayer,
    validatePlaylist,
    validateGroup,
    validateUser,
    validateClient
};