// ##############################
// // // Players View styles
// #############################

// Styles
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx";
import {
  //successColor,
  tooltip,
  cardTitle
} from "assets/jss/material-dashboard-pro-react.jsx";

//import hoverCardStyle from "assets/jss/material-dashboard-pro-react/hoverCardStyle.jsx";

const playersStyle = {
  ...customSelectStyle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px"
  },
  ...buttonStyle,
  tooltip
};

export default playersStyle;