//TODO: Afegir un missatge si no hi ha cap element a la llista que digui "Arrossega les llistes aquí..." o alguna cosa així

import React from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { withTranslation } from 'react-i18next';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { ReactComponent as PlaylistPlay } from "../../assets/img/icons/playlist-play.svg";
import CalendarToday from "@material-ui/icons/CalendarTodayOutlined";
import Delete from "@material-ui/icons/DeleteOutline";

import Tooltip from "@material-ui/core/Tooltip";

import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import mediaColors from "../../helpers/mediaColors";

const Item = styled.div`
  user-select: none;
`;

const Clone = styled(Item)`
  + div {
    display: none !important;
  }
`;

const Notice = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem;
  border: 1px solid transparent;
  line-height: 1.5;
  color: #aaa;
`;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightgrey" : "white",
  minHeight: "50px"
});

class GroupPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <DragDropContext onDragEnd={this.props.onDragEnd}>
        <GridContainer style={{ margin: "0", width: "100%" }}>
          <GridItem xs={12} md={8}>
            <Card style={{ marginTop: "15px", marginBottom: "15px" }}>
              <CardHeader>
                <h4 className={classes.cardIconTitle}>
                  {this.props.listsTitle}
                </h4>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.props.section.options.combine}
                      onChange={this.props.handleCombineChange("combine")}
                      value="combine"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        icon: classes.switchIcon,
                        iconChecked: classes.switchIconChecked,
                        bar: classes.switchBar
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label={ this.props.t('translation:combine_playlists') }
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.props.section.options.mix}
                      onChange={this.props.handleMixChange("mix")}
                      value="mix"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        icon: classes.switchIcon,
                        iconChecked: classes.switchIconChecked,
                        bar: classes.switchBar
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label={ this.props.t('translation:mix_media') }
                />
                {typeof this.props.section.options.mute !== "undefined" && (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.props.section.options.mute}
                        onChange={this.props.handleMuteChange("mute")}
                        value="mute"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label={ this.props.t('translation:mute_playlists') }
                  />
                )}
              </CardHeader>
              <CardBody>
                <Droppable
                  key="list"
                  droppableId="list"
                  style={{ minHeight: "200px" }}
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      /*isDraggingOver={snapshot.isDraggingOver}*/
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.props.section.playlists.length
                        ? this.props.section.playlists.map((playlist, index) => (
                            <Draggable
                              key={playlist.id}
                              draggableId={playlist.id}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Item
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  isDragging={snapshot.isDragging}
                                  style={provided.draggableProps.style}
                                >
                                  <Card
                                    style={{
                                      marginTop: "5px",
                                      marginBottom: "5px",
                                      backgroundColor: mediaColors.getPlaylistBackgroundColor(playlist)
                                    }}
                                  >
                                    <GridContainer alignItems="center">
                                      <GridItem xs={10}>
                                        <GridContainer
                                          alignItems="center"
                                          justify="flex-start"
                                        >
                                          <GridItem xs={2}>
                                            <CardIcon
                                              color="success"
                                              style={{
                                                marginTop: "0px"
                                              }}
                                            >
                                              <PlaylistPlay fill="white" />
                                            </CardIcon>
                                          </GridItem>
                                          <GridItem xs={3}>
                                            <h6>{playlist.name}</h6>
                                          </GridItem>
                                          <GridItem xs={1}>
                                            <Tooltip
                                              disableFocusListener={true}
                                              id="tooltip-top"
                                              title={ this.props.t('translation:schedule') }
                                              placement="bottom"
                                              classes={{
                                                tooltip: classes.tooltip
                                              }}
                                            >
                                              <Button
                                                color="success"
                                                simple
                                                justIcon
                                                onClick={() =>
                                                  this.props.changeScheduleOptions(
                                                    playlist
                                                  )
                                                }
                                              >
                                                <CalendarToday
                                                  className={
                                                    classes.underChartIcons
                                                  }
                                                />
                                              </Button>
                                            </Tooltip>
                                          </GridItem>
                                          <GridItem xs={6}>
                                            {playlist.descriptions.map(
                                              (description, index) => {
                                                return (
                                                  <p
                                                    key={index}
                                                    className={
                                                      classes.cardProductDesciprion
                                                    }
                                                  >
                                                    {description}
                                                  </p>
                                                );
                                              }
                                            )}
                                          </GridItem>
                                        </GridContainer>
                                      </GridItem>
                                      <GridItem xs={2}>
                                        <GridContainer
                                          alignItems="center"
                                          justify="flex-start"
                                        >
                                          <GridItem xs={1}>
                                            <Tooltip
                                              disableFocusListener={true}
                                              id="tooltip-top"
                                              title={ this.props.t('translation:delete') }
                                              placement="bottom"
                                              classes={{
                                                tooltip: classes.tooltip
                                              }}
                                            >
                                              <Button
                                                color="danger"
                                                simple
                                                justIcon
                                                onClick={() =>
                                                  this.props.removePlaylist(
                                                    playlist
                                                  )
                                                }
                                              >
                                                <Delete
                                                  className={
                                                    classes.underChartIcons
                                                  }
                                                />
                                              </Button>
                                            </Tooltip>
                                          </GridItem>
                                        </GridContainer>
                                      </GridItem>
                                    </GridContainer>
                                  </Card>
                                </Item>
                              )}
                            </Draggable>
                          ))
                        : !provided.placeholder && (
                            <Notice>{ t('translation:drag_drop_playlists_here') }</Notice>
                          )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={4}>
            <Card style={{ marginTop: "15px", marginBottom: "15px" }}>
              <CardHeader>
                <h4 className={classes.cardIconTitle}>
                  {this.props.availablelistsTitle}
                </h4>
              </CardHeader>
              <CardBody>
                <Droppable droppableId="playlists" isDropDisabled={true}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      /*isDraggingOver={snapshot.isDraggingOver}*/
                    >
                      {this.props.playlists.map((playlist, index) => (
                        <Draggable
                          key={playlist.id}
                          draggableId={playlist.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <React.Fragment>
                              <Item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                isDragging={snapshot.isDragging}
                                style={provided.draggableProps.style}
                              >
                                <Card
                                  style={{
                                    marginTop: "5px",
                                    marginBottom: "5px"
                                  }}
                                >
                                  <GridContainer alignItems="center">
                                    <GridItem xs={2}>
                                      <CardIcon
                                        color="success"
                                        style={{
                                          marginTop: "0px"
                                        }}
                                      >
                                        <PlaylistPlay fill="white" />
                                      </CardIcon>
                                    </GridItem>
                                    <GridItem xs={10}>
                                      <h6
                                        style={{
                                          textAlign: "center"
                                        }}
                                      >
                                        {playlist.name}
                                      </h6>
                                    </GridItem>
                                  </GridContainer>
                                </Card>
                              </Item>
                              {snapshot.isDragging && (
                                <Clone>
                                  <Card
                                    style={{
                                      marginTop: "5px",
                                      marginBottom: "5px"
                                    }}
                                  >
                                    <GridContainer alignItems="center">
                                      <GridItem xs={2}>
                                        <CardIcon
                                          color="success"
                                          style={{
                                            marginTop: "0px"
                                          }}
                                        >
                                          <PlaylistPlay fill="white" />
                                        </CardIcon>
                                      </GridItem>
                                      <GridItem xs={10}>
                                        <h6>{playlist.name}</h6>
                                      </GridItem>
                                    </GridContainer>
                                  </Card>
                                </Clone>
                              )}
                            </React.Fragment>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </DragDropContext>
    );
  }
}

export default withStyles(dashboardStyle)(withTranslation()(GroupPreview));
