import Pages from "layouts/Pages.jsx";
//import RTL from "layouts/RTL.jsx";
import Dashboard from "layouts/Dashboard.jsx";
import Root from "views/Root/root.jsx";

var indexRoutes = [
//  { path: "/rtl", name: "RTL", component: RTL },
{ path: "/pages", name: "Pages", component: Pages, protected: false },
{ path: "/dashboard", name: "Pages", component: Dashboard, protected: true },
{ path: "/", name: "Home", component: Root, protected: false }
//{ path: "/", name: "Home", component: Dashboard, protected: false }
];

export default indexRoutes;
