import React from "react";
import { Route, Redirect } from "react-router-dom";
import {feathersAuth} from "./feathers";

const PrivateRoute = ({ component: Component, mediaType, playlistType, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        feathersAuth.isAuthenticated ? (
        //false ? (
          <Component mediaType={mediaType} playlistType={playlistType} {...props} />
        ) : (
          <Redirect
            to={{
              //pathname: "/pages/login-page",
              pathname: "/",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );

export default PrivateRoute;