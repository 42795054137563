import React from "react";
import {
  Redirect,
} from 'react-router-dom';
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
//import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Email from "@material-ui/icons/EmailOutlined";
import Lock from "@material-ui/icons/LockOpenOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import {feathersClient, feathersAuth} from "helpers/feathers";

class LoginPage extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      email: "",
      password: "",
      redirectToReferrer: false
    };
  }
  componentDidMount() {
    this._isMounted = true;

    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        if (this._isMounted) this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );

    feathersClient.service('fake').on('created', fakeDate => {
      alert('Login ' + fakeDate.data);
    });

    feathersClient.service('fake').on('NotAuthenticated', error => {
      alert('Login ' + error.name);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;

    feathersClient.service('fake').removeListener('created');
    feathersClient.service('fake').removeListener('NotAuthenticated');

    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }

  handleChange = event => {
    if (this._isMounted) this.setState({
      [event.target.id]: event.target.value
    });
  }

  loginClick = async event => {
    event.preventDefault();
    //console.log('The login was submited');
    const credentials = {email: this.state.email, password: this.state.password};
    feathersAuth.authenticate(credentials).then( response => {
      if (feathersAuth.isAuthenticated === true) {
        if (this._isMounted) this.setState(() => ({
          redirectToReferrer: true
        }))
        //this.props.history.push("/dashboard");
      } else {
        alert("Malament!");
      }
    }).catch( e => {
      alert("Malament! " + e);
    });
  }

  wordpressClick = async event => {
    event.preventDefault();
    //console.log('The wordpress login was submited');
    this.props.history.push('/auth/wordpress');
  }

  fakeClick = event => {
    event.preventDefault();
    //console.log('The fake click was submited');
    feathersClient.service('fake').create({
      data: new Date()
    }).catch(
      function(error) {
        alert('Login Fake ' + error.name)
      });
  }

  render() {
    const { classes } = this.props;
    const { from } = this.props.location.state || { from: { pathname: '/dashboard' } }
    const { redirectToReferrer } = this.state

    if (redirectToReferrer === true) {
      return <Redirect to={from} />
    }

    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <form>
              <Card login className={classes[this.state.cardAnimaton]}>
                <CardHeader
                  className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="success"
                  >
                    <h4 className={classes.cardTitle}>Log in</h4>
                    {/*<div className={classes.socialLine}>
                      {[
                        "fab fa-facebook-square",
                        "fab fa-twitter",
                        "fab fa-google-plus"
                      ].map((prop, key) => {
                        return (
                          <Button
                            color="transparent"
                            justIcon
                            key={key}
                            className={classes.customButtonClass}
                            onClick={this.fakeClick}
                          >
                            <i className={prop} />
                          </Button>
                        );
                      })}
                    </div>*/}
                  </CardHeader>
                  <CardBody>
                    {/*<CustomInput
                      labelText="First Name.."
                      id="firstname"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Face className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        )
                      }}
                    />*/}
                    <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "email",
                        onChange: this.handleChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputAdornmentIcon} />
                          </InputAdornment>
                        )
                      }}
                    />
                    <CustomInput
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        type: "password",
                        onChange: this.handleChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Lock
                              className={classes.inputAdornmentIcon}
                            />
                          </InputAdornment>
                        )
                      }}
                    />
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                  <Button color="success" simple size="lg" block onClick={this.loginClick}>
                    Let's Go
                  </Button>
                  <Button color="success" simple size="lg" block onClick={this.wordpressClick}>
                    Wordpress
                  </Button>
                </CardFooter>
              </Card>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
