var config = null;
if (process.env.NODE_ENV === 'development') {
    config = require('../config/default.json');
} else {
    config = require('../config/production.json');
}

//var api = (config.api.host ? config.api.host : 'localhost') + ':' + (config.api.port ? config.api.port : '3030');

var api = (config.api.host ? config.api.host : 'localhost');
if (config.api.port && config.api.port !== '') {
    api = api + ':' + config.api.port;
}

var server = (config.server.host ? config.server.host : 'localhost');
if (config.server.port && config.server.port !== '') {
    server = server + ':' + config.server.port;
}

const apiURL = (config.api.https ? 'https://' : 'http://') + api;
const serverURL = (config.server.https ? 'https://' : 'http://') + server;
const web = (config.web.host ? config.web.host : 'https://cityadpro.com');

const subscriptionURL = (config.subscriptionURL ? config.subscriptionURL : 'https://cityadpro.com/producto/plan/');

const ga = (config.ga ? config.ga : { gaTrackingID: "UA-85436669-29" })

module.exports = {
    api,
    apiURL,
    server,
    serverURL,
    web,
    subscriptionURL,
    ga
};