const commonTypes = require('./commonTypes');

const item = {
  type: 'object',
  default: {},
  required: ['id', 'read', 'update', 'delete'],
  properties: {
    id: commonTypes.string,
    read: commonTypes.booleanFalse,
    update: commonTypes.booleanFalse,
    delete: commonTypes.booleanFalse,
  },
};

const itemsList = {
  type: 'array',
  default: [],
  items: item
};

const service = {
  type: 'object',
  default: {},
  required: ['name', 'read', 'update', 'delete', 'create'],
  properties: {
    name: commonTypes.string,
    read: commonTypes.booleanFalse,
    update: commonTypes.booleanFalse,
    delete: commonTypes.booleanFalse,
    create: commonTypes.booleanFalse,
    items: itemsList,
  },
};

const servicesList = {
  type: 'array',
  default: [],
  items: service
};

const clientProperties = {
  _id: commonTypes._id,
  name: commonTypes.string,
  clientID: commonTypes.string,
  clientSecret: commonTypes.string,
  services: servicesList,
  enabled: commonTypes.booleanTrue,
};

const clientCreateSchema = {
  required: ['name', 'clientID', 'clientSecret'],
  properties: clientProperties
};

const clientPatchSchema = {
  properties: clientProperties
};

const clientSchema = {
  properties: clientProperties
};

export {
  clientSchema,
  clientCreateSchema,
  clientPatchSchema
};