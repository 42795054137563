import React from "react";
import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputAdornment from "@material-ui/core/InputAdornment";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput";

// @material-ui/icons
import Lock from "@material-ui/icons/LockOpenOutlined";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class RestApiPasswordDialog extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      password1: "",
      password2: "",
      passwordsMatch: false
    };
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    }, () => {
      const passwordsMatch = (
        this.state.password1 === this.state.password2 &&
        this.state.password1 !== ""
      );
      this.setState({
        passwordsMatch: passwordsMatch
      })
    });
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div>
        <Dialog
          classes={{
            root: this.props.classes.center + " " + this.props.classes.modalRoot,
            paper: this.props.classes.modal
          }}
          TransitionComponent={Transition}
          keepMounted
          open={this.props.open}
          onClose={() => {
            // Tornem a posar el valor inicial per si l'hem modificat i no ho canviem
            if (this.props.onClose) this.props.onClose();
          }}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.cardIconTitle}>{ t('translation:rest_api_password') }</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} style={{ textAlign: "left" }}>
                <CustomInput
                  labelText={ t('translation:type_new_password') }
                  id="password1"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "password",
                    onChange: this.handleChange,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Lock
                          className={classes.inputAdornmentIcon}
                        />
                      </InputAdornment>
                    )
                  }}
                  error={!this.state.passwordsMatch}
                />
              </GridItem>
              <GridItem xs={12} style={{ textAlign: "left" }}>
                <CustomInput
                  labelText={ t('translation:retype_new_password') }
                  id="password2"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "password",
                    onChange: this.handleChange,
                    endAdornment: (
                      <InputAdornment position="end">
                        <Lock
                          className={classes.inputAdornmentIcon}
                        />
                      </InputAdornment>
                    )
                  }}
                  error={!this.state.passwordsMatch}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              color="success"
              simple
              onClick={() => {
                if (this.state.passwordsMatch) {
                  this.props.onAccept(this.state.password1);
                }
              }}
              className={classes.registerButton}
              xs={3}
            >
              { t('translation:apply') }
          </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(dashboardStyle)(withTranslation()(RestApiPasswordDialog));
