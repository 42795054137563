exports.string = {
    type: 'string',
    default: '',
    pattern: '^(.*)$'
};

exports.email = {
    type: 'string',
    format: 'email',
    default: ''
};

exports._id = {
    type: 'object',
    default: ''
};

exports.booleanTrue = {
    'type': 'boolean',
    'default': true,
};

exports.booleanFalse = {
    'type': 'boolean',
    'default': false,
};

exports.date = {
    type: 'string',
    format: 'date',
    default: null
};

exports.date_time = {
    format: 'date-time',
    default: null,
    examples: [
        '2018-11-01T00:00:00.000Z'
    ]
};

exports.time = {
    format: 'time',
    default: null
};

exports.positiveNumber = {
    type: 'integer',
    minimum: 0,
    default: 0,
};

exports.positiveFloat = {
    type: 'number',
    minimum: 0,
    default: 0,
};

exports.perOne = {
    type: 'number',
    minimum: 0,
    maximum: 1,
    default: 0,
};

exports.perOneOne = {
    type: 'number',
    minimum: 0,
    maximum: 1,
    default: 1,
};

exports.positivePixels = {
    type: 'integer',
    minimum: 1,
    default: 500,
};

exports.url = {
    type: 'string',
    format: 'uri',
    default: ''
};

exports.mediaType = {
    enum: ['image', 'video', 'webpage', 'videostream', 'audiostream', 'audio']
};

exports.playlistType = {
    enum: ['video', 'audio']
};

exports.roleType = {
    enum: ['customer', 'subscriber', 'administrator'],
    default: 'customer'
};

exports.roles = {
    type: 'array',
    default: ['customer'],
    items: this.roleType
};

exports.hdmiGroup = {
    enum: ['Auto', 'CEA', 'DMT'],
    default: 'Auto'
};

/*
exports.accessType = {
  enum: ['read', 'write']
};
*/

exports.allowedClientServices = {
    enum: [
        'categories',
        'groups',
        'group-schedules',
        'licenses',
        'media',
        'players',
        'playlists'
    ]
};