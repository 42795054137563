const layouts = [
    { language: 'Afghani', layout: 'af'},
    { language: 'Albanian', layout: 'al'},
    { language: 'Amharic', layout: 'et'},
    { language: 'Arabic', layout: 'ara'},
    { language: 'Arabic (Morocco)', layout: 'ma'},
    { language: 'Arabic (Syria)', layout: 'sy'},
    { language: 'Armenian', layout: 'am'},
    { language: 'Azerbaijani', layout: 'az'},
    { language: 'Bambara', layout: 'ml'},
    { language: 'Belarusian', layout: 'by'},
    { language: 'Belgian', layout: 'be'},
    { language: 'Berber (Algeria, Latin characters)', layout: 'dz'},
    { language: 'Bosnian', layout: 'ba'},
    { language: 'Braille', layout: 'brai'},
    { language: 'Bulgarian', layout: 'bg'},
    { language: 'Burmese', layout: 'mm'},
    { language: 'Chinese', layout: 'cn'},
    { language: 'Croatian', layout: 'hr'},
    { language: 'Czech', layout: 'cz'},
    { language: 'Danish', layout: 'dk'},
    { language: 'Dhivehi', layout: 'mv'},
    { language: 'Dutch', layout: 'nl'},
    { language: 'Dzongkha', layout: 'bt'},
    { language: 'English (Australian)', layout: 'au'},
    { language: 'English (Cameroon)', layout: 'cm'},
    { language: 'English (Ghana)', layout: 'gh'},
    { language: 'English (Nigeria)', layout: 'ng'},
    { language: 'English (South Africa)', layout: 'za'},
    { language: 'English (UK)', layout: 'gb'},
    { language: 'English (US)', layout: 'us'},
    { language: 'Esperanto', layout: 'epo'},
    { language: 'Estonian', layout: 'ee'},
    { language: 'Faroese', layout: 'fo'},
    { language: 'Filipino', layout: 'ph'},
    { language: 'Finnish', layout: 'fi'},
    { language: 'French', layout: 'fr'},
    { language: 'French (Canada)', layout: 'ca'},
    { language: 'French (Democratic Republic of the Congo)', layout: 'cd'},
    { language: 'French (Guinea)', layout: 'gn'},
    { language: 'French (Togo)', layout: 'tg'},
    { language: 'Georgian', layout: 'ge'},
    { language: 'German', layout: 'de'},
    { language: 'German (Austria)', layout: 'at'},
    { language: 'German (Switzerland)', layout: 'ch'},
    { language: 'Greek', layout: 'gr'},
    { language: 'Hebrew', layout: 'il'},
    { language: 'Hungarian', layout: 'hu'},
    { language: 'Icelandic', layout: 'is'},
    { language: 'Indian', layout: 'in'},
    { language: 'Indonesian (Jawi)', layout: 'id'},
    { language: 'Iraqi', layout: 'iq'},
    { language: 'Irish', layout: 'ie'},
    { language: 'Italian', layout: 'it'},
    { language: 'Japanese', layout: 'jp'},
    { language: 'Japanese (PC-98xx Series)', layout: 'nec_vndr/jp'},
    { language: 'Kazakh', layout: 'kz'},
    { language: 'Khmer (Cambodia)', layout: 'kh'},
    { language: 'Korean', layout: 'kr'},
    { language: 'Kyrgyz', layout: 'kg'},
    { language: 'Lao', layout: 'la'},
    { language: 'Latvian', layout: 'lv'},
    { language: 'Lithuanian', layout: 'lt'},
    { language: 'Macedonian', layout: 'mk'},
    { language: 'Malay (Jawi)', layout: 'bn'},
    { language: 'Maltese', layout: 'mt'},
    { language: 'Maori', layout: 'mao'},
    { language: 'Moldavian', layout: 'md'},
    { language: 'Mongolian', layout: 'mn'},
    { language: 'Montenegrin', layout: 'me'},
    { language: 'Nepali', layout: 'np'},
    { language: 'Norwegian', layout: 'no'},
    { language: 'Persian', layout: 'ir'},
    { language: 'Polish', layout: 'pl'},
    { language: 'Portuguese', layout: 'pt'},
    { language: 'Portuguese (Brazil)', layout: 'br'},
    { language: 'Romanian', layout: 'ro'},
    { language: 'Russian', layout: 'ru'},
    { language: 'Serbian', layout: 'rs'},
    { language: 'Sinhala (phonetic)', layout: 'lk'},
    { language: 'Slovak', layout: 'sk'},
    { language: 'Slovenian', layout: 'si'},
    { language: 'Spanish', layout: 'es'},
    { language: 'Spanish (Latin American)', layout: 'latam'},
    { language: 'Swahili (Kenya)', layout: 'ke'},
    { language: 'Swahili (Tanzania)', layout: 'tz'},
    { language: 'Swedish', layout: 'se'},
    { language: 'Taiwanese', layout: 'tw'},
    { language: 'Tajik', layout: 'tj'},
    { language: 'Thai', layout: 'th'},
    { language: 'Tswana', layout: 'bw'},
    { language: 'Turkish', layout: 'tr'},
    { language: 'Turkmen', layout: 'tm'},
    { language: 'Ukrainian', layout: 'ua'},
    { language: 'Urdu (Pakistan)', layout: 'pk'},
    { language: 'Uzbek', layout: 'uz'},
    { language: 'Vietnamese', layout: 'vn'},
    { language: 'Wolof', layout: 'sn'},
];

export {
    layouts
}