import React from "react";
import PhotoLibrary from "@material-ui/icons/PhotoLibraryOutlined";
import VideoLibrary from "@material-ui/icons/VideoLibraryOutlined";
import Web from "@material-ui/icons/WebOutlined";
import Html from "@material-ui/icons/CodeOutlined";
import { ReactComponent as FileMusic } from "../assets/img/icons/file-music-outline.svg"
import { ReactComponent as RadioTower } from "../assets/img/icons/radio-tower.svg"
import { ReactComponent as Youtube } from "../assets/img/icons/youtube.svg"

import i18n from "../i18n.js";

import acceptedFileTypes from '../variables/fileTypes';

i18n.on('languageChanged', function (lng) {
  updateLabels();
})

i18n.on('loaded', function (loaded) {
  updateLabels();
})

function updateLabels() {
  images.label = i18n.t('images');
  videos.label = i18n.t('videos');
  audios.label = i18n.t('audios');
  webpages.label = i18n.t('web_pages');
  html.label = i18n.t('html');
  videostreams.label = i18n.t('video_streams');
  audiostreams.label = i18n.t('audio_streams');
}

var images = {
  type: "image",
  label: 'Images',
  icon: <PhotoLibrary />,
  acceptedFileTypes: acceptedFileTypes.imageTypes
};

var videos = {
  type: "video",
  label: "Videos",
  icon: <VideoLibrary />,
  acceptedFileTypes: acceptedFileTypes.videoTypes
};

var audios = {
  type: "audio",
  label: "Music",
  icon: <FileMusic fill="white" />,
  acceptedFileTypes: acceptedFileTypes.audioTypes
};

var webpages = {
  type: "webpage",
  label: "Webpages",
  icon: <Web />,
  acceptedFileTypes: []
};

var html = {
  type: "html",
  label: "HTML",
  icon: <Html />,
  acceptedFileTypes: acceptedFileTypes.htmlTypes
};

var videostreams = {
  type: "videostream",
  label: "Video Streams",
  icon: <Youtube fill="white" />,
  acceptedFileTypes: []
};

var audiostreams = {
  type: "audiostream",
  label: "Music Streams",
  icon: <RadioTower fill="white" />,
  acceptedFileTypes: []
};

var videoType = {
  images,
  videos,
  webpages,
  videostreams,
  html
};

var audioType = {
  audios,
  audiostreams
};

var mediaType = {
  ...videoType,
  ...audioType
  /*images,
  videos,
  audios,
  webpages,
  videostreams,
  audiostreams*/
};

//const videoTypeArray = ["image", "video", "webpage", "videostream"];
const videoTypeArray = [];
Object.values(videoType).forEach(element => {
  videoTypeArray.push(element.type);
});
//const audioTypeArray = ["audio", "audiostream"];
const audioTypeArray = [];
Object.values(audioType).forEach(element => {
  audioTypeArray.push(element.type);
});
//const mediaTypeArray = Object.values(mediaType);
const mediaTypeArray = videoTypeArray.concat(audioTypeArray);

export { videoType, audioType, mediaType, videoTypeArray, audioTypeArray, mediaTypeArray };
