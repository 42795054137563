module.exports =
    [
        // AUTO MODE
        {
            "group": "Auto",
            "mode": 0,
            "resolution": "",
            "frequency": "",
            "aspectRatio": "",
            "description": "Auto detect"
        },
        // Most common modes
        {
            "group": "CEA",
            "mode": 1,
            "resolution": "VGA (640x480)",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 2,
            "resolution": "480p",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 4,
            "resolution": "720p",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 16,
            "resolution": "1080p",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 95,
            "resolution": "2160p",
            "frequency": "30Hz",
            "aspectRatio": "16:9",
            "description": "**"
        },
        // The rest of modes
        {
            "group": "CEA",
            "mode": 3,
            "resolution": "480p",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 5,
            "resolution": "1080i",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 6,
            "resolution": "480i",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 7,
            "resolution": "480i",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 8,
            "resolution": "240p",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 9,
            "resolution": "240p",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 10,
            "resolution": "480i",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": "pixel quadrupling"
        },
        {
            "group": "CEA",
            "mode": 11,
            "resolution": "480i",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": "pixel quadrupling"
        },
        {
            "group": "CEA",
            "mode": 12,
            "resolution": "240p",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": "pixel quadrupling"
        },
        {
            "group": "CEA",
            "mode": 13,
            "resolution": "240p",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": "pixel quadrupling"
        },
        {
            "group": "CEA",
            "mode": 14,
            "resolution": "480p",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": "pixel doubling"
        },
        {
            "group": "CEA",
            "mode": 15,
            "resolution": "480p",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": "pixel doubling"
        },
        {
            "group": "CEA",
            "mode": 17,
            "resolution": "576p",
            "frequency": "50Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 18,
            "resolution": "576p",
            "frequency": "50Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 19,
            "resolution": "720p",
            "frequency": "50Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 20,
            "resolution": "1080i",
            "frequency": "50Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 21,
            "resolution": "576i",
            "frequency": "50Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 22,
            "resolution": "576i",
            "frequency": "50Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 23,
            "resolution": "288p",
            "frequency": "50Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 24,
            "resolution": "288p",
            "frequency": "50Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 25,
            "resolution": "576i",
            "frequency": "50Hz",
            "aspectRatio": "4:3",
            "description": "pixel quadrupling"
        },
        {
            "group": "CEA",
            "mode": 26,
            "resolution": "576i",
            "frequency": "50Hz",
            "aspectRatio": "16:9",
            "description": "pixel quadrupling"
        },
        {
            "group": "CEA",
            "mode": 27,
            "resolution": "288p",
            "frequency": "50Hz",
            "aspectRatio": "4:3",
            "description": "pixel quadrupling"
        },
        {
            "group": "CEA",
            "mode": 28,
            "resolution": "288p",
            "frequency": "50Hz",
            "aspectRatio": "16:9",
            "description": "pixel quadrupling"
        },
        {
            "group": "CEA",
            "mode": 29,
            "resolution": "576p",
            "frequency": "50Hz",
            "aspectRatio": "4:3",
            "description": "pixel doubling"
        },
        {
            "group": "CEA",
            "mode": 30,
            "resolution": "576p",
            "frequency": "50Hz",
            "aspectRatio": "16:9",
            "description": "pixel doubling"
        },
        {
            "group": "CEA",
            "mode": 31,
            "resolution": "1080p",
            "frequency": "50Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 32,
            "resolution": "1080p",
            "frequency": "24Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 33,
            "resolution": "1080p",
            "frequency": "25Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 34,
            "resolution": "1080p",
            "frequency": "30Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 35,
            "resolution": "480p",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": "pixel quadrupling"
        },
        {
            "group": "CEA",
            "mode": 36,
            "resolution": "480p",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": "pixel quadrupling"
        },
        {
            "group": "CEA",
            "mode": 37,
            "resolution": "576p",
            "frequency": "50Hz",
            "aspectRatio": "4:3",
            "description": "pixel quadrupling"
        },
        {
            "group": "CEA",
            "mode": 38,
            "resolution": "576p",
            "frequency": "50Hz",
            "aspectRatio": "16:9",
            "description": "pixel quadrupling"
        },
        {
            "group": "CEA",
            "mode": 39,
            "resolution": "1080i",
            "frequency": "50Hz",
            "aspectRatio": "16:9",
            "description": "reduced blanking"
        },
        {
            "group": "CEA",
            "mode": 40,
            "resolution": "1080i",
            "frequency": "100Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 41,
            "resolution": "720p",
            "frequency": "100Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 42,
            "resolution": "576p",
            "frequency": "100Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 43,
            "resolution": "576p",
            "frequency": "100Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 44,
            "resolution": "576i",
            "frequency": "100Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 45,
            "resolution": "576i",
            "frequency": "100Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 46,
            "resolution": "1080i",
            "frequency": "120Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 47,
            "resolution": "720p",
            "frequency": "120Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 48,
            "resolution": "480p",
            "frequency": "120Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 49,
            "resolution": "480p",
            "frequency": "120Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 50,
            "resolution": "480i",
            "frequency": "120Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 51,
            "resolution": "480i",
            "frequency": "120Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 52,
            "resolution": "576p",
            "frequency": "200Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 53,
            "resolution": "576p",
            "frequency": "200Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 54,
            "resolution": "576i",
            "frequency": "200Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 55,
            "resolution": "576i",
            "frequency": "200Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 56,
            "resolution": "480p",
            "frequency": "240Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 57,
            "resolution": "480p",
            "frequency": "240Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 58,
            "resolution": "480i",
            "frequency": "240Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 59,
            "resolution": "480i",
            "frequency": "240Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 60,
            "resolution": "720p",
            "frequency": "24Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 61,
            "resolution": "720p",
            "frequency": "25Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 62,
            "resolution": "720p",
            "frequency": "30Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 63,
            "resolution": "1080p",
            "frequency": "120Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 64,
            "resolution": "1080p",
            "frequency": "100Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "CEA",
            "mode": 66,
            "resolution": "720p",
            "frequency": "25Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 67,
            "resolution": "720p",
            "frequency": "30Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 68,
            "resolution": "720p",
            "frequency": "50Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 69,
            "resolution": "720p",
            "frequency": "60Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 70,
            "resolution": "720p",
            "frequency": "100Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 71,
            "resolution": "720p",
            "frequency": "120Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 72,
            "resolution": "1080p",
            "frequency": "24Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 73,
            "resolution": "1080p",
            "frequency": "25Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 74,
            "resolution": "1080p",
            "frequency": "30Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 75,
            "resolution": "1080p",
            "frequency": "50Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 76,
            "resolution": "1080p",
            "frequency": "60Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 77,
            "resolution": "1080p",
            "frequency": "100Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 78,
            "resolution": "1080p",
            "frequency": "120Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 79,
            "resolution": "1680x720",
            "frequency": "24Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 80,
            "resolution": "1680x720",
            "frequency": "25z",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 81,
            "resolution": "1680x720",
            "frequency": "30Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 82,
            "resolution": "1680x720",
            "frequency": "50Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 83,
            "resolution": "1680x720",
            "frequency": "60Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 84,
            "resolution": "1680x720",
            "frequency": "100Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 85,
            "resolution": "1680x720",
            "frequency": "120Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 86,
            "resolution": "2560x720",
            "frequency": "24Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 87,
            "resolution": "2560x720",
            "frequency": "25Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 88,
            "resolution": "2560x720",
            "frequency": "30Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 89,
            "resolution": "2560x720",
            "frequency": "50Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 90,
            "resolution": "2560x720",
            "frequency": "60Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 91,
            "resolution": "2560x720",
            "frequency": "100Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 92,
            "resolution": "2560x720",
            "frequency": "120Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 93,
            "resolution": "2160p",
            "frequency": "24Hz",
            "aspectRatio": "16:9",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 94,
            "resolution": "2160p",
            "frequency": "25Hz",
            "aspectRatio": "16:9",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 96,
            "resolution": "2160p",
            "frequency": "50Hz",
            "aspectRatio": "16:9",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 97,
            "resolution": "2160p",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 98,
            "resolution": "4096x2160",
            "frequency": "24Hz",
            "aspectRatio": "256:135",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 99,
            "resolution": "4096x2160",
            "frequency": "25Hz",
            "aspectRatio": "256:135",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 100,
            "resolution": "4096x2160",
            "frequency": "30Hz",
            "aspectRatio": "256:135",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 101,
            "resolution": "4096x2160",
            "frequency": "50Hz",
            "aspectRatio": "256:135",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 102,
            "resolution": "4096x2160",
            "frequency": "60Hz",
            "aspectRatio": "256:135",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 103,
            "resolution": "2160p",
            "frequency": "24Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 104,
            "resolution": "2160p",
            "frequency": "25Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 105,
            "resolution": "2160p",
            "frequency": "30Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 106,
            "resolution": "2160p",
            "frequency": "50Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "CEA",
            "mode": 107,
            "resolution": "2160p",
            "frequency": "60Hz",
            "aspectRatio": "64:27",
            "description": "**"
        },
        {
            "group": "DMT",
            "mode": 1,
            "resolution": "640x350",
            "frequency": "85Hz",
            "aspectRatio": "",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 2,
            "resolution": "640x400",
            "frequency": "85Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 3,
            "resolution": "720x400",
            "frequency": "85Hz",
            "aspectRatio": "",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 4,
            "resolution": "640x480",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 5,
            "resolution": "640x480",
            "frequency": "72Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 6,
            "resolution": "640x480",
            "frequency": "75Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 7,
            "resolution": "640x480",
            "frequency": "85Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 8,
            "resolution": "800x600",
            "frequency": "56Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 9,
            "resolution": "800x600",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 10,
            "resolution": "800x600",
            "frequency": "72Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 11,
            "resolution": "800x600",
            "frequency": "75Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 12,
            "resolution": "800x600",
            "frequency": "85Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 13,
            "resolution": "800x600",
            "frequency": "120Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 14,
            "resolution": "848x480",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 16,
            "resolution": "1024x768",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 17,
            "resolution": "1024x768",
            "frequency": "70Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 18,
            "resolution": "1024x768",
            "frequency": "75Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 19,
            "resolution": "1024x768",
            "frequency": "85Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 20,
            "resolution": "1024x768",
            "frequency": "120Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 21,
            "resolution": "1152x864",
            "frequency": "75Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 22,
            "resolution": "1280x768",
            "frequency": "60Hz",
            "aspectRatio": "15:9",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 23,
            "resolution": "1280x768",
            "frequency": "60Hz",
            "aspectRatio": "15:9",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 24,
            "resolution": "1280x768",
            "frequency": "75Hz",
            "aspectRatio": "15:9",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 25,
            "resolution": "1280x768",
            "frequency": "85Hz",
            "aspectRatio": "15:9",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 26,
            "resolution": "1280x768",
            "frequency": "120Hz",
            "aspectRatio": "15:9",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 27,
            "resolution": "1280x800",
            "frequency": "60 Hz",
            "aspectRatio": "16:10",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 28,
            "resolution": "1280x800",
            "frequency": "60Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 29,
            "resolution": "1280x800",
            "frequency": "75Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 30,
            "resolution": "1280x800",
            "frequency": "85Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 31,
            "resolution": "1280x800",
            "frequency": "120Hz",
            "aspectRatio": "16:10",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 32,
            "resolution": "1280x960",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 33,
            "resolution": "1280x960",
            "frequency": "85Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 34,
            "resolution": "1280x960",
            "frequency": "120Hz",
            "aspectRatio": "4:3",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 35,
            "resolution": "1280x1024",
            "frequency": "60Hz",
            "aspectRatio": "5:4",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 36,
            "resolution": "1280x1024",
            "frequency": "75Hz",
            "aspectRatio": "5:4",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 37,
            "resolution": "1280x1024",
            "frequency": "85Hz",
            "aspectRatio": "5:4",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 38,
            "resolution": "1280x1024",
            "frequency": "120Hz",
            "aspectRatio": "5:4",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 39,
            "resolution": "1360x768",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 40,
            "resolution": "1360x768",
            "frequency": "120Hz",
            "aspectRatio": "16:9",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 41,
            "resolution": "1400x1050",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 42,
            "resolution": "1400x1050",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 43,
            "resolution": "1400x1050",
            "frequency": "75Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 44,
            "resolution": "1400x1050",
            "frequency": "85Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 45,
            "resolution": "1400x1050",
            "frequency": "120Hz",
            "aspectRatio": "4:3",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 46,
            "resolution": "1440x900",
            "frequency": "60Hz",
            "aspectRatio": "16:10",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 47,
            "resolution": "1440x900",
            "frequency": "60Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 48,
            "resolution": "1440x900",
            "frequency": "75Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 49,
            "resolution": "1440x900",
            "frequency": "85Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 50,
            "resolution": "1440x900",
            "frequency": "120Hz",
            "aspectRatio": "16:10",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 51,
            "resolution": "1600x1200",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 52,
            "resolution": "1600x1200",
            "frequency": "65Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 53,
            "resolution": "1600x1200",
            "frequency": "70Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 54,
            "resolution": "1600x1200",
            "frequency": "75Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 55,
            "resolution": "1600x1200",
            "frequency": "85Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 56,
            "resolution": "1600x1200",
            "frequency": "120Hz",
            "aspectRatio": "4:3",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 57,
            "resolution": "1680x1050",
            "frequency": "60Hz",
            "aspectRatio": "16:10",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 58,
            "resolution": "1680x1050",
            "frequency": "60Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 59,
            "resolution": "1680x1050",
            "frequency": "75Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 60,
            "resolution": "1680x1050",
            "frequency": "85Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 61,
            "resolution": "1680x1050",
            "frequency": "120Hz",
            "aspectRatio": "16:10",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 62,
            "resolution": "1792x1344",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 63,
            "resolution": "1792x1344",
            "frequency": "75Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 64,
            "resolution": "1792x1344",
            "frequency": "120Hz",
            "aspectRatio": "4:3",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 65,
            "resolution": "1856x1392",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 66,
            "resolution": "1856x1392",
            "frequency": "75Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 67,
            "resolution": "1856x1392",
            "frequency": "120Hz",
            "aspectRatio": "4:3",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 68,
            "resolution": "1920x1200",
            "frequency": "60Hz",
            "aspectRatio": "16:10",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 69,
            "resolution": "1920x1200",
            "frequency": "60Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 70,
            "resolution": "1920x1200",
            "frequency": "75Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 71,
            "resolution": "1920x1200",
            "frequency": "85Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 72,
            "resolution": "1920x1200",
            "frequency": "120Hz",
            "aspectRatio": "16:10",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 73,
            "resolution": "1920x1440",
            "frequency": "60Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 74,
            "resolution": "1920x1440",
            "frequency": "75Hz",
            "aspectRatio": "4:3",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 75,
            "resolution": "1920x1440",
            "frequency": "120Hz",
            "aspectRatio": "4:3",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 76,
            "resolution": "2560x1600",
            "frequency": "60Hz",
            "aspectRatio": "16:10",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 77,
            "resolution": "2560x1600",
            "frequency": "60Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 78,
            "resolution": "2560x1600",
            "frequency": "75Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 79,
            "resolution": "2560x1600",
            "frequency": "85Hz",
            "aspectRatio": "16:10",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 80,
            "resolution": "2560x1600",
            "frequency": "120Hz",
            "aspectRatio": "16:10",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 81,
            "resolution": "1366x768",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 82,
            "resolution": "1920x1080",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 83,
            "resolution": "1600x900",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 84,
            "resolution": "2048x1152",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": "reduced blanking"
        },
        {
            "group": "DMT",
            "mode": 85,
            "resolution": "1280x720",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": ""
        },
        {
            "group": "DMT",
            "mode": 86,
            "resolution": "1366x768",
            "frequency": "60Hz",
            "aspectRatio": "16:9",
            "description": "reduced blanking"
        }
    ];