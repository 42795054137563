//FIXME: Quan afegim una llista a les llistes de reproducció la planificació (schedule) agafa la de l'últim que hem posat
//TODO: Que no es pugui posar una data anterior al ValidityFrom en el validityUntil i viceversa

import React, { Component } from "react";
import { Prompt } from "react-router";
import uuid from "uuid/v4";
import Datetime from "react-datetime";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import _ from "lodash";

import { withTranslation, Trans } from 'react-i18next';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import NavPills from "components/NavPills/NavPills2.jsx";

// @material-ui/icons
import Extension from "@material-ui/icons/ExtensionOutlined";
import Sync from "@material-ui/icons/SyncOutlined";
import Save from "@material-ui/icons/SaveOutlined";
import Restore from "@material-ui/icons/RestoreOutlined";
import Check from "@material-ui/icons/CheckOutlined";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Snackbars from "components/Snackbar/Snackbar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InputLabel from "@material-ui/core/InputLabel";

// Feathers
import { feathersClient } from "../../helpers/feathers";
import { validateGroup } from "../../schemas/validators";
import config from "../../helpers/config";
import ga from "../../helpers/google-analytics";

import GroupPreview from "./GroupPreview";
import SchedulesPreview from "./SchedulesPreview";
import ScreenPreview from "./ScreenPreview";

// Styles
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const CALENDAR_MONTH = 6;
const CALENDAR_YEAR = 2018;

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
/**
 * Moves an item from one list to another list.
 */
const copy = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = _.cloneDeep(sourceClone[droppableSource.index]);

  destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() });
  return destClone;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const weekDayName = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

const briefWeekDayName = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];

class Group extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    //var categories= null;
    this.state = {
      alert: null,
      isGroupSchedulesAllowed: false,
      isSectionsAllowed: false,
      id: 0,
      availableVideoPlaylists: [], //La llista de playlists de l'usuari
      availableAudioPlaylists: [], //La llista de playlists de l'usuari

      videoSections: null, //La llista de playlists del grup
      audioSection: {
        playlists: [],
        options: {
          mix: false,
          combine: false
        }
      }, //La llista de playlists del grup

      group: {
        video: []
      },
      groupChanges: false,

      syncGroup: false,
      groupSchedules: [],
      scheduleOptionsDialog: false,
      scheduleOptions: {
        id: "",
        validity: {
          from: "",
          until: ""
        },
        time: {
          from: null,
          until: null
        },
        weekDays: [],
        monthDays: []
      },
      groupScheduleOptionsDialog: false,
      groupScheduleOptions: {
        id: null,
        group: null,
        time: null,
        weekDays: [],
        monthDays: []
      },
      notification: {
        visible: false,
        text: "",
        color: "success"
      },
      selectedSection: 0,
      sectionsNotAllowedDialog: false
    };
  }

  onDragEndVideo = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (this._isMounted)
      this.setState({
        groupChanges: true
      });
    switch (source.droppableId) {
      case destination.droppableId:
        if (this._isMounted) {
          var videoSections = [...this.state.videoSections];
          videoSections[this.state.selectedSection].playlists = reorder(
            videoSections[this.state.selectedSection].playlists,
            source.index,
            destination.index
          )
          this.setState({
            videoSections: videoSections
          });
        }
        break;
      case "playlists":
        if (this._isMounted) {
          var videoSections = [...this.state.videoSections];
          videoSections[this.state.selectedSection].playlists = copy(
            this.state.availableVideoPlaylists,
            videoSections[this.state.selectedSection].playlists,
            source,
            destination
          )
          this.setState({
            videoSections: videoSections
          });
        }
        break;
      default:
        /*
        if (this._isMounted) {
          this.setState(
            move(
              this.state.availableVideoPlaylists,
              this.state.videoPlaylists,
              source,
              destination
            )
          );
        }*/
        break;
    }
  };

  onDragEndAudio = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (this._isMounted)
      this.setState({
        groupChanges: true
      });
    switch (source.droppableId) {
      case destination.droppableId:
        if (this._isMounted) {
          var audioSection = { ...this.state.audioSection };
          audioSection.playlists = reorder(
            audioSection.playlists,
            source.index,
            destination.index
          )
          this.setState({
            audioSection: audioSection
          });
        }
        break;
      case "playlists":
        if (this._isMounted) {
          var audioSection = { ...this.state.audioSection };
          audioSection.playlists = copy(
            this.state.availableAudioPlaylists,
            audioSection.playlists,
            source,
            destination
          )
          this.setState({
            audioSection: audioSection
          });
        }
        break;
      default:
        /*
        if (this._isMounted)
          this.setState(
            move(
              this.state.availableAudioPlaylists,
              this.state.audioPlaylists,
              source,
              destination
            )
          );
          */
        break;
    }
  };

  playlistSetDescription(playlist) {
    playlist.descriptions = [];
    var description = "";
    if (playlist.time.from.getTime() === playlist.time.until.getTime()) {
      playlist.descriptions.push(this.props.t('translation:play_always'));
    } else {
      description = this.props.t('translation:play') + " ";
      if (playlist.time.from != null) {
        description +=
          this.props.t('translation:from') + " " +
          this.addZero(playlist.time.from.getHours()) +
          ":" +
          this.addZero(playlist.time.from.getMinutes()) +
          " ";
      }
      if (playlist.time.until != null) {
        description +=
          this.props.t('translation:until') + " " +
          this.addZero(playlist.time.until.getHours()) +
          ":" +
          this.addZero(playlist.time.until.getMinutes()) +
          " ";
      }
      playlist.descriptions.push(description);
    }
    playlist.descriptions.push(this.getWeekDaysDescription(playlist.weekDays));
    playlist.descriptions.push(this.getMonthDaysDescription(playlist.monthDays));
    if (playlist.validity.from === null && playlist.validity.until === null) {
      //playlist.descriptions.push("Valid always");
    } else {
      description = this.props.t('translation:valid') + " ";
      if (playlist.validity.from != null) {
        description +=
          this.props.t('translation:from') + " " + playlist.validity.from.toLocaleDateString() + " ";
      }
      if (playlist.validity.until != null) {
        description +=
          this.props.t('translation:until') + " " + playlist.validity.until.toLocaleDateString() + " ";
      }
      playlist.descriptions.push(description);
    }
  }

  getWeekDaysDescription(weekDays) {
    var description = null;
    var temp = [];
    weekDays.forEach((weekday, index) => {
      if (weekday) {
        temp.push(briefWeekDayName[index]);
      }
    });
    if (temp.length === 7) {
      //Tots els dies
      //playlist.descriptions.push("Every weekday ");
    } else {
      description = this.props.t('translation:weekdays') + " ";
      for (let i = 0; i < temp.length; i++) {
        description += temp[i];
        if (i < temp.length - 1) {
          description += ", ";
        }
      }
    }

    return description;
  }

  getMonthDaysDescription(monthDays) {
    var description = null;
    if (monthDays.length !== 31) {
      description = this.props.t('translation:monthdays') + " ";
      for (let i = 0; i < monthDays.length; i++) {
        description += monthDays[i].getDate();
        if (i < monthDays.length - 1) {
          description += ", ";
        }
      }
    }

    return description;
  }

  playlistToUI(playlist, name) {
    var tempPlaylist = {};
    tempPlaylist._id = playlist._id;
    tempPlaylist.name = name;
    tempPlaylist.id = uuid(); //Li hem de posar un id únic sinó el dnd no funciona
    tempPlaylist.validity = {
      from: null,
      until: null
    };
    if (playlist.validity) {
      if (playlist.validity.from != null) {
        try {
          tempPlaylist.validity.from = new Date(playlist.validity.from);
        } catch (e) {
          tempPlaylist.validity.from = null;
        }
      } else {
        tempPlaylist.validity.from = null;
      }
      if (playlist.validity.until != null) {
        try {
          tempPlaylist.validity.until = new Date(playlist.validity.until);
        } catch (e) {
          tempPlaylist.validity.until = null;
        }
      } else {
        tempPlaylist.validity.until = null;
      }
    }

    const defaultTime = "00:00:00";
    tempPlaylist.time = {
      from: this.stringToDate(defaultTime),
      until: this.stringToDate(defaultTime)
    };
    if (playlist.time) {
      try {
        tempPlaylist.time.from = this.stringToDate(playlist.time.from);
      } catch (e) { } //No fem res pq ja tenim l'hora posada correctament abans del try-catch
      try {
        tempPlaylist.time.until = this.stringToDate(playlist.time.until);
      } catch (e) { } //No fem res pq ja tenim l'hora posada correctament abans del try-catch
    }
    if (playlist.weekDays) {
      tempPlaylist.weekDays = playlist.weekDays;
    } else {
      tempPlaylist.weekDays = [];
      for (let i = 0; i < 7; i++) {
        tempPlaylist.weekDays.push(true);
      }
    }
    if (playlist.monthDays) {
      tempPlaylist.monthDays = [];
      playlist.monthDays.forEach((monthDay, index) => {
        if (monthDay) {
          tempPlaylist.monthDays.push(
            new Date(CALENDAR_YEAR, CALENDAR_MONTH, index + 1)
          );
        }
      });
    } else {
      tempPlaylist.monthDays = [];
      for (let i = 0; i < 31; i++) {
        tempPlaylist.monthDays.push(
          new Date(CALENDAR_YEAR, CALENDAR_MONTH, i + 1)
        );
      }
    }
    this.playlistSetDescription(tempPlaylist);

    return tempPlaylist;
  }

  updateGroupUI(group) {
    const syncGroup = group.updatedAt !== group.synchedAt;
    var videoSections = [];
    //if (!group.options) group.options = {}; //FIXME: Això serveix per algo????
    group.video.forEach(section => {
      var newSection = {
        playlists: [],
        options: { ...section.options },
        size: { ...section.size }
      }
      section.playlists.forEach(playlist => {
        var index = this.state.availableVideoPlaylists.findIndex(
          x => x._id === playlist._id
        );
        if (index > -1) {
          newSection.playlists.push(
            this.playlistToUI(
              playlist,
              this.state.availableVideoPlaylists[index].name
            )
          );
        }
      });
      videoSections.push(newSection);
    });
    var audioSection = {
      playlists: [],
      options: { ...group.audio.options }
    };
    group.audio.playlists.forEach(playlist => {
      var index = this.state.availableAudioPlaylists.findIndex(
        x => x._id === playlist._id
      );
      if (index > -1) {
        audioSection.playlists.push(
          this.playlistToUI(
            playlist,
            this.state.availableAudioPlaylists[index].name
          )
        );
      }
    });
    if (this._isMounted) {
      this.setState({
        videoSections: null
      }, () => {
        this.setState({
          syncGroup: syncGroup,
          group: group,
          videoSections: videoSections,
          audioSection: audioSection,
          //combineVideo: group.video[this.state.selectedSection].options.combine,
          //mixVideo: group.video[this.state.selectedSection].options.mix,
          //muteVideo: group.video[0].options.mute,
          //combineAudio: group.audio.options.combine,
          //mixAudio: group.audio.options.mix,
          groupChanges: false,
          selectedSection: 0
        })
      });
    }
  }

  groupScheduleSetDescription(groupSchedule) {
    groupSchedule.descriptions = [];
    var description = this.props.t('translation:synchronize_at') + " ";
    if (groupSchedule.time != null) {
      description +=
        this.addZero(groupSchedule.time.getHours()) +
        ":" +
        this.addZero(groupSchedule.time.getMinutes()) +
        " ";
    }
    groupSchedule.descriptions.push(description);
    description = this.getWeekDaysDescription(groupSchedule.weekDays);
    if (description) groupSchedule.descriptions.push(description);
    description = this.getMonthDaysDescription(groupSchedule.monthDays);
    if (description) groupSchedule.descriptions.push(description);
    if (groupSchedule.descriptions.length === 1) {
      groupSchedule.descriptions[0] = groupSchedule.descriptions[0] + " " + this.props.t('translation:everyday');
    }
  }

  updateGroupSchedulesUI(groupSchedules) {
    var tempGroupSchedules = [];
    groupSchedules.forEach(groupSchedule => {
      tempGroupSchedules.push(this.groupScheduleToUI(groupSchedule));
    });
    if (this._isMounted)
      this.setState({
        groupSchedules: tempGroupSchedules,
      });
  }

  async loadGroup() {
    const {
      match: { params }
    } = this.props;

    try {
      var group = await feathersClient.service("groups").get(params.groupId);
      //console.log(group);
      validateGroup(group);
      var subscriptions = await feathersClient.service("subscriptions").find();
      var groupSchedules = [];
      if (subscriptions.data.length > 0 && subscriptions.data[0].type !== "basic") {
        this.setState({
          isGroupSchedulesAllowed: true,
          isSectionsAllowed: true,
        })
        var result = await feathersClient.service("group-schedules").find({
          query: {
            group: params.groupId
          }
        });
        groupSchedules = result.data;
      }
      //console.log(result);
      this.updateGroupUI(group);
      this.updateGroupSchedulesUI(groupSchedules);
    } catch (error) {
      this.showNotification(this.props.t('translation:error_fetching_group') + ": " + error.name, "warning");
      if (this._isMounted)
        this.setState({
          group: [],
          groupSchedules: []
        });
    }
    if (this._isMounted)
      this.setState({
        groupChanges: false
      });
  }

  addZero(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  getTime(datetime) {
    var tempTime = "00:00:00";
    try {
      tempTime = moment(datetime).format("HH:mm:ss");
      /*tempTime =
        this.addZero(datetime.getHours()) +
        ":" +
        this.addZero(datetime.getMinutes()) +
        ":00";*/
    } catch (e) {
    }
    return tempTime;
  }

  getTimeUTC(datetime) {
    var tempTime = moment(datetime).format("HH:mm:ssZ");
    return tempTime;
  }

  playlistFromUI(playlist) {
    var tempPlaylist = {
      _id: playlist._id,
      validity: playlist.validity,
      time: {
        from: this.getTime(playlist.time.from),
        until: this.getTime(playlist.time.until)
      },
      weekDays: playlist.weekDays,
      monthDays: []
    };
    for (var i = 0; i < 31; i++) {
      tempPlaylist.monthDays.push(false);
    }
    playlist.monthDays.forEach(monthDay => {
      tempPlaylist.monthDays[monthDay.getDate() - 1] = true;
    });
    return tempPlaylist;
  }

  updateListChanges() {
    var group = { ...this.state.group };
    group.video = [];
    this.state.videoSections.forEach(section => {
      var newSection = {
        playlists: [],
        options: { ...section.options },
        size: { ...section.size }
      }
      section.playlists.forEach(playlist => {
        newSection.playlists.push(this.playlistFromUI(playlist));
      });
      group.video.push(newSection);
    });

    group.audio = {
      playlists: [],
      options: { ...this.state.audioSection.options }
    }
    this.state.audioSection.playlists.forEach(playlist => {
      group.audio.playlists.push(this.playlistFromUI(playlist));
    });
    /*
    group.video[0].options.combine = this.state.combineVideo;
    group.video[0].options.mix = this.state.mixVideo;
    group.video[0].options.mute = this.state.muteVideo;
    group.audio.options.combine = this.state.combineAudio;
    group.audio.options.mix = this.state.mixAudio;
    */
    feathersClient
      .service("groups")
      .patch(group._id, group)
      .then(group => {
        validateGroup(group);
        this.updateGroupUI(group);
        this.showNotification(this.props.t('translation:group_changes_applied_successfully'), "success");
      })
      .catch(error => {
        this.showNotification(
          this.props.t('translation:error_applying_group_changes') + ": " + error.name,
          "warning"
        );
      });
  }

  syncGroup() {
    var group = this.state.group;
    feathersClient
      .service("groups")
      .patch(group._id, { synchronize: true })
      .then(group => {
        validateGroup(group);
        this.updateGroupUI(group);
        this.showNotification(this.props.t('translation:group_successfully_synchronized'), "success");
      })
      .catch(error => {
        this.showNotification(this.props.t('translation:error_synching_group') + ": " + error.name, "warning");
      });
  }

  showScheduleOptionsDialog(show) {
    if (this._isMounted)
      this.setState({
        scheduleOptionsDialog: show
      });
  }

  showSectionsNotAllowedDialog(show) {
    if (this._isMounted)
      this.setState({
        sectionsNotAllowedDialog: show
      });
  }

  stringToDate(stringDate) {
    var temp = stringDate.split(":");
    var tempDate = new Date();
    tempDate.setHours(temp[0]);
    tempDate.setMinutes(temp[1]);
    tempDate.setSeconds(0);
    return tempDate;
  }

  changeScheduleOptions(playlist) {
    /*var scheduleOptions = this.state.scheduleOptions;
    scheduleOptions.id = playlist.id;*/
    if (this._isMounted)
      this.setState({
        scheduleOptions: playlist
      });
    this.showScheduleOptionsDialog(true);
  }

  roundAccurately = (number, decimalPlaces) =>
    Number(Math.round(number + 'e' + decimalPlaces) - 'e-' + decimalPlaces)

  handleResizeSection = (index, size) => {
    var videoSections = [...this.state.videoSections];
    videoSections[index].size.x = size.x > 0 ? size.x : 0;
    videoSections[index].size.y = size.y > 0 ? size.y : 0;
    videoSections[index].size.width = size.width > 0 ? size.width : 0;
    videoSections[index].size.height = size.height > 0 ? size.height : 0;
    this.setState({
      videoSections: videoSections,
      groupChanges: true
    })
  }

  handleDragSection = (index, position) => {
    var videoSections = [...this.state.videoSections];
    videoSections[index].size.x = position.x > 0 ? position.x : 0;
    videoSections[index].size.y = position.y > 0 ? position.y : 0;
    this.setState({
      videoSections: videoSections,
      groupChanges: true
    })
  }

  handleSelectedSectionChange = (index) => {
    this.setState({
      selectedSection: index
    })
  }

  addScreenSection = () => {
    if (this.state.isSectionsAllowed) {
      var videoSections = [...this.state.videoSections];
      var section = {
        playlists: [],
        options: {
          combine: false,
          mix: false,
          mute: false
        },
        size: {
          x: 0.25,
          y: 0.25,
          width: 0.5,
          height: 0.5
        }
      }
      videoSections.push(section);
      this.setState({
        videoSections: videoSections,
        groupChanges: true,
      }, () => {
        this.setState({
          selectedSection: videoSections.length - 1
        })
      })
    } else {
      this.showSectionsNotAllowedDialog(true);
    }
  }

  removeScreenSection = () => {
    var videoSections = [...this.state.videoSections];
    if (videoSections.length > 1) {
      videoSections.splice(this.state.selectedSection, 1);
      this.setState({
        videoSections: null, //Ho fem així per que si no el component Rnd no actualitza correctament les mides de les seccions, per tant primer les eliminem i després les tornem a afegir
        selectedSection: 0,
        groupChanges: true,
      }, () => {
        this.setState({
          videoSections: videoSections
        })
      })
    }
  }

  handleVideoChange = () => event => {
    if (this._isMounted) {
      var videoSections = [...this.state.videoSections];
      videoSections[this.state.selectedSection].options[event.target.value] = event.target.checked;
      this.setState({
        videoSections: videoSections,
        groupChanges: true
      });
    }
  };

  handleAudioChange = () => event => {
    if (this._isMounted) {
      var audioSection = { ...this.state.audioSection };
      audioSection.options[event.target.value] = event.target.checked;
      this.setState({
        audioSection: audioSection,
        groupChanges: true
      });
    }
  };

  handleScheduleValidityChange = name => event => {
    var temp = this.state.scheduleOptions;
    //en principi l'event hauria de portar la data (dins un momentjs), però per si de cas, fem un try catch i si falla llavors posem la data a null
    try {
      temp.validity[name] = event.toDate();
    } catch (e) {
      temp.validity[name] = null;
    }
    if (this._isMounted)
      this.setState({
        scheduleOptions: temp
      });
  };

  handleScheduleTimeChange = name => event => {
    var temp = this.state.scheduleOptions;
    //en principi l'event hauria de portar la data (dins un momentjs), però per si de cas, fem un try catch i si falla llavors posem la data a null
    try {
      temp.time[name] = event.toDate();
    } catch (e) {
      temp.time[name] = "";
    }
    if (this._isMounted)
      this.setState({
        scheduleOptions: temp
      });
  };

  handleToggleWeekDay(index) {
    const temp = this.state.scheduleOptions;
    temp.weekDays[index] = !temp.weekDays[index];
    if (this._isMounted)
      this.setState({
        scheduleOptions: temp
      });
  }

  applyScheduleOptionsClick(options) {
    this.showScheduleOptionsDialog(false);
    var videoSections = [...this.state.videoSections];
    var playlists = videoSections[this.state.selectedSection].playlists;
    var index = playlists.findIndex(x => x.id === options.id);
    if (index > -1) {
      playlists[index] = options;
      this.playlistSetDescription(playlists[index]);
      if (this._isMounted)
        this.setState({
          videoSections: videoSections,
          groupChanges: true
        });
    } else {
      var audioSection = { ...this.state.audioSection };
      playlists = audioSection.playlists;
      index = playlists.findIndex(x => x.id === options.id);
      if (index > -1) {
        playlists[index] = options;
        this.playlistSetDescription(playlists[index]);
        if (this._isMounted)
          this.setState({
            audioSection: audioSection,
            groupChanges: true
          });
      }
    }
  }

  changeGroupScheduleOptions(groupSchedule) {
    if (this._isMounted)
      this.setState({
        groupScheduleOptions: groupSchedule
      });
    this.showGroupScheduleOptions(true);
  }

  addGroupSchedule() {
    if (this._isMounted)
      var temp = {
        _id: null,
        group: this.state.group._id,
        time: null,
        weekDays: [],
        monthDays: []
      }
    const defaultTime = "00:00:00";
    temp.time = this.stringToDate(defaultTime);
    for (let i = 0; i < 7; i++) {
      temp.weekDays.push(true);
    }
    for (let i = 0; i < 31; i++) {
      temp.monthDays.push(
        new Date(CALENDAR_YEAR, CALENDAR_MONTH, i + 1)
      );
    }
    this.setState({
      groupScheduleOptions: temp
    });
    this.showGroupScheduleOptions(true);
  }

  showGroupScheduleOptions(show) {
    if (this._isMounted)
      this.setState({
        groupScheduleOptionsDialog: show
      });
  }

  handleGroupScheduleTimeChange = event => {
    var temp = this.state.groupScheduleOptions;
    //en principi l'event hauria de portar la data (dins un momentjs), però per si de cas, fem un try catch i si falla llavors posem la data a null
    try {
      temp.time = event.toDate();
    } catch (e) {
      temp.time = null;
    }
    if (this._isMounted)
      this.setState({
        groupScheduleOptions: temp
      });
  };

  handleToggleGroupScheduleWeekDay(index) {
    const temp = this.state.groupScheduleOptions;
    temp.weekDays[index] = !temp.weekDays[index];
    if (this._isMounted)
      this.setState({
        groupScheduleOptions: temp
      });
  }

  handleGroupScheduleMonthDayClick(day, { selected }) {
    const monthDays = this.state.groupScheduleOptions.monthDays;
    if (selected) {
      const selectedIndex = monthDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      monthDays.splice(selectedIndex, 1);
    } else {
      monthDays.push(day);
    }
    if (this._isMounted)
      this.setState({
        "groupScheduleOptions.monthDays": monthDays
      });
  }

  applyGroupScheduleOptionsClick(groupSchedule) {
    this.showGroupScheduleOptions(false);

    var tempGroupSchedule = this.groupScheduleFromUI(groupSchedule);
    if (groupSchedule._id) {
      feathersClient.service('group-schedules')
        .patch(groupSchedule._id, tempGroupSchedule)
        .then(result => {
          //console.log(result);
          var tempGroupSchedules = this.state.groupSchedules;
          var index = tempGroupSchedules.findIndex(x => x.id === groupSchedule.id);
          if (index > -1) {
            tempGroupSchedules[index] = this.groupScheduleToUI(result);
            if (this._isMounted)
              this.setState({
                groupSchedules: tempGroupSchedules
              });
          }
          this.showNotification(this.props.t('translation:group_schedule_created_correctly'), "success");
        }).catch(error => {
          this.showNotification(this.props.t('translation:error_updating_group_schedule') + ": " + error.name, "warning");
        })
    } else {
      feathersClient.service('group-schedules')
        .create(tempGroupSchedule)
        .then(result => {
          //console.log(result);
          var tempGroupSchedules = this.state.groupSchedules;
          tempGroupSchedules.push(this.groupScheduleToUI(result));
          this.setState({
            groupSchedules: tempGroupSchedules
          });
          this.showNotification(this.props.t('translation:group_schedule_updated_correctly'), "success");
        }).catch(error => {
          this.showNotification(this.props.t('translation:error_creating_group_schedule') + ": " + error.name, "warning");
        })
    }
  }

  groupScheduleToUI(groupSchedule) {
    var tempGroupSchedule = {};
    tempGroupSchedule._id = groupSchedule._id;
    tempGroupSchedule.id = uuid(); //Li hem de posar un id únic sinó el dnd no funciona
    const defaultTime = "00:00:00";
    tempGroupSchedule.time = this.stringToDate(defaultTime);
    if (groupSchedule.time) {
      try {
        tempGroupSchedule.time = this.stringToDate(groupSchedule.time);
      } catch (e) { } //No fem res pq ja tenim l'hora posada correctament abans del try-catch
    }
    if (groupSchedule.weekDays) {
      tempGroupSchedule.weekDays = groupSchedule.weekDays;
    } else {
      tempGroupSchedule.weekDays = [];
      for (let i = 0; i < 7; i++) {
        tempGroupSchedule.weekDays.push(true);
      }
    }
    if (groupSchedule.monthDays) {
      tempGroupSchedule.monthDays = [];
      groupSchedule.monthDays.forEach((monthDay, index) => {
        if (monthDay) {
          tempGroupSchedule.monthDays.push(
            new Date(CALENDAR_YEAR, CALENDAR_MONTH, index + 1)
          );
        }
      });
    } else {
      tempGroupSchedule.monthDays = [];
      for (let i = 0; i < 31; i++) {
        tempGroupSchedule.monthDays.push(
          new Date(CALENDAR_YEAR, CALENDAR_MONTH, i + 1)
        );
      }
    }
    this.groupScheduleSetDescription(tempGroupSchedule);

    return tempGroupSchedule;
  }

  groupScheduleFromUI(groupSchedule) {
    var tempGroupSchedule = {
      _id: groupSchedule._id,
      group: groupSchedule.group,
      time: moment(groupSchedule.time).format("HH:mm:ssZ"),
      weekDays: groupSchedule.weekDays,
      monthDays: []
    };
    for (var i = 0; i < 31; i++) {
      tempGroupSchedule.monthDays.push(false);
    }
    groupSchedule.monthDays.forEach(monthDay => {
      tempGroupSchedule.monthDays[monthDay.getDate() - 1] = true;
    });

    return tempGroupSchedule;
  }

  removeGroupSchedule(groupSchedule) {
    this.hideAlert();
    feathersClient.service('group-schedules')
      .remove(groupSchedule._id)
      .then(result => {
        var temp = this.state.groupSchedules;
        var index = temp.findIndex(x => x._id === result._id);
        if (index > -1) {
          temp.splice(index, 1);
          if (this._isMounted)
            this.setState({
              groupSchedules: temp
            });
        }
        this.showNotification(this.props.t('translation:group_schedule_deleted_correctly'), "success");
      }).catch(error => {
        this.showNotification(this.props.t('translation:error_deleting_group_schedule') + ": " + error.name, "warning");
      });
  }

  deleteGroupScheduleWarning(groupSchedule) {
    if (this._isMounted)
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title={this.props.t('translation:are_you_sure')}
            onConfirm={() => this.removeGroupSchedule(groupSchedule)}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={this.props.t('translation:yes_delete_it')}
            cancelBtnText={this.props.t('translation:cancel')}
            showCancel
          >
            {this.props.t('translation:you_will_not_be_able_to_recover_this_schedule')}
          </SweetAlert>
        )
      });
  }

  componentDidMount() {
    this._isMounted = true;
    ga.pageview(window.location.pathname);
    feathersClient
      .service("playlists")
      .find({ query: { $sort: { name: 1 } } })
      .then(playlists => {
        var vtemp = [];
        var atemp = [];
        playlists.data.forEach(playlist => {
          if (playlist.listType === "video") {
            vtemp.push(this.playlistToUI(playlist, playlist.name));
          } else {
            atemp.push(this.playlistToUI(playlist, playlist.name));
          }
        });
        //temp.forEach(playlist => (playlist.id = uuid())); //Li posem un id únic, sinó el dnd no funciona
        if (this._isMounted)
          this.setState(
            {
              availableVideoPlaylists: vtemp,
              availableAudioPlaylists: atemp
            },
            () => {
              this.loadGroup();
            }
          );
      })
      .catch(error => {
        this.showNotification(
          this.props.t('translation:error_fetching_playlists') + ": " + error.name,
          "warning"
        );
      })
      .catch(error => {
        this.showNotification(this.props.t('translation:error_fetching_group') + ": " + error.name, "warning");
        if (this._isMounted)
          this.setState({
            group: []
          });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  hideAlert() {
    if (this._isMounted)
      this.setState({
        alert: null
      });
  }

  showNotification(message, color) {
    if (!this.state.notification["visible"]) {
      var x = this.state.notification;
      x["visible"] = true;
      x["text"] = message;
      x["color"] = color;
      if (this._isMounted) this.setState(x);
      // use this to make the notification autoclose
      setTimeout(
        function () {
          x["visible"] = false;
          if (this._isMounted) this.setState(x);
        }.bind(this),
        3000
      );
    }
  }

  removeVideoPlaylist(playlist) {
    var videoSections = [...this.state.videoSections];
    var playlists = videoSections[this.state.selectedSection].playlists;
    var index = playlists.findIndex(x => x.id === playlist.id);
    if (index > -1) {
      playlists.splice(index, 1);
      if (this._isMounted)
        this.setState({
          videoSections: videoSections,
          groupChanges: true
        });
    }
  }

  removeAudioPlaylist(playlist) {
    var audioSection = { ...this.state.audioSection };
    var playlists = audioSection.playlists;
    var index = playlists.findIndex(x => x.id === playlist.id);
    if (index > -1) {
      playlists.splice(index, 1);
      if (this._isMounted)
        this.setState({
          audioSection: audioSection,
          groupChanges: true
        });
    }
  }

  handleMonthDayClick(day, { selected }) {
    const monthDays = this.state.scheduleOptions.monthDays;
    if (selected) {
      const selectedIndex = monthDays.findIndex(selectedDay =>
        DateUtils.isSameDay(selectedDay, day)
      );
      monthDays.splice(selectedIndex, 1);
    } else {
      monthDays.push(day);
    }
    if (this._isMounted)
      this.setState({
        "scheduleOptions.monthDays": monthDays
      });
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div>
        {this.state.alert}
        <Prompt
          when={this.state.groupChanges}
          message={t('translation:are_you_sure_you_want_to_loose_your_changes')}
        />
        <Snackbars
          place="tc"
          color={this.state.notification.color}
          //icon={AddAlert}
          message={this.state.notification.text}
          open={this.state.notification.visible}
          closeNotification={() => {
            if (this._isMounted)
              this.setState({ "notification.visible": false });
          }}
        />
        <Dialog
          classes={{
            root:
              this.props.classes.center + " " + this.props.classes.modalRoot,
            paper: this.props.classes.modal
          }}
          fullWidth
          TransitionComponent={Transition}
          keepMounted
          open={this.state.scheduleOptionsDialog}
          onClose={() => this.showScheduleOptionsDialog(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>{t('translation:playlist_schedule')}</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <InputLabel className={classes.label}> {t('translation:time_from')} </InputLabel>
                <Datetime
                  dateFormat={false}
                  timeFormat={"HH:mm"}
                  closeOnSelect
                  inputProps={{ placeholder: t('translation:time_from') }}
                  onChange={this.handleScheduleTimeChange("from")}
                  value={this.state.scheduleOptions.time.from}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputLabel className={classes.label}> {t('translation:time_until')} </InputLabel>
                <Datetime
                  dateFormat={false}
                  timeFormat={"HH:mm"}
                  closeOnSelect
                  inputProps={{ placeholder: t('translation:time_until') }}
                  onChange={this.handleScheduleTimeChange("until")}
                  value={this.state.scheduleOptions.time.until}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputLabel className={classes.label}>{t('translation:valid_from')}</InputLabel>
                <Datetime
                  timeFormat={false}
                  closeOnSelect
                  inputProps={{ placeholder: t('translation:valid_from') }}
                  onChange={this.handleScheduleValidityChange("from")}
                  value={this.state.scheduleOptions.validity.from}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputLabel className={classes.label}>{t('translation:valid_until')}</InputLabel>
                <Datetime
                  timeFormat={false}
                  closeOnSelect
                  inputProps={{ placeholder: t('translation:valid_until') }}
                  onChange={this.handleScheduleValidityChange("until")}
                  value={this.state.scheduleOptions.validity.until}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <InputLabel
                  className={classes.label}
                  style={{ marginTop: "14px", marginBottom: "14px" }}
                >
                  {t('translation:weekdays')}
                </InputLabel>
                {this.state.scheduleOptions.weekDays.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      }
                      style={{ textAlign: "left", marginLeft: "60px" }}
                    >
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={value}
                            tabIndex={index}
                            onClick={() => this.handleToggleWeekDay(index)}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={weekDayName[index]}
                      />
                    </div>
                  );
                })}
              </GridItem>
              <GridItem xs={12} sm={6}>
                <GridContainer>
                  <GridItem xs={12}>
                    <InputLabel
                      className={classes.label}
                      style={{ marginTop: "14px", marginBottom: "14px" }}
                    >
                      {t('translation:monthdays')}
                    </InputLabel>
                  </GridItem>
                  <GridItem xs={12}>
                    <DayPicker
                      month={new Date(CALENDAR_YEAR, CALENDAR_MONTH)}
                      selectedDays={this.state.scheduleOptions.monthDays}
                      onDayClick={this.handleMonthDayClick.bind(this)}
                      showWeekDays={false}
                      canChangeMonth={false}
                      captionElement={({ date, localeUtils }) => ""}
                      modifiersStyles={{
                        selected: {
                          backgroundColor: '#53C04E'
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              simple
              color="success"
              onClick={() =>
                this.applyScheduleOptionsClick(this.state.scheduleOptions)
              }
              className={classes.registerButton}
              xs={3}
            >
              {t('translation:apply')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          classes={{
            root:
              this.props.classes.center + " " + this.props.classes.modalRoot,
            paper: this.props.classes.modal
          }}
          fullWidth
          TransitionComponent={Transition}
          keepMounted
          open={this.state.groupScheduleOptionsDialog}
          onClose={() => this.showGroupScheduleOptions(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>{t('translation:group_schedule')}</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12} sm={2}>
                <InputLabel className={classes.label}> {t('translation:synchronize_time')} </InputLabel>
                <Datetime
                  dateFormat={false}
                  timeFormat={"HH:mm"}
                  closeOnSelect
                  inputProps={{ placeholder: t('translation:synchronize_time') }}
                  onChange={this.handleGroupScheduleTimeChange}
                  value={this.state.groupScheduleOptions.time}
                />
              </GridItem>
              <GridItem xs={12} sm={4}>
                <InputLabel
                  className={classes.label}
                  style={{ marginTop: "14px", marginBottom: "14px" }}
                >
                  {t('translation:weekdays')}
                </InputLabel>
                {this.state.groupScheduleOptions.weekDays.map((value, index) => {
                  return (
                    <div
                      key={index}
                      className={
                        classes.checkboxAndRadio +
                        " " +
                        classes.checkboxAndRadioHorizontal
                      }
                      style={{ textAlign: "left", marginLeft: "60px" }}
                    >
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={value}
                            tabIndex={index}
                            onClick={() => this.handleToggleGroupScheduleWeekDay(index)}
                            checkedIcon={
                              <Check className={classes.checkedIcon} />
                            }
                            icon={<Check className={classes.uncheckedIcon} />}
                            classes={{
                              checked: classes.checked,
                              root: classes.checkRoot
                            }}
                          />
                        }
                        classes={{
                          label: classes.label
                        }}
                        label={weekDayName[index]}
                      />
                    </div>
                  );
                })}
              </GridItem>
              <GridItem xs={12} sm={6}>
                <GridContainer>
                  <GridItem xs={12}>
                    <InputLabel
                      className={classes.label}
                      style={{ marginTop: "14px", marginBottom: "14px" }}
                    >
                      {t('translation:monthdays')}
                    </InputLabel>
                  </GridItem>
                  <GridItem xs={12}>
                    <DayPicker
                      month={new Date(CALENDAR_YEAR, CALENDAR_MONTH)}
                      selectedDays={this.state.groupScheduleOptions.monthDays}
                      onDayClick={this.handleGroupScheduleMonthDayClick.bind(this)}
                      showWeekDays={false}
                      canChangeMonth={false}
                      captionElement={({ date, localeUtils }) => ""}
                      modifiersStyles={{
                        selected: {
                          backgroundColor: '#53C04E'
                        }
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              simple
              color="success"
              onClick={() =>
                this.applyGroupScheduleOptionsClick(this.state.groupScheduleOptions)
              }
              className={classes.registerButton}
              xs={3}
            >
              {this.state.groupScheduleOptions._id ? t('translation:apply') : t('translation:add')}
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          classes={{
            root:
              this.props.classes.center + " " + this.props.classes.modalRoot,
            paper: this.props.classes.modal
          }}
          fullWidth
          TransitionComponent={Transition}
          keepMounted
          open={this.state.sectionsNotAllowedDialog}
          onClose={() => this.showSectionsNotAllowedDialog(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>{t('translation:functionality_not_allowed')}</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <h4 style={{ textAlign: "center" }}>
              <Trans i18nKey="functionality_upgrade_your_plan">
                Please
                <a href={config.subscriptionURL} target="_blank" rel="noopener noreferrer">
                  upgrade
                </a>
                your plan to be able to use this functionality.
              </Trans>
            </h4>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              simple
              color="success"
              onClick={() =>
                this.showSectionsNotAllowedDialog(false)
              }
              className={classes.registerButton}
              xs={3}
            >
              {t('translation:accept')}
            </Button>
          </DialogActions>
        </Dialog>
        <Card>
          <CardHeader color="success" icon>
            <GridContainer>
              <GridItem xs={4}>
                <GridContainer>
                  <GridItem>
                    <CardIcon color="success">
                      <Extension />
                    </CardIcon>
                  </GridItem>
                  <GridItem
                    xs={3}
                    style={{ maxWidth: "100%", flexBasis: "auto" }}
                  >
                    <h4 className={classes.cardIconTitle}>
                      {this.state.group ? this.state.group.name : ""}
                    </h4>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs>
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Button
                      color="success"
                      simple
                      className={classes.marginRight}
                      disabled={!this.state.groupChanges}
                      onClick={this.loadGroup.bind(this)}
                      style={{ maxWidth: "140px" }}
                    >
                      <Restore className={classes.icons} /> {t('translation:undo_changes')}
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      color="success"
                      simple
                      className={classes.marginRight}
                      disabled={!this.state.groupChanges}
                      onClick={this.updateListChanges.bind(this)}
                      style={{ maxWidth: "140px" }}
                    >
                      <Save className={classes.icons} /> {t('translation:save_changes')}
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      color="success"
                      simple
                      className={classes.marginRight}
                      //disabled={!this.state.syncGroup}
                      onClick={this.syncGroup.bind(this)}
                      style={{ maxWidth: "140px" }}
                    >
                      <Sync className={classes.icons} /> {t('translation:synchronize')}
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <NavPills
              color="success"
              tabs={[
                {
                  tabButton: t('translation:video_playlists'),
                  tabContent: (
                    this.state.videoSections &&
                    <div>
                      <ScreenPreview
                        title={t('translation:screen_sections')}
                        list={this.state.videoSections}
                        onResize={this.handleResizeSection.bind(this)}
                        onDrag={this.handleDragSection.bind(this)}
                        onSelectedChange={this.handleSelectedSectionChange.bind(this)}
                        selectedSection={this.state.selectedSection}
                        addSection={this.addScreenSection.bind(this)}
                        removeSection={this.removeScreenSection.bind(this)}
                      //playlists={this.state.availableVideoPlaylists}
                      />
                      <GroupPreview
                        listsTitle={t('translation:video_playlists') + ' - ' + t('translation:section') + ' ' + this.state.selectedSection}
                        availablelistsTitle={t('translation:available_video_playlists')}
                        onDragEnd={this.onDragEndVideo.bind(this)}
                        changeScheduleOptions={this.changeScheduleOptions.bind(
                          this
                        )}
                        //combine={this.state.combineVideo}
                        handleCombineChange={this.handleVideoChange.bind(
                          this
                        )}
                        //mix={this.state.mixVideo}
                        handleMixChange={this.handleVideoChange.bind(this)}
                        //mute={this.state.muteVideo}
                        handleMuteChange={this.handleVideoChange.bind(this)}
                        removePlaylist={this.removeVideoPlaylist.bind(this)}
                        section={this.state.videoSections[this.state.selectedSection]}
                        playlists={this.state.availableVideoPlaylists}
                      //selectedSection={this.state.selectedSection}
                      />
                    </div>
                  )
                },
                {
                  tabButton: t('translation:audio_playlists'),
                  tabContent: (
                    <GroupPreview
                      listsTitle={t('translation:audio_playlists')}
                      availablelistsTitle={t('translation:available_audio_playlists')}
                      onDragEnd={this.onDragEndAudio.bind(this)}
                      changeScheduleOptions={this.changeScheduleOptions.bind(
                        this
                      )}
                      //combine={this.state.combineAudio}
                      handleCombineChange={this.handleAudioChange.bind(
                        this
                      )}
                      //mix={this.state.mixAudio}
                      handleMixChange={this.handleAudioChange.bind(this)}
                      removePlaylist={this.removeAudioPlaylist.bind(this)}
                      section={this.state.audioSection}
                      playlists={this.state.availableAudioPlaylists}
                    //selectedSection={0}
                    />
                  )
                },
                {
                  tabButton: t('translation:synchronization'),
                  tabContent: (
                    <div>
                      {this.state.isGroupSchedulesAllowed === false &&
                        <Card style={{ marginTop: "15px", marginBottom: "15px" }}>
                          <CardBody>
                            <h4 style={{ textAlign: "center" }}>
                              {t('translation:functionality_not_allowed')}
                            </h4>
                            <h4 style={{ textAlign: "center" }}>
                              <Trans i18nKey="functionality_upgrade_your_plan">
                                Please
                                <a href={config.subscriptionURL} target="_blank" rel="noopener noreferrer">
                                  upgrade
                                </a>
                                your plan to be able to use this functionality.
                              </Trans>
                            </h4>
                          </CardBody>
                        </Card>
                        //<h1>This Media is not allowed</h1>
                      }
                      {this.state.isGroupSchedulesAllowed === true &&
                        <SchedulesPreview
                          listsTitle={t('translation:synchronization')}
                          addGroupSchedule={this.addGroupSchedule.bind(this)}
                          changeGroupScheduleOptions={this.changeGroupScheduleOptions.bind(this)}
                          removeGroupSchedule={this.deleteGroupScheduleWarning.bind(this)}
                          list={this.state.groupSchedules}
                        />
                      }
                    </div>
                  )
                }
              ]}
            />
          </CardBody>
        </Card >
      </div >
    );
  }
}

export default withStyles(dashboardStyle)(withTranslation()(Group));
