const commonTypes = require('./commonTypes');

const videoOptions = {
    type: 'object',
    required: ['combine', 'mix', 'mute'],
    default: {},
    properties: {
        combine: commonTypes.booleanFalse,
        mix: commonTypes.booleanFalse,
        mute: commonTypes.booleanFalse
    }
};

const audioOptions = {
    type: 'object',
    required: ['combine', 'mix'],
    default: {},
    properties: {
        combine: commonTypes.booleanFalse,
        mix: commonTypes.booleanFalse
    }
};

const validity = {
    type: 'object',
    default: {},
    required: ['from', 'until'],
    properties: {
        from: commonTypes.date_time,
        until: commonTypes.date_time
    }
};

const weekDays = {
    type: 'array',
    default: [],
    items: commonTypes.booleanTrue
};

const monthDays = {
    type: 'array',
    default: [],
    items: commonTypes.booleanTrue
};

const time = {
    type: 'object',
    default: {},
    required: ['from', 'until'],
    properties: {
        from: commonTypes.time,
        until: commonTypes.time
    }
};

const playlists = {
    type: 'array',
    default: [],
    items: {
        type: 'object',
        default: {},
        required: ['_id', 'validity', 'weekDays', 'monthDays', 'time'],
        properties: {
            _id: commonTypes.string,
            validity,
            weekDays,
            monthDays,
            time
        }
    }
};

const size = {
    type: 'object',
    default: {
        x: 0,
        y: 0,
        width: 1,
        height: 1
    },
    required: ['x', 'y', 'width', 'height'],
    properties: {
        x: commonTypes.perOne,
        y: commonTypes.perOne,
        width: commonTypes.perOneOne,
        height: commonTypes.perOneOne
    }
};

const video = {
    type: 'array',
    minItems: 1,
    default: [{
        playlists: [],
        size: {},
        options: {}
    }],
    items: {
        type: 'object',
        default: {},
        required: ['playlists', 'options', 'size'],
        properties: {
            playlists: playlists,
            size: size,
            options: videoOptions
        }
    }
};

const audio = {
    type: 'object',
    default: {
        playlists: [],
        options: {}
    },
    required: ['playlists', 'options', 'size'],
    properties: {
        playlists: playlists,
        options: audioOptions
    }
};

const groupProperties = {
    _id: commonTypes.string,
    name: commonTypes.string,
    video: video,
    audio: audio,
    updatedAt: commonTypes.date_time,
    synchedAt: commonTypes.date_time
};

const groupSchema = {
    required: ['_id', 'name', 'video', 'audio', 'updatedAt', 'synchedAt'],
    properties: groupProperties
};

const groupCreateSchema = {
    required: ['name', 'options', 'video', 'audio'],
    properties: groupProperties
};

const groupPatchSchema = {
    properties: {
        ...groupProperties,
        synchronize: commonTypes.booleanFalse
    }
};

const groupAfterSchema = {
    required: ['_id', 'name', 'options', 'video', 'audio'],
    properties: {
        _id: commonTypes._id,
        name: commonTypes.string,
        video: video,
        audio: playlists
    }
};

export {
    groupSchema,
    groupCreateSchema,
    groupPatchSchema,
    groupAfterSchema
};