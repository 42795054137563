import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { withTranslation } from 'react-i18next';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Storage from "@material-ui/icons/StorageOutlined";
import Cast from "@material-ui/icons/CastOutlined";
import Description from "@material-ui/icons/DescriptionOutlined";
//import Warning from "@material-ui/icons/WarningOutlined";
//import DateRange from "@material-ui/icons/DateRangeOutlined";
//import LocalOffer from "@material-ui/icons/LocalOfferOutlined";
import PlaylistPlay from "@material-ui/icons/PlaylistPlayOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
//import Danger from "components/Typography/Danger.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import Snackbars from "components/Snackbar/Snackbar.jsx";

import { feathersClient, feathersAuth } from "../../helpers/feathers";
import defaults from "../../helpers/defaults";
import config from "../../helpers/config";
import ga from "../../helpers/google-analytics";

import moment from "moment";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

class Dashboard extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      players: [],
      online: 0,
      onlineColor: "danger",
      licensed: 0,
      licensedColor: "danger",
      playing: 0,
      playingColor: "danger",
      baseStorage: 0,
      storage: 0,
      storageColor: "danger",
      notification: {
        visible: false,
        text: "",
        color: "success"
      }
    };
    this.timer = null;
    this.updatePlayers = this.updatePlayers.bind(this);
  }

  async updatePlayers() {
    clearTimeout(this.timer);
    try {
      var players = await feathersClient.service("players").find();
      var licenses = await feathersClient.service("licenses").find({
        query: {
          status: "active"
        }
      });
      var online = 0;
      var onlineColor = "danger";
      var playing = 0;
      var playingColor = "danger";
      var licensed = 0;
      var licensedColor = "danger";
      const now = moment();
      const previous = moment().subtract(defaults.dashboardTimeout, "seconds");
      players.data.forEach(player => {
        var ol = false;
        if (player.state && player.state.updatedAt) {
          if (previous.isBefore(player.state.updatedAt, moment.ISO_8601)) {
            online++;
            ol = true;
          }
        }
        if (player.state && player.state.playing && ol) {
          playing++;
        }
        if (player.license) {
          var licenses_array = licenses.data.filter(
            license => license._id === player.license
          );
          if (
            licenses_array.length > 0 &&
            now.isBefore(licenses_array[0].validUntil, moment.ISO_8601)
          ) {
            licensed++;
          }
        }
      });
      //Colors
      if (online === players.data.length) {
        onlineColor = "success";
      } else if (online === 0) {
        onlineColor = "danger";
      } else {
        onlineColor = "warning";
      }
      if (playing === players.data.length) {
        playingColor = "success";
      } else if (playing === 0) {
        playingColor = "danger";
      } else {
        playingColor = "warning";
      }
      if (licensed === players.data.length) {
        licensedColor = "success";
      } else if (licensed === 0) {
        licensedColor = "danger";
      } else {
        licensedColor = "warning";
      }
      if (this._isMounted)
        this.setState({
          players: players.data,
          online: online,
          onlineColor: onlineColor,
          playing: playing,
          playingColor: playingColor,
          licensed: licensed,
          licensedColor: licensedColor
        });
      this.timer = setTimeout(
        this.updatePlayers,
        defaults.dashboardTimeout * 1000
      );
    } catch (error) {
      this.timer = setTimeout(
        this.updatePlayers,
        defaults.dashboardErrorTimeout * 1000
      );
      //console.log(error);
    }
  }

  async updateUser(user) {
    if (user != null) {
      var availableStorage = user.baseStorage;
      const now = moment();
      try {
        var storageUpgrades = await feathersClient.service("storage").find();
        storageUpgrades.data.forEach((element, index, array) => {
          if (
            element.status === "active" &&
            now.isSameOrBefore(element.validUntil)
          ) {
            availableStorage += element.storage;
          }
        });
      } catch (error) {
        // Nothing to do
      }
      const percentage = user.storage / availableStorage;
      availableStorage = (availableStorage / 1024).toFixed(1); // Ho presentem amb Gb amb 1 decimal
      const storage = (user.storage / 1024).toFixed(1); // Ho presentem amb Gb amb 1 decimal
      var storageColor = "success";
      //Colors
      if (percentage < 0.8) {
        storageColor = "success";
      } else if (percentage < 0.95) {
        storageColor = "warning";
      } else {
        storageColor = "danger";
      }
      if (this._isMounted)
        this.setState({
          baseStorage: availableStorage,
          storage: storage,
          storageColor: storageColor
        });
    } else {
      if (this._isMounted)
        this.setState({
          baseStorage: 0,
          storage: 0,
          storageColor: "danger"
        });
    }
  }

  userPatchedListener(user) {
    this.updateUser(user);
  }

  playerChangeListener(player) {
    this.updatePlayers();
  }

  componentDidMount() {
    this._isMounted = true;
    ga.pageview(window.location.pathname);
    feathersClient
      .service("users")
      .get(feathersAuth.userId)
      .then(user => {
        this.updateUser(user);
        this.updatePlayers();
      })
      .catch(error => {
        this.showNotification("Error fetching user: " + error.name, "warning");
      });
    feathersClient
      .service("users")
      .on("patched", this.userPatchedListener.bind(this));
    feathersClient
      .service("players")
      .on("created", this.playerChangeListener.bind(this));
    feathersClient
      .service("players")
      .on("patched", this.playerChangeListener.bind(this));
    feathersClient
      .service("players")
      .on("removed", this.playerChangeListener.bind(this));
  }

  componentWillUnmount() {
    this._isMounted = false;

    /*    feathersClient.service("users").removeListener("patched", this.userPatchedListener.bind(this));
    feathersClient.service("players").removeListener("created", this.playerChangeListener.bind(this));
    feathersClient.service("players").removeListener("patched", this.playerChangeListener.bind(this));
    feathersClient.service("players").removeListener("removed", this.playerChangeListener.bind(this));*/
    feathersClient.service("users").removeAllListeners("patched");
    feathersClient.service("players").removeAllListeners("created");
    feathersClient.service("players").removeAllListeners("patched");
    feathersClient.service("players").removeAllListeners("removed");
    clearTimeout(this.timer);
  }

  handleChange = (event, value) => {
    if (this._isMounted) this.setState({ value });
  };

  handleChangeIndex = index => {
    if (this._isMounted) this.setState({ value: index });
  };

  showNotification(message, color) {
    if (!this.state.notification["visible"]) {
      var x = this.state.notification;
      x["visible"] = true;
      x["text"] = message;
      x["color"] = color;
      if (this._isMounted) this.setState(x);
      // use this to make the notification autoclose
      setTimeout(
        function () {
          x["visible"] = false;
          if (this._isMounted) this.setState(x);
        }.bind(this),
        3000
      );
    }
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div>
        <Snackbars
          place="tc"
          color={this.state.notification.color}
          //icon={AddAlert}
          message={this.state.notification.text}
          open={this.state.notification.visible}
          closeNotification={() => {
            if (this._isMounted)
              this.setState({ "notification.visible": false });
          }}
        />
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color={this.state.onlineColor}>
                  <Cast />
                </CardIcon>
                <p className={classes.cardCategory}>{t('players')}</p>
                <h5 className={classes.cardTitle}>
                  {this.state.online +
                    "/" +
                    this.state.players.length +
                    " "}
                    <small>{ t('online') }</small>
                </h5>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats} style={{ color: "#4caf50" }}>
                  <Cast />
                  <Link to="players">{t('go_to_players')}</Link>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color={this.state.playingColor}>
                  <PlaylistPlay />
                </CardIcon>
                <p className={classes.cardCategory}>{t('players')}</p>
                <h5 className={classes.cardTitle}>
                  {this.state.playing +
                    "/" +
                    this.state.players.length +
                    " "}
                    <small>{ t('playing') }</small>
                </h5>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats} style={{ color: "#4caf50" }}>
                  <Cast />
                  <Link to="players">{t('go_to_players')}</Link>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color={this.state.licensedColor}>
                  <Description />
                </CardIcon>
                <p className={classes.cardCategory}>{t('licenses')}</p>
                <h5 className={classes.cardTitle}>
                  {this.state.licensed +
                    "/" +
                    this.state.players.length +
                    " "}
                    <small>{ t('licensed') }</small>
                </h5>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats} style={{ color: "#4caf50" }}>
                  <Description />
                  <a href={config.subscriptionURL} target="_blank" rel="noopener noreferrer">
                    {t('get_new_licenses')}
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color={this.state.storageColor}>
                  <Storage />
                </CardIcon>
                <p className={classes.cardCategory}>{t('disk_usage')}</p>
                <h4 className={classes.cardTitle}>
                  {this.state.storage + "/" + this.state.baseStorage}{" "}
                  <small>GB</small>
                </h4>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats} style={{ color: "#4caf50" }}>
                  <Storage />
                  <a href={config.subscriptionURL} target="_blank" rel="noopener noreferrer">
                    {t('get_more_storage')}
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(withTranslation()(Dashboard));
