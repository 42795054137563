import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";

// material-ui icons
import Menu from "@material-ui/icons/MenuOutlined";
import MoreVert from "@material-ui/icons/MoreVertOutlined";
import ViewList from "@material-ui/icons/ViewListOutlined";

// core components
import HeaderLinks from "./HeaderLinks";
import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-pro-react/components/headerStyle.jsx";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth
    };
  }
  componentDidMount(){
    window.addEventListener("resize", this.updateWidth);
  }
  componentWillUnmount(){
    window.removeEventListener("resize", this.updateWidth);
  }
  // to update width for the left side menu
  updateWidth = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  }
  makeBrand = () => {
    var name;
    this.props.routes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      }
      if (prop.path === this.props.location.pathname) {
        name = prop.name;
      }
      // JORDI - Inici
      if (prop.path.indexOf(':') > -1) { //Si conté : és una ruta dinàmica
        var path = prop.path.slice(0, prop.path.lastIndexOf('/'));
        var pathname = this.props.location.pathname.slice(0, this.props.location.pathname.lastIndexOf('/'));
        if (path === pathname) {
          name = prop.name;
        }
      }
      // JORDI - Fi
      return null;
    });
    if(name){
      return name;
    } else {
      return "Default Brand Name";
    }
  }
  render(){
    const { classes, color, rtlActive } = this.props;
    const appBarClasses = cx({
      [" " + classes[color]]: color
    });
    const sidebarMinimize =
      classes.sidebarMinimize +
      " " +
      cx({
        [classes.sidebarMinimizeRTL]: rtlActive
      });
    return (
      <AppBar className={classes.appBar + appBarClasses}>
        {
          this.state.windowWidth < 960 ? (
            <Toolbar className={classes.container}>
              <Hidden smDown implementation="css">
                <div className={sidebarMinimize}>
                  {this.props.miniActive ? (
                    <Button
                      justIcon
                      round
                      color="white"
                      onClick={this.props.sidebarMinimize}
                    >
                      <ViewList className={classes.sidebarMiniIcon} />
                    </Button>
                  ) : (
                    <Button
                      justIcon
                      round
                      color="white"
                      onClick={this.props.sidebarMinimize}
                    >
                      <MoreVert className={classes.sidebarMiniIcon} />
                    </Button>
                  )}
                </div>
              </Hidden>
              <div className={classes.flex}>
                {/* Here we create navbar brand, based on route name */}
                <Hidden mdUp implementation="css">
                  <Button
                    className={classes.appResponsive}
                    color="transparent"
                    justIcon
                    aria-label="open drawer"
                    onClick={this.props.handleDrawerToggle}
                  >
                    <Menu />
                  </Button>
                </Hidden>
              </div>
              <Button href="#" className={classes.title} color="transparent">
                {this.makeBrand()}
              </Button>
              <Hidden smDown implementation="css">
                <HeaderLinks rtlActive={rtlActive} />
              </Hidden>

            </Toolbar>
          ):(
            <Toolbar className={classes.container}>
              <Hidden smDown implementation="css">
                <div className={sidebarMinimize}>
                  {this.props.miniActive ? (
                    <Button
                      justIcon
                      round
                      color="white"
                      onClick={this.props.sidebarMinimize}
                    >
                      <ViewList className={classes.sidebarMiniIcon} />
                    </Button>
                  ) : (
                    <Button
                      justIcon
                      round
                      color="white"
                      onClick={this.props.sidebarMinimize}
                    >
                      <MoreVert className={classes.sidebarMiniIcon} />
                    </Button>
                  )}
                </div>
              </Hidden>
              <div className={classes.flex}>
                {/* Here we create navbar brand, based on route name */}
                <Button href="#" className={classes.title} color="transparent">
                  {this.makeBrand()}
                </Button>
              </div>
              <Hidden smDown implementation="css">
                <HeaderLinks rtlActive={rtlActive} />
              </Hidden>
              <Hidden mdUp implementation="css">
                <Button
                  className={classes.appResponsive}
                  color="transparent"
                  justIcon
                  aria-label="open drawer"
                  onClick={this.props.handleDrawerToggle}
                >
                  <Menu />
                </Button>
              </Hidden>
            </Toolbar>
          )
        }
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool
};

export default withStyles(headerStyle)(Header);
