import React from "react";
import { Link } from "react-router-dom";
import { Octokit } from "@octokit/rest";

import { withTranslation } from 'react-i18next';

// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Assignment from "@material-ui/icons/AssignmentOutlined";
import Cast from "@material-ui/icons/CastOutlined";
import Delete from "@material-ui/icons/DeleteOutlined";
import CloudDownload from "@material-ui/icons/CloudDownloadOutlined";
import SignalWifiOff from "@material-ui/icons/SignalWifiOffOutlined";
import SignalWifi from "@material-ui/icons/SignalWifi4BarOutlined";
import PauseCircle from "@material-ui/icons/PauseCircleOutline";
import PlayCircle from "@material-ui/icons/PlayCircleOutline";
import ControlCamera from "@material-ui/icons/ControlCameraOutlined";
import Tooltip from "@material-ui/core/Tooltip";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Snackbars from "components/Snackbar/Snackbar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

//My components
import PlayerOptionsDialog from "./PlayerOptionsDialog";
import PlayerControlDialog from "./PlayerControlDialog";

// Feathers
import { feathersClient, activeUser } from "../../helpers/feathers";

// Styles
import playersStyle from "assets/jss/material-dashboard-pro-react/views/playersStyle";
import { validatePlayer } from "../../schemas/validators";

import moment from "moment";
import compareVersions from "compare-versions";

import defaults from "../../helpers/defaults";
import ga from "../../helpers/google-analytics";

const octokit = new Octokit();

class Players extends React.Component {
  _isMounted = false;
  _updateTimer = null;

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      groups: [], //Ha de ser un array, si no el map no funciona
      players: [],
      licenses: [],
      playerControlDialog: false,
      playerControl: {
        _id: "",
        screenshot: {
          file: "",
          updatedAt: "",
          createdAt: ""
        }
      },
      playerOptionsDialog: false,
      playerOptions: {
        id: "",
        name: "",
        groupName: "",
        group: "",
        location: "",
        configuration: {
          display: {
            controlTV: false,
            disableHDMI: false,
            controlType: "cec",
            rotation: 0,
            hdmiGroup: 'Auto',
            hdmiMode: 0,
            controlPort: ''
          },
          localization: {
            timezone: "",
            locale: "",
            keyboard: ""
          }
        },
        license: "",
        state: {
          serialPorts: []
        }
      },
      notification: {
        visible: false,
        text: "",
        color: "success"
      },
      githubVersion: "",
      githubPreVersion: ""
    };
  }

  getPlayers() {
    feathersClient
      .service("groups")
      .find({
        query: {
          userId: activeUser._id,
          $sort: {
            username: 1
          }
        }
      })
      .then(groups => {
        if (this._isMounted)
          this.setState({
            groups: groups.data
          });
      })
      .catch(error => {
        this.showNotification(
          this.props.t('error_fetching_groups') + ": " + error.name,
          "warning"
        );
        if (this._isMounted)
          this.setState({
            groups: []
          });
      })
      .then(() =>
        feathersClient
          .service("licenses")
          .find({
            query: {
              status: "active"
            }
          })
          .then(licenses => {
            if (this._isMounted)
              this.setState({
                licenses: licenses.data
              });
          })
          .catch(error => {
            this.showNotification(
              this.props.t('error_fetching_licenses') + ": " + error.name,
              "warning"
            );
            if (this._isMounted)
              this.setState({
                licenses: []
              });
          })
          .then(() =>
            feathersClient
              .service("players")
              .find()
              .then(players => {
                //players.data.forEach(player => this.addGroupName(player));
                players.data.forEach(player => {
                  this.customizePlayerData(player);
                });
                if (this._isMounted)
                  this.setState({
                    players: players.data
                  });
                feathersClient
                  .service("players")
                  .on("created", this.playerCreatedListener.bind(this));
                feathersClient
                  .service("players")
                  .on("patched", this.playerPatchedListener.bind(this));
                feathersClient
                  .service("players")
                  .on("removed", this.playerRemovedListener.bind(this));
              })
              .catch(error => {
                this.showNotification(
                  this.props.t('translation:error_fetching_players') + ": " + error.name,
                  "warning"
                );
                if (this._isMounted)
                  this.setState({
                    players: []
                  });
              })
          )
      );
  }

  getGithubVersions() {
    octokit.repos
      .listReleases({
        owner: "cityadpro",
        repo: "player"
      })
      .then(releases => {
        const releasesList = releases.data.filter(release => release.prerelease === false);
        const prereleasesList = releases.data.filter(release => release.prerelease === true);
        var releaseName = '';
        var prereleaseName = '';
        if (releasesList.length > 0) releaseName = releasesList[0].name;
        if (prereleasesList.length > 0) prereleaseName = prereleasesList[0].name;
        this.setState({
          githubVersion: releaseName,
          githubPreVersion: prereleaseName
        }, () => {
          var tempPlayers = this.state.players
          tempPlayers.forEach(player => {
            this.updateStatusActions(player);
          });
          this.setState({
            players: tempPlayers
          })
        });
        //console.log("GV: " + releaseName + "GPV: " + prereleaseName);
      })
      .catch(error => {
      });
  }

  componentDidMount() {
    this._isMounted = true;
    ga.pageview(window.location.pathname);
    this.getPlayers();
    this.getGithubVersions();
    this._updateTimer = setInterval(() => {
      this.state.players.forEach(player => {
        this.updateStatusActions(player);
      });
    }, defaults.playersUpdateState * 1000);
  }

  componentWillUnmount() {
    this._isMounted = false;

    feathersClient.service("players").removeAllListeners("created");
    feathersClient.service("players").removeAllListeners("patched");
    feathersClient.service("players").removeAllListeners("removed");

    clearInterval(this._updateTimer);
  }

  playerCreatedListener(player) {
    this.addPlayerEvent(player);
  }

  playerPatchedListener(player) {
    this.changePlayerOptionsEvent(player);
    if (this.state.playerControl._id === player._id) {
      this.setState({
        playerControl: player
      })
    }
  }

  playerRemovedListener(player) {
    this.removePlayerEvent(player);
  }

  hideAlert() {
    if (this._isMounted)
      this.setState({
        alert: null
      });
  }

  deletePlayerWarning(player) {
    if (this._isMounted)
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title={this.props.t('translation:are_you_sure')}
            onConfirm={() => this.removePlayer(player)}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={this.props.t('translation:yes_delete_it')}
            cancelBtnText={this.props.t('translation:cancel')}
            showCancel
          >
            {this.props.t('translation:this_will_remove_the_player')}
          </SweetAlert>
        )
      });
  }

  removePlayerEvent(player) {
    var temp = [...this.state.players];
    var index = temp.findIndex(x => x._id === player._id);
    if (index >= 0) {
      temp.splice(index, 1);
      if (this._isMounted)
        this.setState({
          players: temp
        });
    }
  }

  removePlayer(player) {
    this.hideAlert();
    feathersClient
      .service("players")
      .remove(player._id)
      .then(message => {
        this.showNotification(this.props.t('translation:player_deleted_successfully'), "success");
      })
      .catch(error => {
        this.showNotification(error.name + ": " + error.message, "warning");
      });
  }

  addNewPlayerClick = event => {
    var temp = {}; // el deixem buit i l'Ajv ens l'omplirà amb els valors per defecte
    validatePlayer(temp);
    if (!temp.state.serialPorts) {
      temp.state.serialPorts = [];
      //console.log("No player serial Ports (addNewPlayer) !!!")
    }
    if (this._isMounted) this.setState({ playerOptions: temp });
    this.showPlayerOptions(true);
  };

  upgradePlayerWarning(player) {
    if (this._isMounted)
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title={this.props.t('are_you_sure')}
            onConfirm={() => this.upgradePlayerVersion(player)}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={this.props.t('translation:yes_upgrade_it')}
            cancelBtnText={this.props.t('translation:cancel')}
            showCancel
          >
            {this.props.t('translation:this_will_upgrade_the_player_version')}
          </SweetAlert>
        )
      });
  }

  upgradePlayerVersion(player) {
    this.hideAlert();
    var newVersion = this.state.githubVersion;
    if (player.allowPreRelease) newVersion = this.state.githubPreVersion;
    feathersClient
      .service("players")
      .patch(player._id, {
        upgradeVersion: newVersion
      })
      .then(player => {
        this.showNotification(this.props.t('translation:player') + " " + player.name + " " + this.props.t('translation:set_to_upgrade'), "success");
      })
      .catch(error => {
        this.showNotification(error.name + ": " + error.message, "warning");
      });
  }

  changePlayerOptionsClick(player) {
    if (this._isMounted)
      this.setState({ playerOptions: player }, () => {
        this.showPlayerOptions(true);
      });
  }

  playerControlClick(player) {
    if (this._isMounted)
      this.setState({ playerControl: player }, () => {
        this.showPlayerControl(true);
      });
  }

  showNotification(message, color) {
    if (!this.state.notification["visible"]) {
      var x = this.state.notification;
      x["visible"] = true;
      x["text"] = message;
      x["color"] = color;
      if (this._isMounted) this.setState(x);
      // use this to make the notification autoclose
      setTimeout(
        function () {
          x["visible"] = false;
          if (this._isMounted) this.setState(x);
        }.bind(this),
        3000
      );
    }
  }

  showPlayerOptions(show) {
    if (this._isMounted)
      this.setState({
        playerOptionsDialog: show
      });
  }

  showPlayerControl(show) {
    if (this._isMounted)
      this.setState({
        playerControlDialog: show
      });
  }

  applyPlayerOptionsClick(options) {
    this.showPlayerOptions(false);
    if (options._id === "") {
      this.addPlayer(options);
    } else {
      this.changePlayerOptions(options);
    }
  }

  updateStatusActions(player) {
    this.addPlayerActions(player);
    this.addPlayerStatus(player);
  }

  customizePlayerData(player) {
    validatePlayer(player);
    if (!player.state.serialPorts) {
      player.state.serialPorts = [];
      //console.log("No player serial Ports (customizePlayerData) !!!")
    }
    this.addGroupName(player);
    this.addPlayerLink(player);
    this.addGroupLink(player);
    this.updateStatusActions(player);
  }

  addPlayerEvent(newPlayer) {
    this.customizePlayerData(newPlayer);
    if (this._isMounted)
      this.setState({
        players: [...this.state.players, newPlayer]
      });
  }

  addPlayer(options) {
    feathersClient
      .service("players")
      .create({
        name: options.name,
        id: options.id,
        group: options.group,
        location: options.location,
        license: options.license,
        configuration: options.configuration
      })
      .then(newPlayer => {
        this.showNotification(this.props.t('translation:new_player_created_correctly'), "success");
      })
      .catch(error => {
        this.showNotification("Error: " + error.message, "warning");
      });
  }

  addConfiguration(player) {
    if (!player.configuration) player.configuration = {};
    if (!player.configuration.display) player.configuration.display = {};
    if (!player.configuration.display.controlTV)
      player.configuration.display.controlTV = false;
    if (!player.configuration.display.disableHDMI)
      player.configuration.display.disableHDMI = false;
    if (!player.configuration.display.controlType)
      player.configuration.display.controlType = "cec";
    if (!player.configuration.display.rotation)
      player.configuration.display.rotation = 0;
  }

  addGroupName(player) {
    if (this.state.groups.length > 0) {
      var index = this.state.groups.findIndex(x => x._id === player.group);
      if (index >= 0) {
        player.groupName = this.state.groups[index].name;
      } else {
        player.group = "";
        player.groupName = "";
      }
    } else {
      player.group = "";
      player.groupName = "";
    }
  }

  addPlayerLink(player) {
    player.playerLink = (
      <Tooltip
        disableFocusListener={true}
        id="tooltip-top"
        title={"IP: " + player.state.ip}
        placement="top"
        classes={{ tooltip: this.props.classes.tooltip }}
        key={player.name}
      >
        <div>{player.name}</div>
      </Tooltip>
    );
  }

  addGroupLink(player) {
    const url = "groups/" + player.group;
    player.groupLink = (
      <Link to={url} key={player.groupName}>
        {player.groupName}
      </Link>
    );
  }

  getUpgradable(player, githubVersion, githubPreVersion) {
    var upgradable = false;
    try {
      const vPlayerVersion = compareVersions.validate(player.version) ? player.version : '0.0.0';
      const vPlayerUpgradeVersion = compareVersions.validate(player.upgradeVersion) ? player.upgradeVersion : '0.0.0';
      const vGithubVersion = compareVersions.validate(githubVersion) ? githubVersion : '0.0.0';
      const vGithubPreVersion = compareVersions.validate(githubPreVersion) ? githubPreVersion : '0.0.0';

      var newGithubVersion = vGithubVersion;
      if (player.allowPreRelease && compareVersions.compare(vGithubPreVersion, vGithubVersion, '>')) {
        newGithubVersion = vGithubPreVersion;
      }
      /*if (player.upgradeVersion !== "" &&
        compareVersions.validate(player.upgradeVersion) &&
        compareVersions.compare(player.upgradeVersion, playerVersion, '>')) {
        playerVersion = player.upgradeVersion;
      }*/
      upgradable =
        compareVersions.compare(newGithubVersion, vPlayerVersion, '>') &&
        compareVersions.compare(newGithubVersion, vPlayerUpgradeVersion, '>');
    } catch (error) {
      upgradable = false;
    }
    return upgradable;
  }

  addPlayerActions(player) {
    player.actions = (
      // we've added some custom button actions
      <div className="actions-right">
        {/* use this button to add a like kind of action */}
        <Tooltip
          disableFocusListener={true}
          id="tooltip-top"
          title={this.props.t('translation:screenshots')}
          placement="top"
          classes={{ tooltip: this.props.classes.tooltip }}
        >
          <Button
            justIcon
            round
            simple
            onClick={() => {
              this.playerControlClick(player);
            }}
            color="primary"
            className="edit"
          /*disabled={
            !this.getUpgradable(player, this.state.githubVersion, this.state.githubPreVersion)
          }*/
          >
            <ControlCamera />
          </Button>
        </Tooltip>
        {/* use this button to add a like kind of action */}
        <Tooltip
          disableFocusListener={true}
          id="tooltip-top"
          title={this.props.t('translation:options')}
          placement="top"
          classes={{ tooltip: this.props.classes.tooltip }}
        >
          <Button
            justIcon
            round
            simple
            onClick={() => {
              this.changePlayerOptionsClick(player);
            }}
            color="warning"
            className="edit"
          >
            <Assignment />
          </Button>
        </Tooltip>
        {/* use this button to add a like kind of action */}
        <Tooltip
          disableFocusListener={true}
          id="tooltip-top"
          title={this.props.t('translation:upgrade_player')}
          placement="top"
          classes={{ tooltip: this.props.classes.tooltip }}
        >
          <Button
            justIcon
            round
            simple
            onClick={() => {
              this.upgradePlayerWarning(player);
            }}
            color="success"
            className="edit"
            disabled={
              !this.getUpgradable(player, this.state.githubVersion, this.state.githubPreVersion)
            }
          >
            <CloudDownload />
          </Button>
        </Tooltip>
        {/* use this button to remove the data row */}
        <Tooltip
          disableFocusListener={true}
          id="tooltip-top"
          title={this.props.t('translation:delete')}
          placement="top"
          classes={{ tooltip: this.props.classes.tooltip }}
        >
          <Button
            justIcon
            round
            simple
            onClick={() => this.deletePlayerWarning(player)}
            color="danger"
            className="delete"
          >
            <Delete />
          </Button>
        </Tooltip>
      </div>
    );
  }

  addPlayerStatus(player) {
    var connected = moment()
      .subtract(5, "minutes")
      .isSameOrBefore(moment(player.state.updatedAt));
    player.status = (
      // we've added some custom button actions
      <div className="actions-left">
        <Tooltip
          disableFocusListener={true}
          id="tooltip-top"
          title={
            this.props.t('translation:last_seen') + "\n" +
            moment(player.state.updatedAt).format(defaults.momentFormat)
          }
          placement="top"
          classes={{ tooltip: this.props.classes.tooltip }}
        >
          {connected ? (
            <SignalWifi
              style={{
                color: "#4caf50",
                fontSize: 20,
                width: "41px",
                height: "41px",
                paddingLeft: "12px",
                paddingRight: "12px"
              }}
            />
          ) : (
            <SignalWifiOff
              style={{
                color: "#f44336",
                fontSize: 20,
                width: "41px",
                height: "41px",
                paddingLeft: "12px",
                paddingRight: "12px"
              }}
            />
          )}
        </Tooltip>
        {connected && (
          <Tooltip
            disableFocusListener={true}
            id="tooltip-top"
            title={player.state.playing ? this.props.t('translation:playing') : this.props.t('translation:paused')}
            placement="top"
            classes={{ tooltip: this.props.classes.tooltip }}
          >
            {player.state.playing ? (
              <PlayCircle
                style={{
                  color: "#4caf50",
                  fontSize: 20,
                  width: "41px",
                  height: "41px",
                  paddingLeft: "12px",
                  paddingRight: "12px"
                }}
              />
            ) : (
              <PauseCircle
                style={{
                  color: "#ff9800",
                  fontSize: 20,
                  width: "41px",
                  height: "41px",
                  paddingLeft: "12px",
                  paddingRight: "12px"
                }}
              />
            )}
          </Tooltip>
        )}
      </div>
    );
  }

  changePlayerOptionsEvent(newPlayer) {
    // Ara substituim el fitxer de l'array d'estat per tal que el nou nom surti a la pantalla
    this.customizePlayerData(newPlayer);
    var temp = [...this.state.players];
    var index = temp.findIndex(x => x._id === newPlayer._id);
    temp.splice(index, 1, newPlayer);
    if (this._isMounted)
      this.setState({
        players: temp
      });
  }

  changePlayerOptions(options) {
    //event.preventDefault();
    this.showPlayerOptions(false);
    feathersClient
      .service("players")
      .patch(options._id, {
        name: options.name,
        id: options.id,
        group: options.group,
        location: options.location,
        license: options.license,
        configuration: options.configuration
      })
      .then(newPlayer => {
        this.showNotification(
          this.props.t('translation:new_player_options_submitted_correctly'),
          "success"
        );
        /*// Ara substituim el fitxer de l'array d'estat per tal que el nou nom surti a la pantalla
      this.addGroupName(newPlayer);
      this.addPlayerActions(newPlayer);
      var temp = [...this.state.players];
      var index = temp.findIndex(x => x._id === options._id);
      temp.splice(index, 1, newPlayer);
      if (this._isMounted) this.setState({
        players: temp
      });*/
      })
      .catch(error => {
        this.showNotification(this.props.t('translation:error') + ": " + error.name, "warning");
      });
  }

  keySort(a, b) {
    return a.key.localeCompare(b.key);
  }

  nameFilter(filter, row) {
    return row._original["name"]
      .toLowerCase()
      .includes(filter.value.toLowerCase());
  }

  locationFilter(filter, row) {
    return row._original["location"]
      .toLowerCase()
      .includes(filter.value.toLowerCase());
  }

  groupFilter(filter, row) {
    return row._original["groupName"]
      .toLowerCase()
      .includes(filter.value.toLowerCase());
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div>
        {this.state.alert}
        <Snackbars
          place="tc"
          color={this.state.notification.color}
          //icon={AddAlert}
          message={this.state.notification.text}
          open={this.state.notification.visible}
          closeNotification={() => {
            if (this._isMounted)
              this.setState({ "notification.visible": false });
          }}
        //close
        />
        <PlayerOptionsDialog
          key={'o' + this.state.playerOptions._id} //Canviar el valor del key ens assegura que React tornarà a crear el component amb els props nous
          playerOptions={this.state.playerOptions}
          players={this.state.players}
          groups={this.state.groups}
          licenses={this.state.licenses}
          open={this.state.playerOptionsDialog}
          onClose={() => this.showPlayerOptions(false)}
          onAccept={newOptions => this.applyPlayerOptionsClick(newOptions)}
        />
        <PlayerControlDialog
          key={'c' + this.state.playerControl._id} //Canviar el valor del key ens assegura que React tornarà a crear el component amb els props nous
          player={this.state.playerControl}
          open={this.state.playerControlDialog}
          onClose={() => this.showPlayerControl(false)}
          onAccept={() => this.showPlayerControl(false)}
        />
        <Card>
          <CardHeader color="success" icon>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <GridContainer>
                  <GridItem>
                    <CardIcon color="success">
                      <Cast />
                    </CardIcon>
                  </GridItem>
                  <GridItem xs={4}>
                    <h4 className={classes.cardIconTitle}>{t('translation:players')}</h4>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Button
                      color="success"
                      simple
                      className={classes.marginRight}
                      onClick={this.addNewPlayerClick}
                      style={{ maxWidth: "140px" }}
                    >
                      <Cast className={classes.icons} /> {t('translation:new_player')}
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={this.state.players}
              filterable
              previousText={this.props.t('translation:previous')}
              nextText={this.props.t('translation:next')}
              loadingText={this.props.t('translation:loading')}
              noDataText={this.props.t('translation:no_rows_found')}
              pageText={this.props.t('translation:page')}
              ofText={this.props.t('translation:of')}
              rowsText={this.props.t('translation:rows')}
              pageJumpText={this.props.t('translation:jump_to_page')}
              rowsSelectorText={this.props.t('translation:rows_per_page')}
              columns={[
                {
                  Header: t('translation:name'),
                  accessor: "playerLink",
                  filterMethod: this.nameFilter,
                  sortMethod: this.keySort
                },
                {
                  Header: t('translation:location'),
                  accessor: "location",
                  filterMethod: this.locationFilter
                  //sortMethod: this.rowSort
                },
                {
                  Header: t('translation:group'),
                  accessor: "groupLink",
                  filterMethod: this.groupFilter,
                  sortMethod: this.keySort
                },
                {
                  Header: t('translation:status'),
                  accessor: "status",
                  sortable: false,
                  filterable: false
                },
                {
                  Header: t('translation:actions'),
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  minWidth: 185
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withStyles(playersStyle)(withTranslation()(Players));
