//TODO: Hem de marcar els continguts que hagin expirat els període de validesa (en vermell?). Marcar també els continguts que encara no són vàlids (en taronja??).
//TODO: Hem de convertir les dates que mostrem a l'idioma que toca, ara sempre surten en anglès -> MM/DD/YYYY
//TODO: Mostrar les imatges en mode lazy
//TODO: Afegir un missatge si no hi ha cap element a la llista que digui "Arrossega el contingut aquí..." o alguna cosa així

import React, { Component } from "react";
import { Prompt } from "react-router";
import uuid from "uuid/v4";

import { withTranslation } from 'react-i18next';


// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

// @material-ui/icons
import PlaylistPlay from "@material-ui/icons/PlaylistPlayOutlined";
import Save from "@material-ui/icons/SaveOutlined";
import Restore from "@material-ui/icons/RestoreOutlined";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Snackbars from "components/Snackbar/Snackbar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// Feathers
import { feathersClient } from "../../helpers/feathers";
import ga from "../../helpers/google-analytics";

// Media Types
import {
  videoType,
  audioType,
  videoTypeArray,
  audioTypeArray
} from "../../variables/media.jsx";

// Styles
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import PlaylistPreview from "./PlaylistPreview";

import { validatePlaylist } from "../../schemas/validators";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
/**
 * Moves an item from one list to another list.
 */
const copy = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const item = sourceClone[droppableSource.index];

  destClone.splice(droppableDestination.index, 0, { ...item, id: uuid() });
  return destClone;
};

class Playlist extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    var typeArray;
    var playlistTitle;
    var availablelistTitle;

    switch (this.props.playlistType) {
      case "video":
        typeArray = Object.values(videoType);
        playlistTitle = this.props.t('translation:video_playlist_media');
        availablelistTitle = this.props.t('translation:available_video_media');
        break;
      case "audio":
        typeArray = Object.values(audioType);
        playlistTitle = this.props.t('translation:audio_playlist_media');
        availablelistTitle = this.props.t('translation:available_audio_media');
        break;
      default:
        break;
    }

    this.state = {
      tab: 0,
      alert: null,
      id: 0,
      playlistTitle: playlistTitle,
      availablelistTitle: availablelistTitle,
      typeArray: typeArray,
      categories: [],
      media: [], //La llista de media per categoria i tipus
      medialist: [], //La llista de media de la playlist
      selectedCategoryId: "",
      searchString: "",
      selectedMediaType: "all",
      playlist: null,
      random: false,
      mute: false,
      timed: false,
      timedTime: 30,
      playlistChanges: false,
      playlistOptionsDialog: false,
      playlistOptions: {
        _id: "",
        name: ""
      },
      notification: {
        visible: false,
        text: "",
        color: "success"
      }
    };
  }

  /*mediaList = [];

  typeArray = [];

  playlistTitle = "";
  availablelistTitle = "";*/

  onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (this._isMounted)
      this.setState({
        playlistChanges: true
      });
    switch (source.droppableId) {
      case destination.droppableId: //Si el source i el destination són iguals l'hem de reordenar
        if (this._isMounted)
          this.setState({
            medialist: reorder(
              this.state.medialist,
              source.index,
              destination.index
            )
          });
        break;
      case "media": // Si el source és la llista de media, l'hem de copiar a la llista de la playlist
        if (this._isMounted)
          this.setState({
            medialist: copy(
              this.state.media,
              this.state.medialist,
              source,
              destination
            )
          });
        break;
      default:
        /*if (this._isMounted) this.setState(
          move(
            this.state[source.droppableId],
            this.state[destination.droppableId],
            source,
            destination
          )
        );*/
        break;
    }
  };

  updateMediaList() {
    var media = [];
    let search = this.state.searchString.trim().toLowerCase();
    if (this.state.selectedMediaType === "all") {
      media = this.mediaList.filter(
        x => x.category === this.state.selectedCategoryId &&
          (search === "" ||
            (search !== "" && x.name.toLowerCase().includes(search)))
      );
    } else {
      media = this.mediaList.filter(
        x =>
          x.type === this.state.selectedMediaType &&
          x.category === this.state.selectedCategoryId &&
          (search === "" ||
            (search !== "" && x.name.toLowerCase().includes(search)))
      );
    }
    if (this._isMounted) this.setState({ media: media });
  }

  handleCategorySelectChange = event => {
    if (this.state.selectedCategoryId !== event.target.value) {
      if (this._isMounted)
        this.setState(
          { selectedCategoryId: event.target.value },
          this.updateMediaList
        );
    }
  };

  handleSearchChange = event => {
    if (this.state.searchString !== event.target.value) {
      if (this._isMounted)
        this.setState(
          { searchString: event.target.value },
          this.updateMediaList
        );
    }
  };

  handleTypeSelectChange = event => {
    if (this.state.selectedMediaType !== event.target.value) {
      if (this._isMounted)
        this.setState(
          { selectedMediaType: event.target.value },
          this.updateMediaList
        );
    }
  };

  loadPlaylist() {
    const {
      match: { params }
    } = this.props;
    feathersClient
      .service("playlists")
      .get(params.playlistId)
      .then(playlist => {
        validatePlaylist(playlist);
        var medialist = [];
        playlist.mediaList.forEach(media => {
          var index = this.mediaList.findIndex(x => x._id === media._id);
          if (index > -1) {
            var temp = Object.assign({}, this.mediaList[index]);
            temp.defaultDuration = media.duration;
            temp.mute = media.mute;
            temp.id = uuid(); //Li hem de posar un id únic sinó el dnd no funciona
            medialist.push(temp);
          }
        });
        if (this._isMounted)
          this.setState({
            playlist: playlist,
            medialist: medialist,
            random: playlist.random,
            mute: playlist.mute,
            timed: playlist.timed,
            timedTime: playlist.timedTime
          });
      })
      .catch(error => {
        this.showNotification(
          this.props.t('translation:error_fetching_playlist') + ": " + error.name,
          "warning"
        );
        if (this._isMounted)
          this.setState({
            playlist: [],
            medialist: []
          });
      });
    if (this._isMounted)
      this.setState({
        playlistChanges: false
      });
  }

  updateListChanges() {
    var playlist = this.state.playlist;
    playlist.random = this.state.random;
    playlist.mute = this.state.mute;
    playlist.timed = this.state.timed;
    playlist.timedTime = this.state.timedTime;
    playlist.mediaList = [];
    this.state.medialist.forEach(media => {
      var temp = {
        _id: media._id,
        duration: media.defaultDuration,
        mute: media.mute || false
      };
      playlist.mediaList.push(temp);
    });
    feathersClient
      .service("playlists")
      .patch(playlist._id, playlist)
      .then(playlist => {
        validatePlaylist(playlist);
        if (this._isMounted)
          this.setState({
            playlist: playlist,
            playlistChanges: false
          });
        this.showNotification(this.props.t('translation:playlist_changes_applied_successfully'), "success");
      })
      .catch(error => {
        this.showNotification(
          this.props.t('translation:error_applying_playlist_changes') + ": " + error.name,
          "warning"
        );
      });
  }

  componentDidMount() {
    this._isMounted = true;
    ga.pageview(window.location.pathname);

    var types = [];
    switch (this.props.playlistType) {
      case "video":
        types = videoTypeArray;
        break;
      case "audio":
        types = audioTypeArray;
        break;
      default:
        break;
    }
    feathersClient
      .service("categories")
      .find({ query: { $sort: { name: 1 } } })
      .then(categories => {
        feathersClient
          .service("media")
          .find({
            query: {
              type: {
                $in: types
              },
              $sort: { name: 1 }
            }
          })
          .then(media => {
            this.mediaList = media.data;
            this.mediaList.forEach(media => (media.id = uuid())); //Li posem un id únic, sinó el dnd no funciona
            var selectedCategoryId = "";
            if (categories.data.length > 0) {
              selectedCategoryId = categories.data[0]._id;
            }
            if (this._isMounted)
              this.setState(
                {
                  categories: categories.data,
                  selectedCategoryId: selectedCategoryId
                },
                this.updateMediaList
              );
            this.loadPlaylist();
          })
          .catch(error => {
            this.showNotification(
              this.props.t('translation:error_fetching_media') + ": " + error.name,
              "warning"
            );
          });
      })
      .catch(error => {
        this.showNotification(
          this.props.t('translation:error_fetching_playlist') + ": " + error.name,
          "warning"
        );
        if (this._isMounted)
          this.setState({
            playlist: []
          });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  showNotification(message, color) {
    if (!this.state.notification["visible"]) {
      var x = this.state.notification;
      x["visible"] = true;
      x["text"] = message;
      x["color"] = color;
      if (this._isMounted) this.setState(x);
      // use this to make the notification autoclose
      setTimeout(
        function () {
          x["visible"] = false;
          if (this._isMounted) this.setState(x);
        }.bind(this),
        3000
      );
    }
  }

  handleRandomChange = () => event => {
    if (this._isMounted)
      this.setState({
        random: event.target.checked,
        playlistChanges: true
      });
  };

  handleMuteChange = () => event => {
    if (this._isMounted)
      this.setState({
        mute: event.target.checked,
        playlistChanges: true
      });
  };

  handleTimedChange = () => event => {
    if (this._isMounted)
      this.setState({
        timed: event.target.checked,
        playlistChanges: true
      });
  };

  handleTimedTimeChange(event, name) {
    var newTimedTime = parseInt(event.target.value, 10);
    if (newTimedTime < 1) newTimedTime = 1;
    if (this._isMounted)
      this.setState({
        timedTime: newTimedTime,
        playlistChanges: true
      });
  }

  handleMediaTimeChange(media, event) {
    // Primer busquem a la llista de la playlist
    var newDuration = parseInt(event.target.value);
    if (media.type === "videostream" || media.type === "audiostream") {
      if (newDuration < 0) newDuration = 0;
    } else if (newDuration < 1) {
      newDuration = 1;
    }
    var temp;
    var listname;
    temp = this.state.medialist;
    listname = "medialist";
    var index = temp.findIndex(x => x.id === media.id);
    if (index > -1) {
      temp[index].defaultDuration = newDuration;
      if (this._isMounted)
        this.setState({
          [listname]: temp,
          playlistChanges: true
        });
    } else {
      temp = this.state.media;
      index = temp.findIndex(x => x.id === media.id);
      if (index > -1) {
        temp[index].defaultDuration = newDuration;
        if (this._isMounted)
          this.setState({
            media: temp
          });
      }
    }
  }

  handleMediaMuteChange(media, mute) {
    // Primer busquem a la llista de la playlist
    var temp;
    var listname;
    temp = this.state.medialist;
    listname = "medialist";
    var index = temp.findIndex(x => x.id === media.id);
    if (index > -1) {
      temp[index].mute = mute;
      if (this._isMounted)
        this.setState({
          [listname]: temp,
          playlistChanges: true
        });
    } else {
      temp = this.state.media;
      index = temp.findIndex(x => x.id === media.id);
      if (index > -1) {
        temp[index].mute = mute;
        if (this._isMounted)
          this.setState({
            media: temp
          });
      }
    }
  }

  removeMedia(media) {
    var temp;
    var listname;
    temp = this.state.medialist;
    listname = "medialist";
    var index = temp.findIndex(x => x.id === media.id);
    if (index > -1) {
      temp.splice(index, 1);
      if (this._isMounted)
        this.setState({
          [listname]: temp,
          playlistChanges: true
        });
    }
  }

  addMedia(media) {
    if (this._isMounted) {
      var temp;
      var listname;
      temp = this.state.medialist;
      listname = "medialist";
      temp.push({ ...media, id: uuid() });
      this.setState({
        [listname]: temp,
        playlistChanges: true
      });
    }
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div>
        {this.state.alert}
        <Prompt
          when={this.state.playlistChanges}
          message={t('translation:are_you_sure_you_want_to_loose_your_changes')}
        />
        <Snackbars
          place="tc"
          color={this.state.notification.color}
          message={this.state.notification.text}
          open={this.state.notification.visible}
          closeNotification={() => {
            if (this._isMounted)
              this.setState({ "notification.visible": false });
          }}
        />
        <Card>
          <CardHeader color="success" icon>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <GridContainer>
                  <GridItem>
                    <CardIcon color="success">
                      <PlaylistPlay />
                    </CardIcon>
                  </GridItem>
                  <GridItem xs={4}>
                    <h4 className={classes.cardIconTitle}>
                      {this.state.playlist ? this.state.playlist.name : ""}
                    </h4>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} md={6}>
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Button
                      color="success"
                      simple
                      className={classes.marginRight}
                      disabled={!this.state.playlistChanges}
                      onClick={this.loadPlaylist.bind(this)}
                      style={{ maxWidth: "140px" }}
                    >
                      <Restore className={classes.icons} /> {t('translation:undo_changes')}
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      color="success"
                      simple
                      className={classes.marginRight}
                      disabled={!this.state.playlistChanges}
                      onClick={this.updateListChanges.bind(this)}
                      style={{ maxWidth: "140px" }}
                    >
                      <Save className={classes.icons} /> {t('translation:save_changes')}
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <h4 className={classes.cardIconTitle}>{t('translation:options')}</h4>
            <GridContainer>
              <GridItem xs={12} md={6} lg={3} xl={2}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.random}
                      onChange={this.handleRandomChange("random")}
                      value="random"
                      classes={{
                        switchBase: classes.switchBase,
                        checked: classes.switchChecked,
                        icon: classes.switchIcon,
                        iconChecked: classes.switchIconChecked,
                        bar: classes.switchBar
                      }}
                    />
                  }
                  classes={{
                    label: classes.label
                  }}
                  label={t('translation:random_media')}
                />
              </GridItem>
              {this.props.playlistType === "video" && (
                <GridItem xs={12} md={6} lg={3} xl={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={this.state.mute}
                        onChange={this.handleMuteChange("mute")}
                        value="mute"
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar
                        }}
                      />
                    }
                    classes={{
                      label: classes.label
                    }}
                    label={t('translation:mute_media')}
                  />
                </GridItem>
              )}
              <GridItem xs={12} md={12} lg={6} xl={4}>
                <GridContainer>
                  <GridItem xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.timed}
                          onChange={this.handleTimedChange("timed")}
                          value="timed"
                          classes={{
                            switchBase: classes.switchBase,
                            checked: classes.switchChecked,
                            icon: classes.switchIcon,
                            iconChecked: classes.switchIconChecked,
                            bar: classes.switchBar
                          }}
                        />
                      }
                      classes={{
                        label: classes.label
                      }}
                      label={t('translation:timed_playlist')}
                    />
                  </GridItem>
                  <GridItem xs={12} md={6}>
                    <CustomInput
                      labelText={t('translation:interval_s')}
                      id="timedTime"
                      formControlProps={{ fullWidth: false }}
                      inputProps={{
                        type: "number",
                        value: this.state.timedTime,
                        disabled: !this.state.timed,
                        onChange: event =>
                          this.handleTimedTimeChange(event, "timedTime")
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <PlaylistPreview
              playlistTitle={this.state.playlistTitle}
              availablelistTitle={this.state.availablelistTitle}
              onDragEnd={this.onDragEnd.bind(this)}
              medialist={this.state.medialist}
              media={this.state.media}
              random={this.state.random}
              handleRandomChange={this.handleRandomChange.bind(this)}
              timed={this.state.timed}
              handleTimedChange={this.handleTimedChange.bind(this)}
              timedTime={this.state.timedTime}
              handleTimedTimeChange={this.handleTimedTimeChange.bind(this)}
              handleMediaTimeChange={this.handleMediaTimeChange.bind(this)}
              handleMediaMuteChange={this.handleMediaMuteChange.bind(this)}
              removeMedia={this.removeMedia.bind(this)}
              addMedia={this.addMedia.bind(this)}
              handleCategorySelectChange={this.handleCategorySelectChange.bind(
                this
              )}
              handleSearchChange={this.handleSearchChange.bind(
                this
              )}
              selectedCategoryId={this.state.selectedCategoryId}
              handleTypeSelectChange={this.handleTypeSelectChange.bind(this)}
              selectedMediaType={this.state.selectedMediaType}
              categories={this.state.categories}
              typeArray={this.state.typeArray}
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withStyles(dashboardStyle)(withTranslation()(Playlist));
