import React from "react";
import styled from "styled-components";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { withTranslation } from 'react-i18next';

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

import MediaPreview from "./MediaPreview";

import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

const Item = styled.div`
  user-select: none;
`;

const Clone = styled(Item)`
  + div {
    display: none !important;
  }
`;

const Notice = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 0.5rem;
  margin: 0 0.5rem 0.5rem;
  border: 1px solid transparent;
  line-height: 1.5;
  color: #aaa;
`;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightgrey" : "white",
  minHeight: '50px'
});

class PlaylistPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      media: [],
      selectedCategoryId: "",
      selectedMediaType: "all",
    };
  }

  componentDidUpdate() {
    if (
      this.props.categories.length > 0 &&
      this.state.selectedCategoryId === ""
    ) {
      this.setState(
        {
          selectedCategoryId: this.props.categories[0]._id,
          selectedMediaType: "all"
        },
        this.updateMediaList
      );
    }
  }

  componentDidMount() { }

  componentWillUnmount() { }

  handleTimedTimeChange(event, name) {
    this.props.handleTimedTimeChange(event);
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <DragDropContext onDragEnd={this.props.onDragEnd}>
        <GridContainer style={{ margin: "0", width: "100%" }}>
          <GridItem xs={12} md={7}>
            <Card>
              <CardBody>
                <h4 className={classes.cardIconTitle}>
                  {this.props.playlistTitle}
                </h4>
                <Droppable key="list" droppableId="list">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.props.medialist.length
                        ? this.props.medialist.map((media, index) => (
                          <Draggable
                            key={media.id}
                            draggableId={media.id}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <Item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                isDragging={snapshot.isDragging}
                                style={provided.draggableProps.style}
                              >
                                <MediaPreview
                                  media={media}
                                  deleteIcon={true}
                                  handleMediaTimeChange={
                                    this.props.handleMediaTimeChange
                                  }
                                  handleMediaMuteChange={
                                    this.props.handleMediaMuteChange
                                  }
                                  removeMedia={this.props.removeMedia}
                                  addMedia={this.props.addMedia}
                                />
                              </Item>
                            )}
                          </Draggable>
                        ))
                        : !provided.placeholder && (
                          <Notice>{t('translation:drag_drop_media_here')}</Notice>
                        )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} md={5}>
            <Card>
              <CardBody>
                <h4 className={classes.cardIconTitle}>
                  {this.props.availablelistTitle}
                </h4>
                <GridContainer>
                  <GridItem xs={12} xl={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="type-select"
                        className={classes.selectLabel}
                      >
                        {t('translation:media_type')}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.props.selectedMediaType}
                        onChange={this.props.handleTypeSelectChange}
                        inputProps={{
                          name: "selectedMediaType",
                          id: "type-select"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          {t('translation:choose_media_type')}
                        </MenuItem>
                        <MenuItem
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                          value={"all"}
                          key={"all"} //Posem el valor key per que el React no es queixi. Aquesta clau ha de ser única
                        >
                          {t('translation:all_media_type')}
                        </MenuItem>
                        {this.props.typeArray.map(type => {
                          //Hem de convertir la colecció en un array per poder fer el map
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={type.type}
                              key={type.type} //Posem el valor key per que el React no es queixi. Aquesta clau ha de ser única
                            >
                              {type.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} xl={6}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor="category-select"
                        className={classes.selectLabel}
                      >
                        {t('translation:category')}
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={this.props.selectedCategoryId}
                        onChange={this.props.handleCategorySelectChange}
                        inputProps={{
                          name: "selectedCategoryId",
                          id: "category-select"
                        }}
                      >
                        <MenuItem
                          disabled
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          {t('translation:choose_category')}
                        </MenuItem>
                        {this.props.categories.map(category => {
                          return (
                            <MenuItem
                              classes={{
                                root: classes.selectMenuItem,
                                selected: classes.selectMenuItemSelected
                              }}
                              value={category._id}
                              key={category._id} //Posem el valor key per que el React no es queixi. Aquesta clau ha de ser única
                            >
                              {category.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} xl={6}>
                  <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <CustomInput
                        //success={this.state.registerEmailState === "success"}
                        //error={this.state.registerEmailState === "error"}
                        labelText={t('translation:search')}
                        id="SearchString"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.name,
                          onChange: this.props.handleSearchChange,
                          /*onChange: event => this.handleChange(event, "search"),*/
                          type: "search"
                        }}
                      />
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <Droppable droppableId="media" isDropDisabled={true}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef}>
                      {this.props.media.map((media, index) => (
                        <Draggable
                          key={media.id}
                          draggableId={media.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <React.Fragment>
                              <Item
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                isDragging={snapshot.isDragging}
                                style={provided.draggableProps.style}
                              >
                                <MediaPreview
                                  media={media}
                                  handleMediaTimeChange={
                                    this.props.handleMediaTimeChange
                                  }
                                  handleMediaMuteChange={
                                    this.props.handleMediaMuteChange
                                  }
                                  addMedia={this.props.addMedia}
                                />
                              </Item>
                              {snapshot.isDragging && (
                                <Clone>
                                  <MediaPreview
                                    media={media}
                                    handleMediaTimeChange={
                                      this.props.handleMediaTimeChange
                                    }
                                    handleMediaMuteChange={
                                      this.props.handleMediaMuteChange
                                    }
                                    />
                                </Clone>
                              )}
                            </React.Fragment>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </DragDropContext>
    );
  }
}

export default withStyles(dashboardStyle)(withTranslation()(PlaylistPreview));
