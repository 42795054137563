import React from "react";
import styled from "styled-components";

import { withTranslation } from 'react-i18next';

import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import { ReactComponent as PlaylistPlay } from "../../assets/img/icons/playlist-play.svg";
import CalendarToday from "@material-ui/icons/CalendarTodayOutlined";
import Delete from "@material-ui/icons/DeleteOutline";
import Add from "@material-ui/icons/AddBoxOutlined";

import Tooltip from "@material-ui/core/Tooltip";

import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import mediaColors from "../../helpers/mediaColors";
import { Grid } from "@material-ui/core";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import ScreenSplit from "../../components/ScreenSplit/ScreenSplit";
import '../../assets/css/ScreenPreview.css';

class ScreenPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //console.log(this.props.list);
  }
  
  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <GridContainer style={{ margin: "0", width: "100%" }}>
        <GridItem xs={12}>
          <Card style={{ marginTop: "15px", marginBottom: "15px" }}>
            <CardHeader>
              <h4 className={classes.cardIconTitle}>
                {this.props.title}
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <ScreenSplit
                    screenWidth={1920}
                    screenHeight={1080}
                    sectionsList={this.props.list}
                    selectedSection={this.props.selectedSection}
                    onResize={(index, size) => {
                      this.props.onResize(index, size);
                      /*var list = {...this.props.list};
                      list[index].size.x = size.x;
                      list[index].size.y = size.y;
                      list[index].size.width = size.width;
                      list[index].size.height = size.height;
                      this.setState({
                        screensList: list
                      })*/
                    }}
                    onDrag={(index, position) => {
                      this.props.onDrag(index, position);
                      /*var list = {...this.props.list};
                      list[index].size.x = position.x;
                      list[index].size.y = position.y;
                      this.setState({
                        screensList: list
                      })*/
                    }}
                    changeSelected={(index) => {
                      this.props.onSelectedChange(index);
                      /*this.setState({
                        selectedScreen: index
                      })*/
                    }}
                  >
                  </ScreenSplit>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <GridContainer>
                    <GridItem xs={6}>
                      <Button
                        color="success"
                        simple
                        className={classes.marginRight}
                        //disabled={}
                        onClick={() => {
                          this.props.addSection();
                        }}
                        //style={{ maxWidth: "140px" }}
                      >
                        <Add className={classes.icons} /> {t('translation:add_section')}
                      </Button>
                    </GridItem>
                    <GridItem xs={6}>
                      <Button
                        color="danger"
                        simple
                        className={classes.marginRight}
                        disabled={(this.props.list.length < 2)}
                        onClick={() => {
                          this.props.removeSection();
                        }}
                        //style={{ maxWidth: "140px" }}
                      >
                        <Delete className={classes.icons} /> {t('translation:remove_section')}
                      </Button>
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={t('translation:position_x_percentage')}
                        id="positionXPercentage"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          //type: "number",
                          value: ((this.props.list.length > 0) ? Math.round(this.props.list[this.props.selectedSection].size.x * 100) : 0) + " %",
                          disabled: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={t('translation:position_y_percentage')}
                        id="positionYPercentage"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          //type: "number",
                          value: ((this.props.list.length > 0) ? Math.round(this.props.list[this.props.selectedSection].size.y * 100) : 0) + " %",
                          disabled: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={t('translation:width_percentage')}
                        id="widthPercentage"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          //type: "number",
                          value: ((this.props.list.length > 0) ? Math.round(this.props.list[this.props.selectedSection].size.width * 100) : 0) + " %",
                          disabled: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={6}>
                      <CustomInput
                        labelText={t('translation:height_percentage')}
                        id="heightPercentage"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          //type: "number",
                          style: { textAlign: "right" },
                          value: ((this.props.list.length > 0) ? Math.round(this.props.list[this.props.selectedSection].size.height * 100) : 0) + " %",
                          disabled: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withStyles(dashboardStyle)(withTranslation()(ScreenPreview));
