import React from "react";
import { Link } from "react-router-dom";

import { withTranslation } from 'react-i18next';

// react component for creating dynamic tables
import ReactTable from "react-table";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import Assignment from "@material-ui/icons/AssignmentOutlined";
import PlaylistPlay from "@material-ui/icons/PlaylistPlayOutlined";
import PlaylistAdd from "@material-ui/icons/PlaylistAddOutlined";
import Delete from "@material-ui/icons/DeleteOutline";

// core components
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Snackbars from "components/Snackbar/Snackbar.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";

// Feathers
import { feathersClient } from "../../helpers/feathers";
import ga from "../../helpers/google-analytics";

// Styles
import playlistsStyle from "assets/jss/material-dashboard-pro-react/views/playlistsStyle";

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class Playlists extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      playlists: [], //Ha de ser un array, si no el map no funciona
      playlistOptionsDialog: false,
      playlistOptions: {
        _id: "",
        name: ""
      },
      notification: {
        visible: false,
        text: "",
        color: "success"
      }
    };
  }

  componentDidMount() {
    this._isMounted = true;
    ga.pageview(window.location.pathname);

    feathersClient
      .service("playlists")
      .find({
        query: { listType: this.props.playlistType, $sort: { name: 1 } }
      })
      .then(playlists => {
        playlists.data.forEach(playlist => {
          this.addPlaylistLink(playlist);
          this.addPlaylistActions(playlist);
        });
        if (this._isMounted)
          this.setState({
            playlists: playlists.data
          });
        feathersClient
          .service("playlists")
          .on("created", playlist => this.addPlaylistEvent(playlist));
        feathersClient
          .service("playlists")
          .on("patched", playlist => this.changePlaylistOptionsEvent(playlist));
        feathersClient
          .service("playlists")
          .on("removed", playlist => this.removePlaylistEvent(playlist));
      })
      .catch(error => {
        this.showNotification(
          this.props.t('translation:error_fetching_playlists') + ": " + error.name,
          "warning"
        );
        if (this._isMounted)
          this.setState({
            playlists: []
          });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  hideAlert() {
    if (this._isMounted)
      this.setState({
        alert: null
      });
  }

  deletePlaylistWarning(playlist) {
    if (this._isMounted)
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title={this.props.t('translation:are_you_sure')}
            onConfirm={() => this.removePlaylist(playlist)}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={this.props.t('translation:yes_delete_it')}
            cancelBtnText={this.props.t('translation:cancel')}
            showCancel
          >
            {this.props.t('translation:this_will_remove_the_playlist')}
          </SweetAlert>
        )
      });
  }

  removePlaylistEvent(playlist) {
    var temp = [...this.state.playlists];
    var index = temp.findIndex(pl => {
      return playlist._id === pl._id;
    });
    if (index >= 0) {
      temp.splice(index, 1);
      if (this._isMounted)
        this.setState({
          playlists: temp
        });
      //console.log("Playlist removed: " + index);
    }
  }

  removePlaylist(playlist) {
    //event.preventDefault();
    this.hideAlert();
    feathersClient
      .service("playlists")
      .remove(playlist._id)
      .then(message => {
        this.showNotification(this.props.t('translation:playlist_deleted_successfully'), "success");
      })
      .catch(error => {
        this.showNotification(error.name + ": " + error.message, "warning");
      });
  }

  addNewPlaylistClick = event => {
    var temp = {
      _id: "", //Aquest és l'identificador del registre de la BBDD
      name: "" //Aquí hi va el nom del grup de la DDBB, no l'ID!!!
    };
    if (this._isMounted) this.setState({ playlistOptions: temp });
    this.showPlaylistOptions(true);
  };

  changePlaylistOptionsClick(playlist) {
    var temp = {
      _id: playlist._id, //Aquest és l'identificador del registre de la BBDD
      name: playlist.name //Aquí hi va el nom del grup de la DDBB, no l'ID!!!
    };
    if (this._isMounted) this.setState({ playlistOptions: temp });
    this.showPlaylistOptions(true);
  }

  showNotification(message, color) {
    if (!this.state.notification["visible"]) {
      var x = this.state.notification;
      x["visible"] = true;
      x["text"] = message;
      x["color"] = color;
      if (this._isMounted) this.setState(x);
      // use this to make the notification autoclose
      setTimeout(
        function () {
          x["visible"] = false;
          if (this._isMounted) this.setState(x);
        }.bind(this),
        3000
      );
    }
  }

  showPlaylistOptions(show) {
    if (this._isMounted)
      this.setState({
        playlistOptionsDialog: show
      });
  }

  handlePlaylistOptionChange(event, option) {
    var temp = this.state.playlistOptions;
    temp[option] = event.target.value;
    if (this._isMounted)
      this.setState({
        playlistOptions: temp
      });
  }

  applyPlaylistOptionsClick(options) {
    this.showPlaylistOptions(false);
    if (options._id === "") {
      this.addPlaylist(options);
    } else {
      this.changePlaylistOptions(options);
    }
  }

  addPlaylistEvent(newPlaylist) {
    this.addPlaylistLink(newPlaylist);
    this.addPlaylistActions(newPlaylist);
    if (this._isMounted)
      this.setState({
        playlists: [...this.state.playlists, newPlaylist]
      });
  }

  addPlaylist(options) {
    feathersClient
      .service("playlists")
      .create({
        name: options.name,
        listType: this.props.playlistType
      })
      .then(newPlaylist => {
        this.showNotification(this.props.t('translation:new_playlist_created_correctly'), "success");
      })
      .catch(error => {
        this.showNotification("Error: " + error.name, "warning");
      });
  }

  addPlaylistLink(playlist) {
    var url = "";
    switch (this.props.playlistType) {
      case "video":
        url = url + "videoplaylists/";
        break;
      case "audio":
        url = url + "audioplaylists/";
        break;
      default:
        url = "";
        break;
    }
    url = url + playlist._id;
    playlist.link = (
      <Link to={url} key={playlist.name}>
        {playlist.name}
      </Link>
    );
  }

  addPlaylistActions(playlist) {
    playlist.actions = (
      // we've added some custom button actions
      <div className="actions-right">
        {/* use this button to add a like kind of action */}
        <Tooltip
          disableFocusListener={true}
          id="tooltip-top"
          title={this.props.t('translation:options')}
          placement="top"
          classes={{ tooltip: this.props.classes.tooltip }}
        >
          <Button
            justIcon
            round
            simple
            onClick={() => {
              this.changePlaylistOptionsClick(playlist);
            }}
            color="warning"
            className="edit"
          >
            <Assignment />
          </Button>
        </Tooltip>
        {/* use this button to remove the data row */}
        <Tooltip
          disableFocusListener={true}
          id="tooltip-top"
          title={this.props.t('translation:delete')}
          placement="top"
          classes={{ tooltip: this.props.classes.tooltip }}
        >
          <Button
            justIcon
            round
            simple
            onClick={() => this.deletePlaylistWarning(playlist)}
            color="danger"
            className="delete"
          >
            <Delete />
          </Button>
        </Tooltip>
      </div>
    );
  }

  changePlaylistOptionsEvent(newPlaylist) {
    // Ara substituim el fitxer de l'array d'estat per tal que el nou nom surti a la pantalla
    this.addPlaylistLink(newPlaylist);
    this.addPlaylistActions(newPlaylist);
    var temp = [...this.state.playlists];
    var index = temp.findIndex(x => x._id === newPlaylist._id);
    temp.splice(index, 1, newPlaylist);
    if (this._isMounted)
      this.setState({
        playlists: temp
      });
  }

  changePlaylistOptions(options) {
    //event.preventDefault();
    this.showPlaylistOptions(false);
    feathersClient
      .service("playlists")
      .patch(options._id, {
        name: options.name
      })
      .then(newPlaylist => {
        this.showNotification(
          this.props.t('translation:new_playlist_options_submitted_correctly'),
          "success"
        );
        /*// Ara substituim el fitxer de l'array d'estat per tal que el nou nom surti a la pantalla
      this.addPlaylistActions(newPlaylist);
      var temp = [...this.state.playlists];
      var index = temp.findIndex(x => x._id === options._id);
      temp.splice(index, 1, newPlaylist);
      if (this._isMounted) this.setState({
        playlists: temp
      });*/
      })
      .catch(error => {
        this.showNotification(this.props.t('translation:error') + ": " + error.name, "warning");
      });
  }

  nameFilter(filter, row) {
    return row._original['name']
      .toLowerCase()
      .includes(filter.value.toLowerCase());
  }

  /*rowSort(a, b) {
    return a.props.children.localeCompare(b.props.children);
  }*/

  keySort(a, b) {
    return a.key.localeCompare(b.key);
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <div>
        {this.state.alert}
        <Snackbars
          place="tc"
          color={this.state.notification.color}
          //icon={AddAlert}
          message={this.state.notification.text}
          open={this.state.notification.visible}
          closeNotification={() => {
            if (this._isMounted)
              this.setState({ "notification.visible": false });
          }}
        //close
        />
        <Dialog
          classes={{
            root:
              this.props.classes.center + " " + this.props.classes.modalRoot,
            paper: this.props.classes.modal
          }}
          TransitionComponent={Transition}
          keepMounted
          open={this.state.playlistOptionsDialog}
          onClose={() => this.showPlaylistOptions(false)}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
          style={{ textAlign: "center" }}
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>{t('translation:playlist_configuration')}</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            <GridContainer>
              <GridItem xs={12}>
                <CustomInput
                  //success={this.state.registerEmailState === "success"}
                  //error={this.state.registerEmailState === "error"}
                  labelText={t('translation:name')}
                  id="name"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    value: this.state.playlistOptions.name,
                    onChange: event =>
                      this.handlePlaylistOptionChange(event, "name"),
                    type: "text"
                  }}
                />
              </GridItem>
            </GridContainer>
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button
              simple
              color="success"
              onClick={() =>
                this.applyPlaylistOptionsClick(this.state.playlistOptions)
              }
              className={classes.registerButton}
              xs={3}
            >
              {t('translation:apply')}
            </Button>
          </DialogActions>
        </Dialog>
        <Card>
          <CardHeader color="success" icon>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <GridContainer>
                  <GridItem>
                    <CardIcon color="success">
                      <PlaylistPlay />
                    </CardIcon>
                  </GridItem>
                  <GridItem xs={4}>
                    <h4 className={classes.cardIconTitle}>{t('translation:playlists')}</h4>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <GridContainer justify="flex-end">
                  <GridItem>
                    <Button
                      color="success"
                      simple
                      className={classes.marginRight}
                      onClick={this.addNewPlaylistClick}
                      style={{ maxWidth: "140px" }}
                    >
                      <PlaylistAdd className={classes.icons} /> {t('translation:new_playlist')}
                    </Button>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </CardHeader>
          <CardBody>
            <ReactTable
              data={this.state.playlists}
              filterable
              previousText={this.props.t('translation:previous')}
              nextText={this.props.t('translation:next')}
              loadingText={this.props.t('translation:loading')}
              noDataText={this.props.t('translation:no_rows_found')}
              pageText={this.props.t('translation:page')}
              ofText={this.props.t('translation:of')}
              rowsText={this.props.t('translation:rows')}
              pageJumpText={this.props.t('translation:jump_to_page')}
              rowsSelectorText={this.props.t('translation:rows_per_page')}
              columns={[
                {
                  Header: t('translation:name'),
                  accessor: "link",
                  filterMethod: this.nameFilter,
                  sortMethod: this.keySort
                },
                /*{
                  Header: "Media",
                  accessor: "mediaDescription",
                  sortable: false,
                  filterable: false
                },*/
                {
                  Header: t('translation:actions'),
                  accessor: "actions",
                  sortable: false,
                  filterable: false,
                  minWidth: 185
                }
              ]}
              defaultPageSize={10}
              showPaginationTop
              showPaginationBottom={false}
              className="-striped -highlight"
            />
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default withStyles(playlistsStyle)(withTranslation()(Playlists));
