//TODO: Hem de marcar els continguts que hagin expirat els període de validesa (en vermell?). Marcar també els continguts que encara no són vàlids (en taronja??).
//TODO: Que no es pugui posar una data anterior al ValidityFrom en el validityUntil i viceversa
//TODO:  Mirar per què els .avi pujats des del Windows donen error de tipus de fitxer al filepond
import React from "react";
// react component plugin for creating a beautiful datetime dropdown picker
//import ReactPlayer from "react-player";
import ReactLoading from "react-loading";

import { withTranslation, Trans } from 'react-i18next';

import SweetAlert from "react-bootstrap-sweetalert";
import classNames from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";

// @material-ui/icons
import Assignment from "@material-ui/icons/AssignmentOutlined";
import Delete from "@material-ui/icons/DeleteOutline";
import NoteAdd from "@material-ui/icons/NoteAddOutlined";
import MoreVert from "@material-ui/icons/MoreVertOutlined";
import CloudDownload from "@material-ui/icons/CloudDownloadOutlined";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Snackbars from "components/Snackbar/Snackbar.jsx";
import Tooltip from "@material-ui/core/Tooltip";

// My components
import CategoryOptionsDialog from "./CategoryOptionsDialog";
import MediaOptionsDialog from "./MediaOptionsDialog";
import { ReactComponent as NotAvailable } from "../../assets/img/no_image_available.svg";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

// Import React FilePond
import { FilePond, /*File,*/ registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import { feathersClient, feathersAuth } from "../../helpers/feathers";
import config from "../../helpers/config";
import mediaColors from "../../helpers/mediaColors";
import ga from "../../helpers/google-analytics";
// Register the image preview plugin
//import FilePondImagePreview from "filepond-plugin-image-preview";
//import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
//import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata';

import normalizeUrl from "normalize-url";

registerPlugin(FilePondPluginFileValidateType);
//registerPlugin(FilePondPluginFileMetadata);

/*function Transition(props) {
  return <Slide direction="down" {...props} />;
}*/

const normalizeTypes = ["webpage", "videostream", "audiostream"];

const NotEnoughStorageError = {
  code: 420,
  body: "Not enough storage"
};

class Media extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      categoryButtonOpen: false,
      media: [], //Ha de ser un array, si no el map no funciona
      categories: [], //Ha de ser un array, si no el map no funciona
      alert: null,
      categoryOptionsDialog: false,
      categoryOptions: {
        _id: "1", // Li posem un valor qualsevol per que si no el react es queixa de que la clau és la mateixa que al mediaOptions
        name: ""
      },
      mediaOptionsDialog: false,
      mediaOptions: {
        _id: "2", // Li posem un valor qualsevol per que si no el react es queixa de que la clau és la mateixa que al categoryOptions
        name: "",
        defaultDuration: 10,
        validFrom: "",
        validUntil: "",
        url: "",
        //thumbURL: ""
      },
      show: false,
      notification: {
        visible: false,
        text: "",
        color: "success"
      },
      selectedCategory: "",
      Authorization: "",
      isMediaAllowed: null
    };
    this.hideAlert = this.hideAlert.bind(this);
  }

  handleClickCategoryButton = () => {
    if (this._isMounted)
      this.setState({ categoryButtonOpen: !this.state.categoryButtonOpen });
  };

  handleCloseCategoryButton = option => {
    if (this._isMounted) this.setState({ categoryButtonOpen: false });
    switch (option) {
      case "add":
        this.addNewCategoryClick();
        break;
      case "rename":
        if (this.state.selectedCategory) {
          const index = this.state.categories.findIndex(
            x => x._id === this.state.selectedCategory
          );
          if (index !== -1) {
            this.changeCategoryOptionsClick(this.state.categories[index]);
          }
        }
        break;
      case "delete":
        if (this.state.selectedCategory) {
          this.deleteCategoryWarning(this.state.selectedCategory);
        }
        break;
      default:
        break;
    }
  };

  showMedia(media) {
    if (this._isMounted)
      this.setState({
        showMediaDialog: true
      });
  }

  showCategoryOptions(show) {
    if (this._isMounted)
      this.setState({
        categoryOptionsDialog: show
      });
  }

  handleCategoryOptionChange(event, option) {
    var temp = this.state.categoryOptions;
    temp[option] = event.target.value;
    if (this._isMounted)
      this.setState({
        categoryOptions: temp
      });
  }

  applyCategoryOptionsClick(options) {
    this.showCategoryOptions(false);
    if (options._id === "") {
      if (options.name !== "") {
        this.addCategory(options);
      }
    } else {
      this.changeCategoryOptions(options);
    }
  }

  addNewCategoryClick = event => {
    var temp = {
      _id: "", //Aquest és l'identificador del registre de la BBDD
      name: "" //Aquí hi va el nom del grup de la DDBB, no l'ID!!!
    };
    if (this._isMounted) this.setState({ categoryOptions: temp });
    this.showCategoryOptions(true);
  };

  changeCategoryOptionsClick(category) {
    var temp = {
      _id: category._id, //Aquest és l'identificador del registre de la BBDD
      name: category.name //Aquí hi va el nom del grup de la DDBB, no l'ID!!!
    };
    if (this._isMounted) this.setState({ categoryOptions: temp });
    this.showCategoryOptions(true);
  }

  changeMediaOptionsClick(media) {
    var validFrom = "";
    var validUntil = "";
    if (media.validFrom !== null) validFrom = new Date(media.validFrom);
    if (media.validUntil !== null) validUntil = new Date(media.validUntil);
    var mediaOptions = {
      _id: media._id,
      url: media.url,
      //thumbURL: media.thumbURL,
      name: media.name,
      //new: false, // Ho posem a fals per tal de saber si hem de crear un nou registre a la BBDD o no
      defaultDuration: media.defaultDuration,
      mute: media.mute,
      validFrom: validFrom,
      validUntil: validUntil
    };
    if (this._isMounted)
      this.setState({
        mediaOptions: mediaOptions
      });
    this.showMediaOptions(true);
  }

  deleteMediaWarning(media) {
    if (this._isMounted)
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title={this.props.t('translation:are_you_sure')}
            onConfirm={() => this.removeMedia(media)}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={this.props.t('translation:yes_delete_it')}
            cancelBtnText={this.props.t('translation:cancel')}
            showCancel
          >
            {this.props.t('translation:you_will_not_be_able_to_recover_this_media')}
          </SweetAlert>
        )
      });
  }

  deleteCategoryWarning(categoryId) {
    if (this._isMounted)
      this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: "block", marginTop: "-200px" }}
            title={this.props.t('translation:are_you_sure')}
            onConfirm={() => this.removeCategory(categoryId)}
            onCancel={() => this.hideAlert()}
            confirmBtnCssClass={
              this.props.classes.button + " " + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + " " + this.props.classes.danger
            }
            confirmBtnText={this.props.t('translation:yes_delete_it')}
            cancelBtnText={this.props.t('translation:cancel')}
            showCancel
          >
            {/*You will not be able to recover this media!*/}
          </SweetAlert>
        )
      });
  }

  removeMediaEvent(media) {
    var temp = [...this.state.media];
    var index = temp.findIndex(x => x._id === media._id);
    if (index !== -1) {
      temp.splice(index, 1);
      if (this._isMounted)
        this.setState({
          media: temp
        });
    }
  }

  removeMedia(media) {
    this.hideAlert();
    feathersClient
      .service("media")
      .remove(media._id)
      .then(media => {
        this.showNotification(this.props.t('translation:media_deleted_successfully'), "success");
      })
      .catch(error => {
        this.showNotification(error.name + ": " + error.message, "warning");
      });
  }

  removeCategoryEvent(category) {
    var temp = [...this.state.categories];
    var index = temp.findIndex(x => x._id === category._id);
    if (index !== -1) {
      temp.splice(index, 1);
      if (this._isMounted)
        this.setState(
          {
            categories: temp
          },
          () => {
            if (this.state.categories.length > 0) {
              this.changeCategory(this.state.categories[0]._id);
            } else {
              if (this._isMounted) {
                this.setState({
                  selectedCategory: ""
                });
              }
            }
          }
        );
    }
  }

  removeCategory(categoryId) {
    this.hideAlert();
    //console.log('Remove category id: ' + categoryId);
    feathersClient
      .service("categories")
      .remove(categoryId)
      .then(category => {
        this.showNotification(this.props.t('translation:category_deleted_successfully'), "success");
        /*if (this._isMounted) this.setState({
          selectedCategory: ""
        });*/
      })
      .catch(error => {
        this.showNotification(error.name + ": " + error.message, "warning");
      });
  }

  addCategoryEvent(newCategory) {
    if (this._isMounted)
      this.setState(
        {
          categories: [...this.state.categories, newCategory]
        },
        () => {
          if (this.state.selectedCategory === "") {
            this.changeCategory(newCategory._id);
          }
        }
      );
  }

  changeCategoryOptionsEvent(category) {
    // Ara substituim el fitxer de l'array d'estat per tal que el nou nom surti a la pantalla
    var temp = [...this.state.categories];
    var index = temp.findIndex(x => x._id === category._id);
    temp.splice(index, 1, category);
    if (this._isMounted)
      this.setState({
        categories: temp
      });
  }

  addCategory(options) {
    feathersClient
      .service("categories")
      .create({
        name: options.name
      })
      .then(newCategory => {
        this.showNotification(this.props.t('translation:new_category_created_correctly'), "success");
        //this.changeCategory(newCategory._id);
      })
      .catch(error => {
        this.showNotification(this.props.t('translation:error') + ": " + error.name, "warning");
      });
  }

  changeCategoryOptions(options) {
    //event.preventDefault();
    this.hideAlert();
    //console.log("The new category name was submited");
    feathersClient
      .service("categories")
      .patch(options._id, { name: options.name })
      .then(category => {
        this.showNotification(
          this.props.t('translation:new_category_name_submitted_correctly'),
          "success"
        );
      })
      .catch(error => {
        this.showNotification(this.props.t('translation:error') + ": " + error.name, "warning");
      });
  }

  changeMediaOptionsEvent(newMedia) {
    // Ara substituim el fitxer de l'array d'estat per tal que el nou nom surti a la pantalla
    var temp = [...this.state.media];
    var index = temp.findIndex(x => x._id === newMedia._id);
    if (index !== -1) {
      temp.splice(index, 1, newMedia);
      if (this._isMounted)
        this.setState({
          media: temp
        });
    }
  }

  applyMediaOptionsClick(options) {
    //event.preventDefault();
    this.showMediaOptions(false);
    var validFrom = options.validFrom;
    var validUntil = options.validUntil;
    if (validFrom === "") validFrom = null;
    if (validUntil === "") validUntil = null;
    var defaultDuration = parseInt(options.defaultDuration, 10);
    var mute = options.mute;
    try {
      if (normalizeTypes.indexOf(this.props.mediaType.type) !== -1) {
        options.url = normalizeUrl(options.url, {
          stripWWW: false,
          sortQueryParameters: false
        });
      }
      if (options._id !== "") {
        feathersClient
          .service("media")
          .patch(options._id, {
            name: options.name,
            url: options.url,
            defaultDuration: defaultDuration,
            mute: mute,
            validFrom: validFrom,
            validUntil: validUntil
          })
          .then(newMedia => {
            this.showNotification(
              this.props.t('translation:new_media_options_submitted_correctly'),
              "success"
            );
          })
          .catch(error => {
            this.showNotification(this.props.t('translation:error') + ": " + error.name, "warning");
          });
      } else {
        feathersClient
          .service("media")
          .create({
            type: this.props.mediaType.type,
            category: this.state.selectedCategory,
            name: options.name,
            url: options.url,
            defaultDuration: defaultDuration,
            validFrom: validFrom,
            validUntil: validUntil
          })
          .then(newMedia => {
            this.showNotification(this.props.t('translation:new_media_created_correctly'), "success");
          })
          .catch(error => {
            this.showNotification(this.props.t('translation:error') + ": " + error.name, "warning");
          });
      }
    } catch (error) {
      this.showNotification(this.props.t('translation:error') + ": " + error.message, "warning");
    }
  }

  showNotification(message, color) {
    if (!this.state.notification["visible"]) {
      var x = this.state.notification;
      x["visible"] = true;
      x["text"] = message;
      x["color"] = color;
      if (this._isMounted) this.setState(x);
      // use this to make the notification autoclose
      setTimeout(
        function () {
          x["visible"] = false;
          if (this._isMounted) this.setState(x);
        }.bind(this),
        3000
      );
    }
  }

  hideAlert() {
    if (this._isMounted)
      this.setState({
        alert: null
      });
  }

  showMediaOptions(show) {
    if (this._isMounted)
      this.setState({
        mediaOptionsDialog: show
      });
  }

  componentDidMount() {
    this._isMounted = true;
    ga.pageview(window.location.pathname);
    if (this._isMounted)
      this.setState({
        Authorization: feathersAuth.accessToken
      });
    feathersClient
      .service("subscriptions")
      .find()
      .then(subscriptions => {
        if (subscriptions.data.length > 0) {
          if (subscriptions.data[0].allowedMedia.includes(this.props.mediaType.type)) {
            this.setState({
              isMediaAllowed: true
            })
            feathersClient
              .service("categories")
              .find({
                query: {
                  $sort: {
                    name: 1
                  }
                }
              })
              .then(categories => {
                if (categories.total > 0) {
                  if (this._isMounted) {
                    this.setState({
                      categories: categories.data,
                      selectedCategory: categories.data[0]._id
                    });
                  }
                  this.loadMedia(categories.data[0]._id, this.props.mediaType.type);
                }
              })
              .catch(error => {
                this.showNotification(
                  this.props.t('translation:error_fetching_categories') + ": " + error.name,
                  "warning"
                );
                if (this._isMounted)
                  this.setState({
                    categories: []
                  });
              });
            feathersClient
              .service("media")
              .on("created", media => this.addMediaEvent(media));
            feathersClient
              .service("media")
              .on("patched", media => this.changeMediaOptionsEvent(media));
            feathersClient
              .service("media")
              .on("removed", media => this.removeMediaEvent(media));
            feathersClient
              .service("categories")
              .on("created", category => this.addCategoryEvent(category));
            feathersClient
              .service("categories")
              .on("patched", category => this.changeCategoryOptionsEvent(category));
            feathersClient
              .service("categories")
              .on("removed", category => this.removeCategoryEvent(category));
          } else {
            this.setState({
              isMediaAllowed: false
            })
          }
        }
      })
      .catch(error => {
        this.showNotification(
          this.props.t('translation:error_fetching_subscription') + ": " + error.name,
          "warning"
        );
      });
  }

  componentWillUnmount() {
    this._isMounted = false;

    feathersClient.service("media").removeAllListeners("created");
    feathersClient.service("media").removeAllListeners("patched");
    feathersClient.service("media").removeAllListeners("removed");
    feathersClient.service("categories").removeAllListeners("created");
    feathersClient.service("categories").removeAllListeners("patched");
    feathersClient.service("categories").removeAllListeners("removed");
  }

  changeCategory(selectedCategory) {
    if (this.state.selectedCategory !== selectedCategory) {
      if (this._isMounted) {
        this.setState({ selectedCategory: selectedCategory });
      }
      this.loadMedia(selectedCategory, this.props.mediaType.type);
    }
  }

  handleCategoryChange = event => {
    this.changeCategory(event.target.value);
  };

  addMediaEvent(newMedia) {
    if (
      newMedia.type === this.props.mediaType.type &&
      newMedia.category === this.state.selectedCategory
    ) {
      if (this._isMounted)
        this.setState({
          media: [...this.state.media, newMedia]
        });
    }
  }

  loadMedia(category, type) {
    feathersClient
      .service("media")
      .find({
        query: {
          type: type,
          category: category,
          $sort: {
            name: 1
          }
        }
      })
      .then(media => {
        if (this._isMounted)
          this.setState({
            media: media.data
          });
        //console.log(media);
      })
      .catch(error => {
        this.showNotification(this.props.t('translation:error_fetching_media') + ": " + error.name, "warning");
        if (this._isMounted)
          this.setState({
            media: []
          });
      });
  }

  addNewWebpageClick = event => {
    var temp = {
      _id: "", //Aquest és l'identificador del registre de la BBDD
      name: "", //Aquí hi va el nom de la DDBB, no l'ID!!!
      //new: true,
      url: "",
      defaultDuration: 0
    };
    if (this._isMounted) this.setState({ mediaOptions: temp });
    this.showMediaOptions(true);
  };

  renderImage(media) {
    if (
      media.thumbnail &&
      media.thumbnail !== "" &&
      media.thumbnail !== "not_available"
    ) {
      var url;
      if (media.type !== "videostream") {
        url = "//" + config.api + "/" + media.thumbnail;
      } else {
        url = media.thumbnail;
      }
      return (
        <div className="media-image">
          <GridContainer justify="center">
            <GridItem>
              <img
                src={url}
                loading="lazy"
                //src={media.thumbnail}
                alt="..."
                style={{
                  maxWidth: "192px",
                  maxHeight: "108px",
                  objectFit: "contain"
                }}
                onClick={media => this.showMedia(media)}
              />
            </GridItem>
          </GridContainer>
        </div>
      );
    } else if (media.thumbnail === "not_available") {
      return (
        <div
          className="player-wrapper"
          style={{
            maxHeight: "80px",
            maxWidth: "100%",
            padding: "inherit"
          }}
        >
          <NotAvailable
            style={{
              maxWidth: "192px",
              maxHeight: "108px",
              objectFit: "contain"
            }}
          />
        </div>
      );
    } else {
      return (
        <div className="media-image">
          <GridContainer justify="center">
            <GridItem>
              <ReactLoading type={"spinningBubbles"} color={"#4caf50"} />
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }

  renderPreview(media) {
    switch (media.type) {
      case "image":
      case "video":
      case "videostream":
      case "webpage":
      case "html":
        return this.renderImage(media);
      case "audio":
        return (
          <div className="media-image">
            <audio
              controls
              style={{ width: "100%" }}
              src={"//" + config.api + "/" + media.url}
            />
          </div>
        );
      case "audiostream":
        return (
          <div className="media-image">
            <audio controls style={{ width: "100%" }} src={media.url} />
          </div>
        );
      default:
        break;
    }
  }

  renderDrop(type) {
    const { classes } = this.props;
    if (this.state.categories.length > 0) {
      switch (type) {
        case "image":
        case "video":
        case "audio":
        case "html":
          return (
            <GridItem xs={12}>
              <FilePond
                ref={ref => this.pond = ref}
                id="filepond"
                allowDrop={this.state.selectedCategory !== ""}
                allowBrowse={this.state.selectedCategory !== ""}
                dropOnPage={true}
                dropOnElement={false}
                allowMultiple={true}
                allowRevert={false}
                name="file"
                server={{
                  url: config.apiURL + "/uploads",
                  process: {
                    headers: {
                      Authorization: this.state.Authorization,
                      Category: this.state.selectedCategory
                    }
                  },
                  onerror: {
                    function(e, file) {
                      //console.log(e, file);
                    }
                  },
                }}
                labelIdle={this.props.t('translation:drag_drop_your_files_or') + ' <span class="filepond--label-action"> ' + this.props.t('translation:browse') + ' </span>'}
                acceptedFileTypes={this.props.mediaType.acceptedFileTypes}
                onprocessfile={(error, file) => {
                  if (error) {
                    if (error.code === NotEnoughStorageError.code) {
                      this.showNotification(
                        NotEnoughStorageError.body,
                        "warning"
                      );
                    } else {
                      this.showNotification(error.body, "warning");
                    }
                  } else {
                    this.timeOutFunction = setTimeout(
                      function () {
                        this.pond.removeFile(file);
                      }.bind(this),
                      1000
                    );
                  }
                }}
              />
            </GridItem>
          );
        default:
          break;
      }
    } else {
      return (
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <div className={classes.center}>
                <h5>{this.props.t('translation:please_create_at_least_one_category_first')}</h5>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      );
    }
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;
    const dropdownItem =
      classes.dropdownItem +
      " " +
      classNames({
        [classes.dropdownItemRTL]: false
      });

    return (
      <div>
        {this.state.alert}
        <CategoryOptionsDialog
          key={'COD' + this.state.categoryOptions._id} //Canviar el valor del key ens assegura que React tornarà a crear el component amb els props nous
          _id={this.state.categoryOptions._id}
          name={this.state.categoryOptions.name}
          open={this.state.categoryOptionsDialog}
          onClose={() => this.showCategoryOptions(false)}
          onAccept={newOptions => this.applyCategoryOptionsClick(newOptions)}
        />
        <MediaOptionsDialog
          key={'MOD' + this.state.mediaOptions._id} //Canviar el valor del key ens assegura que React tornarà a crear el component amb els props nous
          type={this.props.mediaType.type}
          mediaOptions={this.state.mediaOptions}
          open={this.state.mediaOptionsDialog}
          onClose={() => this.showMediaOptions(false)}
          onAccept={newOptions => this.applyMediaOptionsClick(newOptions)}
        />
        <GridContainer justify="flex-end">
          <GridItem>
            <Snackbars
              place="tc"
              color={this.state.notification.color}
              //icon={AddAlert}
              message={this.state.notification.text}
              open={this.state.notification.visible}
              closeNotification={() => {
                if (this._isMounted)
                  this.setState({ "notification.visible": false });
              }}
            //close
            />
          </GridItem>
        </GridContainer>
        {this.state.isMediaAllowed === false &&
          <Card>
            <CardBody>
              <h4 style={{ textAlign: "center" }}>
                {t('translation:media_not_allowed')}
              </h4>
              <h4 style={{ textAlign: "center" }}>
                <Trans i18nKey="upgrade_your_plan">
                  Please
                  <a href={config.subscriptionURL} target="_blank" rel="noopener noreferrer">
                    upgrade
                  </a>
                  your plan to be able to use this type of media.
                </Trans>
              </h4>
            </CardBody>
          </Card>
          //<h1>This Media is not allowed</h1>
        }
        {this.state.isMediaAllowed === true &&
          <Card>
            <CardHeader color="success" icon>
              <GridContainer>
                <GridItem xs={12} sm={9}>
                  <GridContainer>
                    <GridItem>
                      <CardIcon color="success">
                        {this.props.mediaType.icon}
                      </CardIcon>
                    </GridItem>
                    <GridItem xs={6}>
                      <h4 className={classes.cardIconTitle}>
                        {this.props.mediaType.label}
                      </h4>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem xs={12} sm={3}>
                  <GridContainer justify="flex-end">
                    <GridItem>
                      {this.props.mediaType.type === "webpage" && (
                        <GridItem>
                          <Button
                            color="success"
                            simple
                            className={this.props.classes.marginRight}
                            onClick={this.addNewWebpageClick}
                            style={{ maxWidth: "140px" }}
                          >
                            <NoteAdd className={this.props.classes.icons} /> {t('translation:new_webpage')}
                          </Button>
                        </GridItem>
                      )}
                      {this.props.mediaType.type === "videostream" && (
                        <GridItem>
                          <Button
                            color="success"
                            simple
                            className={this.props.classes.marginRight}
                            onClick={this.addNewWebpageClick}
                            style={{ maxWidth: "140px" }}
                          >
                            <NoteAdd className={this.props.classes.icons} /> {t('translation:new_video_stream')}
                          </Button>
                        </GridItem>
                      )}
                      {this.props.mediaType.type === "audiostream" && (
                        <GridItem>
                          <Button
                            color="success"
                            simple
                            className={this.props.classes.marginRight}
                            onClick={this.addNewWebpageClick}
                            style={{ maxWidth: "140px" }}
                          >
                            <NoteAdd className={this.props.classes.icons} /> {t('translation:new_audio_stream')}
                          </Button>
                        </GridItem>
                      )}
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <GridContainer
                //justify="flex-end"
                alignItems="flex-end"
              //direction="row-reverse"
              >
                <GridItem xs>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="category-select"
                      className={classes.selectLabel}
                    >
                      {this.state.categories.length > 0
                        ? t('translation:category')
                        : t('translation:create_a_new_category_first')}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={this.state.selectedCategory}
                      onChange={this.handleCategoryChange}
                      inputProps={{
                        name: "selectedCategory",
                        id: "category-select"
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        {t('translation:choose_category')}
                      </MenuItem>
                      {this.state.categories.map(category => {
                        return (
                          <MenuItem
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={category._id}
                            key={'MI' + category._id} //Posem el valor key per que el React no es queixi. Aquesta clau ha de ser única
                          >
                            {category.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={3}>
                  <Button
                    color="success"
                    simple
                    justIcon
                    round
                    aria-label="More"
                    aria-owns={this.state.categoryButtonOpen ? "menu-list" : null}
                    aria-haspopup="true"
                    onClick={this.handleClickCategoryButton}
                    className={classes.buttonLink}
                    muiClasses={{
                      label: ""
                    }}
                    buttonRef={node => {
                      this.anchorEl = node;
                    }}
                  >
                    <MoreVert
                      className={classes.headerLinksSvg + " " + classes.links}
                    />
                    <Hidden mdUp implementation="css">
                      <span
                        onClick={this.handleClickCategoryButton}
                        className={classes.linkText}
                      />
                    </Hidden>
                  </Button>
                  <Popper
                    open={this.state.categoryButtonOpen}
                    anchorEl={this.anchorEl}
                    transition
                    disablePortal
                    placement="bottom-end"
                    className={classNames({
                      [classes.popperClose]: !this.state.categoryButtonOpen,
                      [classes.pooperResponsive]: true,
                      [classes.pooperNav]: true
                    })}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        id="menu-list"
                        style={{ transformOrigin: "0 0 0" }}
                      >
                        <Paper className={classes.dropdown}>
                          <ClickAwayListener
                            onClickAway={() => {
                              this.handleCloseCategoryButton(null);
                            }}
                          >
                            <MenuList role="menu">
                              <MenuItem
                                onClick={() => {
                                  this.handleCloseCategoryButton("add");
                                }}
                                className={dropdownItem}
                              >
                                {t('translation:add_new_category')}
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  this.handleCloseCategoryButton("rename");
                                }}
                                className={dropdownItem}
                              >
                                {t('translation:rename_category')}
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  this.handleCloseCategoryButton("delete");
                                }}
                                className={dropdownItem}
                              >
                                {t('translation:delete_category')}
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </GridItem>
              </GridContainer>
              <GridContainer>
                {this.renderDrop(this.props.mediaType.type)}
              </GridContainer>
              <GridContainer alignItems="stretch">
                {this.state.media.map(media => {
                  return (
                    <GridItem xs={12} sm={6} md={4} lg={3} xl={2} key={'M' + media._id}>
                      <Card
                        product
                        className={classes.cardHover}
                        style={{
                          backgroundColor: mediaColors.getMediaBackgroundColor(media)
                        }}>
                        <CardHeader image className={classes.cardHeaderHover}>
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            {this.renderPreview(media)}
                          </a>
                        </CardHeader>
                        <CardBody>
                          <div className={classes.cardHoverUnder}>
                            <Tooltip
                              disableFocusListener={true}
                              id="tooltip-top"
                              title={t('translation:options')}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="warning"
                                simple
                                justIcon
                                onClick={() =>
                                  this.changeMediaOptionsClick(media)
                                }
                              >
                                <Assignment className={classes.underChartIcons} />
                              </Button>
                            </Tooltip>
                            {(media.type === "image" ||
                              media.type === "video" ||
                              media.type === "audio") && (
                                <Tooltip
                                  disableFocusListener={true}
                                  id="tooltip-top"
                                  title={t('translation:download')}
                                  placement="bottom"
                                  classes={{ tooltip: classes.tooltip }}
                                >
                                  <Button
                                    color="success"
                                    simple
                                    justIcon
                                    href={"//" + config.api + "/" + media.url}
                                    download={media.file.originalname}
                                  >
                                    <CloudDownload
                                      className={classes.underChartIcons}
                                    />
                                  </Button>
                                </Tooltip>
                              )}
                            <Tooltip
                              disableFocusListener={true}
                              id="tooltip-top"
                              title={t('translation:delete')}
                              placement="bottom"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <Button
                                color="danger"
                                simple
                                justIcon
                                onClick={() => this.deleteMediaWarning(media)}
                              >
                                <Delete className={classes.underChartIcons} />
                              </Button>
                            </Tooltip>
                          </div>
                          <h6 className={classes.cardProductTitle}>
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              {media.name}
                            </a>
                          </h6>
                        </CardBody>
                      </Card>
                    </GridItem>
                  );
                })}
              </GridContainer>
            </CardBody>
          </Card>
        }
      </div>
    );
  }
}

Media.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(dashboardStyle)(withTranslation()(Media));
