const audioTypes = [
  "audio/aac",
  "audio/aacp",
  "audio/3gpp",
  "audio/3gpp2",
  "audio/mp4",
  "audio/mp4a-latm",
  "audio/mpeg4-generic",
  "audio/mp3",
  "audio/ogg",
  "audio/mpeg",
  "audio/MPA",
  "audio/mpa-robust",
  "audio/x-flac",
  "audio/flac",
  "audio/vnd.wave",
  "audio/wav",
  "audio/wave",
  "audio/x-wav"
];

const imageTypes = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/webp",
  "image/svg+xml",
  "image/apng",
  "image/bmp"
];

const videoTypes = [
  "video/mp4",
  "video/quicktime",
  "video/x-msvideo",
  "video/x-ms-wmv",
  "video/x-m4v",
  "video/x-matroska"
];

const htmlType = [
  "text/html"
];

const compressedHtmlType = [
  "application/zip",
  "application/gzip",
  "application/x-tar",
  "application/x-bzip2",
  "application/x-bzip"
];

const htmlTypes = htmlType.concat(compressedHtmlType);

module.exports = {
  imageTypes,
  videoTypes,
  audioTypes,
  htmlTypes,
  htmlType,
  compressedHtmlType
};
