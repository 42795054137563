import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

import "assets/scss/material-dashboard-pro-react.css?v=1.4.0";

import history from "helpers/history"

import App from "./App";

// import i18n (needs to be bundled ;)) 
import './i18n';

ReactDOM.render(
  <Router history={history}>
    <Suspense fallback="...loading app">
      <App />
    </Suspense>
  </Router>,
  document.getElementById("root")
);