const commonTypes = require('./commonTypes');

const userProperties = {
  _id: commonTypes.string,
  username: commonTypes.string,
  email: commonTypes.email,
  first_name: commonTypes.string,
  last_name: commonTypes.string,
  display_name: commonTypes.string,
  nickname: commonTypes.string,
  roles: commonTypes.roles,
  avatar: commonTypes.string,
  language: commonTypes.string
};

//////////////// SCHEMAS ////////////////////

const userSchema = {
  properties: userProperties
};

const userCreateSchema = {
  required: ["email"],
  properties: userProperties
};

const userPatchSchema = {
  properties: userProperties
};

export {
  userSchema,
  userCreateSchema,
  userPatchSchema
};