import React from "react";
import { withTranslation } from 'react-i18next';

import Card from "components/Card/Card.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "components/CustomButtons/Button.jsx";

import Delete from "@material-ui/icons/DeleteOutline";
import VolumeOff from "@material-ui/icons/VolumeOffOutlined";
import VolumeUp from "@material-ui/icons/VolumeUpOutlined";
import PlaylistAdd from "@material-ui/icons/PlaylistAdd";

import { ReactComponent as FileMusic } from "../../assets/img/icons/file-music-outline.svg";
import { ReactComponent as RadioTower } from "../../assets/img/icons/radio-tower.svg";
import { ReactComponent as NotAvailable } from "../../assets/img/no_image_available.svg";
import ReactLoading from "react-loading";

import config from "../../helpers/config";
import mediaColors from "../../helpers/mediaColors";

import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

import moment from "moment";
import color from "@material-ui/core/colors/amber";
import { colors } from "@material-ui/core";

class MediaPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //windowWidth: window.innerWidth
    };
  }
  componentDidMount() {
    //window.addEventListener("resize", this.updateWidth);
  }
  componentWillUnmount() {
    //window.removeEventListener("resize", this.updateWidth);
  }

  renderImage(media) {
    if (
      media.thumbnail &&
      media.thumbnail !== "" &&
      media.thumbnail !== "not_available"
    ) {
      var url;
      if (media.type !== "videostream") {
        url = "//" + config.api + "/" + media.thumbnail;
      } else {
        url = media.thumbnail;
      }
      return (
        <div
          className="player-wrapper"
          style={{
            maxHeight: "80px",
            maxWidth: "100%",
            padding: "inherit"
          }}
        >
          <img
            src={url}
            loading="lazy"
            //src={media.thumbnail}
            alt="..."
            style={{
              maxWidth: "100%",
              maxHeight: "80px",
              objectFit: "contain",
              display: "block",
              margin: "auto"
            }}
          />
        </div>
      );
    } else if (media.thumbnail === "not_available") {
      return (
        <div
          className="player-wrapper"
          style={{
            maxHeight: "80px",
            maxWidth: "100%",
            padding: "inherit"
          }}
        >
          <NotAvailable
            style={{
              maxWidth: "100%",
              maxHeight: "80px",
              objectFit: "contain",
              display: "block",
              margin: "auto"
            }}
          />
        </div>
      );
    } else {
      return (
        <div
          className="media-image"
          style={{
            maxHeight: "80px",
            maxWidth: "100%",
            padding: "inherit"
          }}
        >
          <GridContainer justify="center">
            <GridItem>
              <ReactLoading type={"spinningBubbles"} color={"#4caf50"} />
            </GridItem>
          </GridContainer>
        </div>
      );
    }
  }

  renderPreview(media, draggable) {
    switch (media.type) {
      case "image":
      case "video":
      case "videostream":
      case "html":
      case "webpage":
        return(this.renderImage(media));
      case "audiostream":
        return (
          <div
            className="media-image"
            style={{ width: "100%", height: "100%" }}
          >
            <RadioTower
              fill="#4caf50"
              style={{ width: "100%", height: "100%", maxHeight: "80px" }}
            />
          </div>
        );
      case "audio":
        return (
          <div
            className="media-image"
            style={{ width: "100%", height: "100%" }}
          >
            <FileMusic
              fill="#4caf50"
              style={{ width: "100%", height: "100%", maxHeight: "80px" }}
            />
          </div>
        );
      default:
        break;
    }
  }

  render() {
    const { classes } = this.props;
    const { t } = this.props;

    return (
      <Card
        style={{
          marginTop: "5px",
          marginBottom: "5px",
          backgroundColor: mediaColors.getMediaBackgroundColor(this.props.media)
        }}
      >
        <GridContainer alignItems="center">
          <GridItem xs={4}>
            {this.renderPreview(this.props.media, true)}
          </GridItem>
          <GridItem xs={8}>
            <GridContainer>
              <GridItem xs={9}>
                <h6>{this.props.media.name}</h6>
              </GridItem>
              {!this.props.deleteIcon && (
                <GridItem xs={3} style={{padding: "0px"}}>
                  <Tooltip
                    disableFocusListener={true}
                    id="tooltip-top"
                    title={ t('translation:add') }
                    placement="top"
                    classes={{
                      tooltip: this.props.classes.tooltip
                    }}
                  >
                    <Button
                      color="success"
                      simple
                      justIcon
                      onClick={() => {
                        this.props.addMedia(this.props.media);
                      }}
                    >
                      <PlaylistAdd className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </GridItem>
              )}
              {this.props.deleteIcon && (
                <GridItem xs={3} style={{padding: "0px"}}>
                  <Tooltip
                    disableFocusListener={true}
                    id="tooltip-top"
                    title={ t('translation:delete') }
                    placement="top"
                    classes={{
                      tooltip: this.props.classes.tooltip
                    }}
                  >
                    <Button
                      color="danger"
                      simple
                      justIcon
                      onClick={() => {
                        this.props.removeMedia(this.props.media);
                      }}
                    >
                      <Delete className={classes.underChartIcons} />
                    </Button>
                  </Tooltip>
                </GridItem>
              )}
            </GridContainer>
            <GridContainer justify="space-between">
              <GridItem xs={6}>
                <CustomInput
                  labelText={ t('translation:duration_s') }
                  id="number"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    value: this.props.media.defaultDuration,
                    disabled:
                      this.props.media.type === "video" ||
                      this.props.media.type === "audio",
                    onChange: event =>
                      this.props.handleMediaTimeChange(this.props.media, event)
                  }}
                />
              </GridItem>
              {(this.props.media.type === "video" ||
                this.props.media.type === "videostream") && (
                <GridItem xs={3} style={{padding: "0px"}}>
                  <Tooltip
                    disableFocusListener={true}
                    id="tooltip-top"
                    title={ t('translation:mute') }
                    placement="top"
                    classes={{
                      tooltip: this.props.classes.tooltip
                    }}
                  >
                    <Button
                      color={this.props.media.mute ? "danger" : "success"}
                      simple
                      justIcon
                      onClick={() => {
                        this.props.handleMediaMuteChange(
                          this.props.media,
                          !this.props.media.mute
                        );
                      }}
                    >
                      {this.props.media.mute ? (
                        <VolumeOff className={classes.underChartIcons} />
                      ) : (
                        <VolumeUp className={classes.underChartIcons} />
                      )}
                    </Button>
                  </Tooltip>
                </GridItem>
              )}
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Card>
    );
  }
}

export default withStyles(dashboardStyle)(withTranslation()(MediaPreview));
