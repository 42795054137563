import io from 'socket.io-client';
import feathers from '@feathersjs/client';

import {
    validateUser
} from '../schemas/validators';

import config from '../helpers/config';

import i18n from '../i18n.js';

//console.log(config);

const socket = io(
    config.api, {
        transports: ['websocket'],
        upgrade: false
    }
);
const feathersClient = feathers();

feathersClient.configure(feathers.socketio(socket));
feathersClient.configure(feathers.authentication({
    storage: window.localStorage
}));

var userProfile = validateUser({});
var activeUser = userProfile;

var i18nLoaded = false;
var i18nLanguage = "es";

i18n.on('initialized', function(options) {
    i18nLoaded = true;
    if (i18nLanguage !== "" && i18n.language !== i18nLanguage) {
        i18n.changeLanguage(i18nLanguage);
    } else {
        i18n.changeLanguage();
    }
    //console.log(i18n.language);
})

feathersClient.service("users").on("patched", newUser => {
    userProfile = newUser;
});

const feathersAuth = {
    isAuthenticated: false,
    userId: '',
    email: '',
    accessToken: '',
    async obtenirDades(response) {
        //console.log('Obtenir dades...')
        this.accessToken = response.accessToken;
        var payload = await feathersClient.passport.verifyJWT(response.accessToken);
        this.userId = payload.userId;
        var newUser = await feathersClient.service('users').get(payload.userId);
        userProfile = newUser;
        activeUser = newUser;
        this.email = newUser.email;
        this.isAuthenticated = true;
    },
    async authenticate(credentials) {
        //console.log('Try to Authenticate');
        try {
            if (!credentials) {
                // Try to authenticate using the JWT from localStorage
                //console.log('Authenticate without credentials');
                var response = await feathersClient.authenticate();
                //console.log('Authenticated wo credentials, response: ', response);
                //this.isAuthenticated = true;
                await feathersAuth.obtenirDades(response);
            } else {
                // If we get login information, add the strategy we want to use for login
                //console.log('Authenticated with credentials: ', credentials);
                const payload = Object.assign({
                    strategy: 'local'
                }, credentials);
                response = await feathersClient.authenticate(payload);
                //console.log('Authenticated w credentials!', response);
                await feathersAuth.obtenirDades(response);
                //i18n.changeLanguage('ca', () => {
                //console.log('new language: ' + i18n.language);
                //});
            }
        } catch (error) {
            // If we got an error, show the login page
            userProfile = validateUser({});
            activeUser = userProfile;
            this.userId = '';
            this.accessToken = null;
            this.isAuthenticated = false;
            //console.log(error);
        }
        if (this.isAuthenticated) {
            if (userProfile.language !== "") {
                if (i18nLoaded === true && userProfile.language !== i18n.language) {
                    i18n.changeLanguage(userProfile.language);
                }
                i18nLanguage = userProfile.language;
            }
        }
        return this.isAuthenticated;
    },
    async signout() {
        this.userId = '';
        userProfile = validateUser({});
        activeUser = userProfile;
        this.accessToken = '';
        this.isAuthenticated = false;
        return await feathersClient.logout();
    },
    async getAuthenticated() {
        if (this.isAuthenticated === null) {
            return await this.authenticate()
        } else {
            return this.isAuthenticated;
        }
    },
    async changeActiveUser(id) {
        try {
            activeUser = await feathersClient.service('users').get(id);
            //console.log(activeUser);
        } catch {
            activeUser = userProfile;
        }
    },
    setActiveUser(user) {
        activeUser = user;
    }
    /*getIsAuthenticated() {
      return this.isAuthenticated;
    }*/
};

export {
    feathersClient,
    feathersAuth,
    userProfile,
    activeUser
};